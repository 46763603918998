@use '../../fundation/index' as *;

.js-text-split{
	display: inline-block;
	overflow: hidden;
	span:not([class]){
		display: inline-block;
		opacity: 0;
		translate: 0 60%;
		transition: translate 0.6s ease-in-out, opacity 0.8s ease-out;
		@for $i from 1 through 30 {
			$j: $i * 0.05;
			&:nth-of-type(#{$i}) {
				transition-delay: $j+s;
			}
		}
	}
	&.is-active{
		span:not([class]){
			opacity: 1;
			translate: 0 0;
		}
	}
}