@use '../../fundation/index' as *;
.c-breadcrumb{
	position: absolute;
	width: max-content;
	bottom: -90px;
	right: 120px;
	.c-breadcrumb__list{
		display: flex;
		flex-wrap: wrap;
		background: $clr_bg;
		gap: 22px;
		padding: 6px 12px;
		border-radius: 4px;
		font-size: font-size(13);
		 > span{
			a{
				display: inline-block;
				color: $clr_default;
				@include trans-normal;
				&:hover,&:focus-visible{
					opacity: 0.5;
				}
			}
			&:nth-child(n+2){
				position: relative;
				color: $clr_text_gray;
				&::before{
					content: '';
					position: absolute;
					top: 50%;
					left: -16px;
					width: 10px;
					height: 1px;
					background: #ADADAD;
				}
			}
		}
	}
	@include mq (full){
		right: 20px;
	}
}