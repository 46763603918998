@use '../fundation/index' as *;

/*  l-footer
---------------------------------------- */
.l-footer{
	border-top: 1px solid $clr_border;
	padding-top: 100px;
	.l-footer__inner{
		@include inner;
	}
	.l-footer__logo-img{
		width: 267px;
		&-link{
			display: block;
			@include trans-normal;
			&:hover,&:focus-visible{
				opacity: 0.5;
			}
		}
	}
	.l-footer__logo-add{
		margin-top: 14px;
		font-size: font-size(12);
	}
	.l-footer__bottom{
		margin-top: 80px;
	}
	.l-footer__qualification{
		&-list{
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
		}
		&-item{
			width: 69px;
		}
	}
	.l-footer__cr{
		margin-top: 20px;
		padding: 16px 0 10px;
		border-top: 1px solid $clr_border_secondary;
		color: $clr_text_gray;
		font-size: font-size(10);
		@include font_en;
	}
	@include mq (large){
		padding-top: 200px;
		padding-bottom: 40px;
		.l-footer__top{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 80px;
		}
		.l-footer__logo-img{
			width: 360px;
		}
		.l-footer__logo-add{
			margin-top: 24px;
			font-size: font-size(14);
		}
		.l-footer__contact{
			width: 340px;
		}
		.l-footer__contact-btn{
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			gap: 16px;
		}
		.l-footer__contact-btn-item{
			width: 100%;
			&-link{
				display: grid;
				place-content: center;
				height: 100%;
				padding: 24px;
				border: 1px solid $clr_default;
				border-radius: 4px;
				text-align: center;
				font-size: font-size(18);
				@include trans-normal;
				&.-num{
					@include font_en;
				}
				&:hover,&:focus-visible{
					background: $clr_primary;
					color: #fff;
				}
			}
		}
		.l-footer__note{
			margin-top: 12px;
			&-text{
				font-size: font-size(13);
				color: $clr_text_gray;
			}
		}
		.l-footer__bottom{
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: space-between;
			gap: 80px;
			margin-top: 100px;
			padding-top: 100px;
			border-top: 1px solid $clr_border_secondary;
		}
		.l-footer__qualification{
			&-list{
				gap: 32px;
			}
			&-item{
				width: 103px;
			}
		}
		.l-footer__nav{
			display: flex;
			flex-wrap: wrap;
			gap: 100px;
		}
		.l-footer__nav-item{
			&:nth-child(n+2){
				margin-top: 24px;
			}
			&:has(.l-footer__nav-item-in){
				display: flex;
				flex-wrap: wrap;
				gap: 40px;
			}
		}
		.l-footer__nav-item-in,
		.l-footer__nav-item-link{
			display: block;
			font-size: font-size(18);
			font-weight: 700;
			@include font_title;
		}
		.l-footer__nav-item-link{
			display: block;
			@include trans-normal;
			&:hover,&:focus-visible{
				opacity: 0.5;
			}
		}
		.l-footer__nav-item-sub{
			&-item{
				&:nth-child(n+2){
					margin-top: 20px;
				}
			}
			&-link{
				display: block;
				@include trans-normal;
				&:hover,&:focus-visible{
					opacity: 0.5;
				}
			}
		}
		.l-footer__cr{
			margin-top: 160px;
			padding: 0;
			border-top: 0;
			text-align: right;
			font-size: font-size(12);
		}
	}
	@include mq (full){
		.l-footer__logo-img{
			width: 388px;
		}
		.l-footer__contact{
			width: 832px;
		}
		.l-footer__contact-btn{
			flex-direction: row;
		}
		.l-footer__contact-btn-item{
			width: calc(50% - 8px);
		}
		.l-footer__bottom{
			flex-direction: row;
		}
	}
}
