@use '../../fundation/index' as *;
/*  p-business
----------------------------------- */
.p-business {
  padding-top: 60px;
  .p-business__inner {
    @include inner;
  }
  .p-business__contents-item {
    padding: 50px 0;
    &:first-child {
      padding: 0 0 50px;
    }
    &:last-child {
      padding: 50px 0 100px;
    }
  }
  .p-business__contents-txt {
    padding-bottom: 24px;
    color: $clr_text_secondary;
  }
  @include mq(large) {
    padding-top: calc(200px - var(--headerH));
    .p-business__contents-item {
      padding: 100px 0;
      &:first-child {
        padding: 0 0 100px;
      }
      &:last-child {
        padding: 100px 0 240px;
      }
    }
    .p-business__contents-txt {
      padding-bottom: 30px;
    }
  }
}
