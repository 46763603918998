@use '../../fundation/index' as *;

/*  p-products-parts
---------------------------------------- */
.p-products-parts{
	padding-bottom: 80px;
	.p-products-parts-anchor{
		padding: 60px 0;
		background: $clr_bg;
	}
	.p-products-parts-anchor__inner{
		@include inner;
	}
	.p-products-parts-container{
		padding-top: 60px;
	}
	.p-products-parts-container__inner{
		@include inner;
	}
	.p-products-parts-container__sec{
		&:nth-of-type(n+2){
			padding-top: 40px;
		}
	}
	@include mq (large){
		padding-bottom: 240px;
		.p-products-parts-anchor{
			padding: 100px 0;
		}
		.p-products-parts-container{
			padding-top: 100px;
		}
		.p-products-parts-container__sec{
			&:nth-of-type(n+2){
				padding-top: 80px;
			}
		}
	}
	@include mq (full){
		.p-products-parts-anchor__inner{
			@include inner(1272px);
		}
	}
}