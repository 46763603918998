@use '../../fundation/index' as *;
.p-index-hero{
	.p-index-hero__text{
		position: relative;
		z-index: 1;
		padding: 50px 0 60px;
	}
	.p-index-hero__text-outer{
		@include inner;
	}
	.p-index-hero__text-logo{
		width: 24px;
		margin: 0 auto 24px;
		path{
			fill: $clr_default;
		}
	}
	.p-index-hero__text-row{
		text-align: center;
		@include font_title;
		line-height: 1.6;
		font-size: font-size(38);
		letter-spacing: .06em;
		span{
			display: block;
		}
		.-spSmall{
			font-size: font-size(20);
		}
	}
	.p-index-hero__topics{
		display: flex;
		height: 80px;
		border-bottom: 1px solid $clr_border_secondary;
		&-header{
			display: grid;
			place-content: center;
			width: auto;
			height: 100%;
			aspect-ratio: 1/1;
			background: $clr_primary;
			color: #fff;
			@include font_en;
			font-size: font-size(12);
		}
		&-body{
			width: calc(100% - 80px);
		}
		&-link{
			display: flex;
			align-items: center;
			height: 100%;
			padding: 5px 20px;
			@include trans-normal;
			&:hover,&:focus-visible{
				opacity: 0.5;
			}
		}
		&-title{
			width: 100%;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			font-size: font-size(13);
			color: $clr_text_secondary;
		}
	}
	@include mq (large){
		position: relative;
		z-index: 2;
		.p-index-hero__text{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 0;
		}
		.p-index-hero__text-outer{
			height: 100%;
			@include inner;
		}
		.p-index-hero__text-inner{
			position: relative;
			height: 100%;
		}
		.p-index-hero__video{
			position: relative;
			&::before{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(#000,.35);
			}
		}
		.p-index-hero__text-row{
			position: absolute;
			top: 50%;
			translate: 0 -50%;
			left: 0;
			color: #fff;
			text-align: left;
			font-size: vwFont(68);
			.-spSmall{
				font-size: vwFont(48);
			}
			.-pcLarge{
				font-size: vwFont(68);
			}
		}
		.p-index-hero__topics{
			position: absolute;
			bottom: 0;
			right: 0;
			width: 593px;
			height: 65px;
			border-bottom: 0;
			background: #fff;
			z-index: 2;
			&-header{
				height: 100%;
				aspect-ratio: 95/65;
				font-size: font-size(14);
			}
			&-link{
				padding: 5px 24px;
			}
			&-title{
				-webkit-line-clamp: 1;
				font-size: font-size(14);
			}
		}
	}
}

/*  p-index-link
---------------------------------------- */
.p-index-link{
	padding: 60px 0 50px;
	.p-index-link__inner{
		@include inner;
	}
	.p-index-link__header{
		position: relative;
		margin-bottom: 12px;
		padding-left: 12px;
		color: $clr_text_gray;
		font-size: font-size(12);
		@include font_en;
		&::before,
		&::after{
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 7px;
			height: 1px;
			background: $clr_text_gray;
		}
		&::after{
			rotate: 90deg;
		}
	}
	.p-index-link__card{
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}
	.p-index-link__card-item{
		width: calc(50% - 4px);
		aspect-ratio: 1/1;
	}
	.p-index-link__card-item-link{
		position: relative;
		display: block;
		height: 100%;
		border: 1px solid $clr_border;
		border-radius: 4px;
		padding: 20px 20px 40px;
	}
	.p-index-link__card-item-text{
		font-size: font-size(15);
		@include font_title;
		@include trans-normal;
	}
	.p-index-link__card-item-img{
		width: 40px;
		margin-top: 14px;
	}
	.p-index-link__card-item-arw{
		position: absolute;
		right: 6px;
		bottom: 6px;
		width: 24px;
		aspect-ratio: 1;
		background: $clr_bg;
		border-radius: 4px;
		@include trans-normal;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			width: 4px;
			aspect-ratio: 4/8;
			background: $clr_default;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			@include trans-normal;
		}
	}
	.p-index-link__card-item-link{

		&:hover,&:focus-visible{
			.p-index-link__card-item-text{
				color: $clr_primary;
			}
			.p-index-link__card-item-arw{
				background: $clr_primary;
				&::before{
					background: #fff;
				}
			}
		}
	}
	@include mq (large){
		padding: 140px 0 50px;
		.p-index-link__header{
			margin-bottom: 24px;
			padding-left: 17px;
			font-size: font-size(13);
			&::before,
			&::after{
				width: 9px;
			}
		}
		.p-index-link__card{
			gap: 16px;
		}
		.p-index-link__card-item{
			width: calc(50% - (16px * 1 / 2) );
			aspect-ratio: 388/201;
		}
		.p-index-link__card-item-link{
			padding: 32px 32px 55px;
		}
		.p-index-link__card-item-text{
			font-size: font-size(20);
		}
		.p-index-link__card-item-img{
			width: 50px;
			margin-top: 16px;
		}
		.p-index-link__card-item-arw{
			right: 10px;
			bottom: 10px;
			width: 32px;
			&::before{
				width: 5px;
			}
		}
	}
	@media all and (1500px <= width) {
		.p-index-link__card-item{
			width: calc(25% - (16px * 3 /4) );
		}
		.p-index-link__card-item-text{
			font-size: vwFont(20);
		}
	}
}

/* p-index-intro
---------------------------------------- */
.p-index-intro{
	padding: 50px 0;
	.p-index-intro__inner{
		@include inner(1328px);
	}
	.p-index-intro__header{
		position: relative;
		margin-bottom: 68px;
		text-align: center;
		@include font_title;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			width: 205px;
			aspect-ratio: 1;
			background: url(../img/common/icon-crest.svg) no-repeat center /contain;
			z-index: -1;
		}
		&-sub{
			display: block;
			font-size: font-size(17);
		}
		&-main{
			display: block;
			margin-top: 5px;
			font-size: font-size(28);
		}
	}
	.p-index-intro__text{
		padding: 0 20px;
		color: $clr_text_secondary;
		line-height: 2;
		strong{
			color: $clr_default;
		}
	}
	@include mq (large){
		padding: 50px 0 80px;
		.p-index-intro__contents{
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 740px;
			&::before{
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				translate: -50% -50%;
				width: 740px;
				aspect-ratio: 1;
				background: url(../img/common/icon-crest.svg) no-repeat center /contain;
				z-index: -1;
			}
		}
		.p-index-intro__header{
			margin-bottom: 56px;
			&::before{
				content: none;
			}
			&-sub{
				font-size: font-size(40);
			}
			&-main{
				margin-top: 0;
				font-size: font-size(40);
			}
		}
		.p-index-intro__text{
			padding: 0;
			font-size: font-size(20);
		}
	}
}

 /*  p-index-date
 ---------------------------------------- */
 .p-index-date{
	padding: 50px 0 100px;
	.p-index-date__inner{
		@include inner(1328px);
	}
	.p-index-date__container{
		position: relative;
		border-bottom: 1px solid $clr_default;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			width: 514px;
			aspect-ratio: 514/530;
			background: url(../img/home/img-japan.svg) no-repeat center /contain;
			z-index: -1;
		}
	}
	.p-index-date__contents{
		border-top: 1px solid $clr_default;
		padding: 16px 0 32px;
		&-header{
			@include font_title;
			font-size: font-size(15);
			font-weight: 700;
		}
		&-body{
			margin-top: 24px;
			&.-mtLarge{
				margin-top: 32px;
			}
		}
	}
	.p-index-date__contents-body{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
	.p-index-date__contents-list{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 4px;
	}
	.p-index-date__contents-text{
		display: flex;
		flex-wrap: wrap;
		align-items: end;
		gap: 4px;
		&-num{
			@include font_en;
			letter-spacing: 0;
			font-size: font-size(45);
		}
		&-small{
			font-size: font-size(15);
			font-weight: 700;
		}
		&-side{
			font-size: font-size(17);
			font-weight: 700;
		}
	}
	.p-index-date__contents-item-add{
		margin-top: 2px;
		text-align: right;
		font-size: font-size(13);
	}
	.p-index-date__contents-iso{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		gap: 16px;
		&-item{
			width: 86px;
		}
	}
	@include mq (large){
		padding: 80px 0 200px;
		.p-index-date__container{
			position: relative;
			height: 690px;
			border-bottom: 0;
			&::before{
				width: 1050px;
			}
		}
		.p-index-date__contents{
			position: absolute;
			padding: 24px 20px 0;
			&:nth-child(1){
				top: 0;
				left: 0;
				width: 50%;
			}
			// &:nth-child(2){
			// 	top: 290px;
			// 	left: 0;
			// 	width: 40%;
			// }
			&:nth-child(2){
				top: 0;
				right: 0;
				width: 40%;
			}
			&:nth-child(3){
				bottom: 0;
				right: 0;
				width: 50%;
			}
			&-header{
				font-size: font-size(20);
			}
			&-body{
				margin-top: 24px;
				&.-mtLarge{
					margin-top: 40px;
				}
			}
		}
		.p-index-date__contents-list{
			gap: 10px;
		}
		.p-index-date__contents-text{
			gap: 12px;
			&-num{
				font-size: vwFont(80);
			}
			&-small{
				font-size: vwFont(24);
			}
			&-side{
				font-size: vwFont(30);
			}
		}
		.p-index-date__contents-item-add{
			margin-top: 10px;
			font-size: font-size(18);
		}
		.p-index-date__contents-iso{
			gap: 32px;
			&-item{
				width: 137px;
			}
		}
	}
	@include mq (full){
		.p-index-date__container{
			&::before{
				left: -16px;
				top: -48px;
				translate: 0% 0%;
			}
		}
		.p-index-date__contents{
			&:nth-child(1){
				width: vw(648);
			}
			&:nth-child(2){
				width: vw(376);
			}
			&:nth-child(3){
				width: vw(376);
			}
			&:nth-child(4){
				width: vw(648);
			}
		}
	}
 }

 /*  p-index-business
 ---------------------------------------- */
.p-index-business{
	padding: 100px 0;
	background: $clr_bg;
	.p-index-business__inner{
		position: relative;
		@include inner;
		z-index: 1;
		&::before{
			content: '';
			position: absolute;
			right: 0;
			bottom: -100px;
			width: 221px;
			height: 100px;
			background: #fff;
			mask: url(../img/common/bg-nav-logo.svg) no-repeat center /contain;
			z-index: -1;
		}
	}
	.p-index-business__item{
		&:nth-child(n+2){
		margin-top: 60px;
		}
	}
	.p-index-business__item-link{
		display: block;
		height: 100%;
	}
	.p-index-business__item-img{
		margin: 0 calc($padding * -1);
	}
	.p-index-business__item-text{
		margin-top: 24px;
	}
	.p-index-business__item-header{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 9px;
		margin-bottom: 20px;
		&-num{
			@include font_en;
			font-size: font-size(12);
			font-weight: 400;
		}
		&-ja{
			@include font_title;
			font-size: font-size(22);
			font-weight: 700;
		}
	}
	.p-index-business__item-text-row{
		color: $clr_text_gray;
	}
	.p-index-business__item-text-btn{
		position: relative;
		display: block;
		width: 105px;
		margin-top: 20px;
		padding-right: 17px;
		color: $clr_default;
		@include font_en;
		font-size: font-size(14);
		font-weight: 400;
		&::before{
			content:'';
			position: absolute;
			top: 50%;
			right: 0;
			translate: 0 -50%;
			width: 5px;
			height: 10px;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			background: $clr_default;
		}
	}
	@include mq (large){
		padding: 200px 0;
		.p-index-business__inner{
			&::before{
				bottom: -200px;
				right: 120px;
				width: 707px;
				height: 320px;
			}
		}
		.p-index-business__list{
			display: flex;
			justify-content: space-between;
			gap: 16px;
		}
		.p-index-business__item{
			position: relative;
			overflow: hidden;
			flex: 0 0 120px;
			height: 797px;
			cursor: pointer;
			@include trans-normal;
			transition: 0.3s;
			&::after{
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				height: 50%;
				border-radius: 4px 4px 0 0;
				opacity: 0.5;
				background-image: linear-gradient(to bottom, #000 0%, rgba(#000,0) 100%);
				z-index: 1;
			}
			&::before{
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 60%;
				border-radius: 0 0 4px 4px;
				opacity: 0.5;
				background-image: linear-gradient(to top, #000 0%, rgba(#000,0) 100%);
				z-index: 1;
			}
			&:nth-child(n+2){
				margin-top: 0;
			}
			&.is-active{
				flex: 1;
				&::after{
					content: none;
				}
				.p-index-business__item-text-row{
					opacity: 1;
					transition: opacity 0.1s 0.3s linear;
				}
				.p-index-business__item-header{
					left: 80px;
					translate: 0 0;
					animation: titleAnm 0.5s linear;
					&-num{
						font-size: font-size(20);
					}
					&-ja{
						writing-mode: horizontal-tb;
						text-orientation: mixed;
						font-size: font-size(40);
					}
				}
			}
		}
		.p-index-business__item-img{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			img{
				position: absolute;
				left: 50%;
				top: 50%;
				translate: -50% -50%;
				width: 100%;
				height: 797px;
				object-fit: cover;
				border-radius: 4px;
			}
		}
		.p-index-business__item-text{
			margin-top: 0;
		}
		.p-index-business__item-header{
			position: absolute;
			left: 50%;
			translate: -50% 0;
			top: 80px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			gap: 32px;
			margin-bottom: 0;
			color: #fff;
			z-index: 2;
			&-num{
				font-size: font-size(14);
			}
			&-ja{
				writing-mode: vertical-rl;
				text-orientation: upright;
				font-size: font-size(28);
			}
		}
		.p-index-business__item-text-row{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 80px;
			color: #fff;
			font-size: font-size(18);
			opacity: 0;
			transition: opacity 0s linear;
			z-index: 2;
		}
		.p-index-business__item-text-btn{
			margin: 32px 0 0 auto;
			color: #fff;
			&::before{
				background: #fff;
			}
		}
	}
	@include mq (full){
		.p-index-business__inner{
			&::before{
				right: 20px;
			}
		}
	}
}
@keyframes titleAnm {
	0%{
		opacity: 0;
	}
	50%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

/* p-index-products
---------------------------------------- */
.p-index-products{
	padding: 100px 0;
	.p-index-products__inner{
		@include inner;
	}
	@include mq (large){
		padding: 200px 0 192px;
	}
}

//p-index-products-pickup
.p-index-products-pickup{
	position: relative;
	padding: 60px 24px;
	&::before{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: $clr_bg;
		z-index: -1;
	}
	@include mq (large){
		padding: 100px 0;
		&::before{
			left: -80px;
			right: -80px;
		}
	}
}

//p-index-products-main
.p-index-products-main{
	margin-top: 60px;
	.p-index-products-main__list{
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}
	.p-index-products-main__item-link{
		overflow: hidden;
		position: relative;
		display: block;
		width: 100%;
		aspect-ratio: 335/223;
		border-radius: 4px;
		&::before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 110px;
			opacity: 0.3;
			background-image: linear-gradient(to bottom, #000 0%, rgba(#000,0) 100%);
			z-index: 1;
		}
	}
	.p-index-products-main__item-text{
		position: absolute;
		top: 20px;
		left: 20px;
		@include font_title;
		font-size: font-size(18);
		color: #fff;
		z-index: 1;
	}
	.p-index-products-main__item-img{
		@include trans-normal;
	}
	.p-index-products-main__item-arw{
		position: absolute;
		right: 6px;
		bottom: 6px;
		width: 24px;
		aspect-ratio: 1;
		border-radius: 4px;
		background: rgba(#fff,0.4);
		z-index: 1;
		@include trans-normal;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			background: #fff;
			width: 4px;
			aspect-ratio: 1/2;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			@include trans-normal;
		}
	}
	.p-index-products-main__item-link{
		@include trans-normal;
		&:hover,&:focus-visible{
			.p-index-products-main__item-img{
				scale: 1.1;
			}
			.p-index-products-main__item-arw::before{
				background: $clr_primary;
			}
			.p-index-products-main__item-arw{
				background: rgba(#fff,1);
			}
		}
	}
	@include mq (large){
		margin-top: 100px;
		.p-index-products-main__list{
			gap: 15px;
		}
		.p-index-products-main__item{
			width: calc(25% - (45px / 4));
		}
		.p-index-products-main__item-link{
			aspect-ratio: 388/582;
			&::before{
				height: 290px;
			}
		}
		.p-index-products-main__item-text{
			top: vw(40);
			left: vw(40);
			font-size: vwFont(22);
		}
		.p-index-products-main__item-arw{
			right: 16px;
			bottom: 16px;
			width: 32px;
			&::before{
				width: 5px;
			}
		}
	}
}

//p-index-products-other
.p-index-products-other{
	margin-top: 60px;
	.p-index-products-other__list{
		display: grid;
		grid-template-columns: repeat(2,1fr);
		grid-template-areas:
		'item01 item01'
		'item02 item03';
		gap: 8px;
	}
	.p-index-products-other__item{
		&:nth-child(1){ grid-area: item01; }
		&:nth-child(2){ grid-area: item02; }
		&:nth-child(3){ grid-area: item03; }
	}
	.p-index-products-other__item-link{
		position: relative;
		display: block;
		height: 100%;
		padding: 20px 20px 35px;
		border: 1px solid $clr_border;
		border-radius: 4px;
		@include trans-normal;
		&:hover,&:focus-visible{
			.p-index-products-other__item-text{
				color: $clr_primary;
			}
			.p-index-products-other__item-arw{
				background: $clr_primary;
				&::before{
					background: #fff;
				}
			}
		}
	}
	.p-index-products-other__item-text{
		@include font_title;
		font-size: font-size(15);
		@include trans-normal;
	}
	.p-index-products-other__item-icon{
		margin-top: 10px;
		width: 40px;
	}
	.p-index-products-other__item-arw{
		position: absolute;
		bottom: 6px;
		right: 6px;
		width: 24px;
		aspect-ratio: 1;
		background: $clr_bg;
		border-radius: 4px;
		@include trans-normal;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			width: 4px;
			aspect-ratio: 1/2;
			background: $clr_text_gray;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
		}
	}
	@include mq (large){
		margin-top: 60px;
		.p-index-products-other__list{
			display: grid;
			grid-template-columns: repeat(3,1fr);
			grid-template-areas:
			'item01 item02 item03';
			gap: 17px;
		}
		.p-index-products-other__item-link{
			aspect-ratio: 522/230;
			height: auto;
			padding: 40px 40px 55px;
		}
		.p-index-products-other__item-text{
			font-size: font-size(22);
		}
		.p-index-products-other__item-icon{
			margin-top: 16px;
			width: 60px;
		}
		.p-index-products-other__item-arw{
			bottom: 10px;
			right: 10px;
			width: 32px;
			&::before{
				width: 5px;
			}
		}
	}
}

/*  p-index-innovation
---------------------------------------- */
.p-index-innovation{
	padding: 60px 0;
	background: $clr_bg;
	.p-index-innovation__inner{
		@include inner;
	}
	.p-index-innovation__container{
		display: flex;
		flex-wrap: wrap;
		gap: 34px;
	}
	.p-index-innovation__contents{
		border-top: 1px solid $clr_default;
		width: 100%;
		padding: 0 10px;
	}
	.p-index-innovation__contents-item{
		padding: 16px 0 32px;
		&:nth-child(n+2){
			border-top: 1px solid $clr_border;
		}
		&-head{
			font-size: font-size(15);
			font-weight: 700;
		}
		&-text{
			margin-top: 2px;
			color: $clr_text_secondary;
			font-size: font-size(13);
		}
	}
	.p-index-innovation__contents-more{
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		padding-top: 16px;
	}
	.p-index-innovation__contents-more-item{
		overflow: hidden;
		width: 100%;
		border-radius: 4px;
	}
	.p-index-innovation__contents-more-item-link{
		position: relative;
		display: block;
		width: 100%;
		aspect-ratio: 315/177;
		&::before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 110px;
			opacity: 0.3;
			background-image: linear-gradient(to bottom, #000 0%, rgba(#000,0) 100%);
			z-index: 1;
		}
	}
	.p-index-innovation__contents-more-item-img{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include trans-normal;
		img{
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	.p-index-innovation__contents-more-item-text{
		position: absolute;
		top: 20px;
		left: 20px;
		color: #fff;
		@include font_title;
		font-size: font-size(18);
		z-index: 2;
	}
	.p-index-innovation__contents-more-item-arw{
		position: absolute;
		bottom: 6px;
		right: 6px;
		width: 24px;
		aspect-ratio: 1;
		background: rgba(#fff,0.2);
		border-radius: 4px;
		@include trans-normal;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			width: 4px;
			aspect-ratio: 1/2;
			background: #fff;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			@include trans-normal;
		}
	}
	.p-index-innovation__contents-more-item-link{
		@include trans-normal;
		&:hover,&:focus-visible{
			.p-index-innovation__contents-more-item-img{
				scale: 1.1;
			}
			.p-index-innovation__contents-more-item-arw{
				background: #fff;
				&::before{
					background: $clr_primary;
				}
			}
		}
	}
	@include mq (large){
		padding: 160px 0;
		.p-index3innovation__container{
			gap: 32px;
		}
		.p-index-innovation__contents{
			width: calc(50% - 17px);
			padding: 0 5px;
		}
		.p-index-innovation__contents-item{
			padding: 24px 20px;
			&-head{
				font-size: font-size(20);
			}
			&-text{
				margin-top: 6px;
				font-size: font-size(14);
			}
		}
		.p-index-innovation__contents-more{
			gap: 16px;
			padding-top: 24px;
		}
		.p-index-innovation__contents-more-item-link{
			aspect-ratio: 492/277;
			&::before{
				height: 139px;
			}
		}
		.p-index-innovation__contents-more-item-text{
			top: vw(40);
			left: vw(40);
			font-size: font-size(22);
		}
		.p-index-innovation__contents-more-item-arw{
			bottom: 10px;
			right: 10px;
			width: 32px;
			&::before{
				width: 5px;
			}
		}
	}
	@include mq (full){
		.p-index-innovation__wrap{
			display: grid;
			grid-template-columns: auto 1056px;
		}
	}
}

/*  p-index-company
---------------------------------------- */
.p-index-company{
	padding: 100px 0 40px;
	.p-index-company__inner{
		@include inner;
	}
	.p-index-company__list{
		display: grid;
		grid-template-columns: repeat(2,1fr);
		grid-template-areas:
			'area1 area1'
			'area2 area3'
			'area4 area4';
		gap: 8px;
	}
	.p-index-company__item{
		&:nth-child(1){
			grid-area: area1;
		}
		&:nth-child(2){
			grid-area: area2;
		}
		&:nth-child(3){
			grid-area: area3;
		}
		&:nth-child(4){
			grid-area: area4;
		}
	}
	.p-index-company__item-link{
		overflow: hidden;
		position: relative;
		display: block;
		border-radius: 4px;
		&::before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 94px;
			opacity: 0.3;
			background-image: linear-gradient(to bottom, #000 0%, rgba(#000,0) 100%);
			z-index: 1;
		}
	}
	.p-index-company__item-img{
		@include trans-normal;
	}
	.p-index-company__item-text{
		position: absolute;
		top: 20px;
		left: 20px;
		color: #fff;
		z-index: 1;
		font-size: font-size(18);
		@include font_title;
	}
	.p-index-company__item-arw{
		position: absolute;
		bottom: 6px;
		right: 6px;
		width: 24px;
		aspect-ratio: 1;
		background: rgba(#fff,0.2);
		border-radius: 4px;
		@include trans-normal;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			width: 4px;
			aspect-ratio: 1/2;
			background: #fff;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			@include trans-normal;
		}
	}
	.p-index-company__item-link{
		@include trans-normal;
		&:hover,&:focus-visible{
			.p-index-company__item-img{
				scale: 1.1;
			}
			.p-index-company__item-arw{
				background: #fff;
				&::before{
					background: $clr_primary
				}
			}
		}
	}
	@include mq (large){
		padding: 200px 0 80px;
		.p-index-company__list{
			grid-template-columns: vw(573) 1fr vw(573);
			grid-template-rows: 1fr 1fr;
			grid-template-areas:
				'area1 area1 area2'
				'area3 area4 area4';
			gap: 16px;
		}
		.p-index-company__item{
			&:nth-child(2),
			&:nth-child(3){
				aspect-ratio: 1/1;
			}
		}
		.p-index-company__item-link{
			height: 100%;
			&::before{
				height: vw(285);
			}
		}
		.p-index-company__item-img{
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			width: 100%;
			height: 100%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.p-index-company__item-text{
			top: vw(60);
			left: vw(62);
			font-size: vw(32);
		}
		.p-index-company__item-arw{
			bottom: 24px;
			right: 24px;
			width: 40px;
			&::before{
				width: 7px;
			}
		}
	}
}

/* p-index-topics
---------------------------------------- */
.p-index-topics{
	padding: 40px 0 100px;
	.p-index-topics__inner{
		@include inner;
	}
	.p-index-topics__body{
		border-top: 1px solid $clr_default;
	}
	@include mq (large){
		padding: 80px 0 200px;
	}
	@include mq (full){
		.p-index-topics__wrap{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.p-index-topics__header{
			align-self: start;
			width: calc(100% - 1190px);
		}
		.p-index-topics__body{
			width: 1190px;
		}
	}
}


/*  p-index-recruit
---------------------------------------- */
.p-index-recruit{
	.p-index-recruit__link{
		overflow: hidden;
		position: relative;
		display: block;
		width: 100%;
		aspect-ratio: 375/563;
	}
	.p-index-recruit__img{
		@include trans-normal;
	}
	.p-index-recruit__text{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 40px 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

	}
	.p-index-recruit__text-ja{
		color: #fff;
		font-size: font-size(20);
		font-weight: 700;
	}
	.p-index-recruit__text-en{
		align-self: end;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 16px;
		color: #fff;
		&-in{
			font-size: font-size(48);
			@include font_en;
		}
		&-arw{
			position: relative;
			display: inline-block;
			width: 40px;
			aspect-ratio: 1;
			border-radius: 4px;
			background: rgba(#fff,.2);
			@include trans-normal;
			&::before{
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				translate: -50% -50%;
				width: 7px;
				aspect-ratio: 1/2;
				background: #fff;
				mask: url(../img/common/icon-arw02.svg) no-repeat center /contain;
				@include trans-normal;
			}
		}
	}
	.p-index-recruit__link{
		@include trans-normal;
		&:hover,&:focus-visible{
			.p-index-recruit__img{
				scale: 1.1;
			}
			.p-index-recruit__text-en{
				&-arw{
					background: rgba(#fff,1);
					&::before{
						background: $clr_primary;
					}
				}
			}
		}
	}
	@include mq (large){
		.p-index-recruit__link{
			aspect-ratio: 1920/1080;
		}
		.p-index-recruit__text{
			padding: 80px 160px;

		}
		.p-index-recruit__text-ja{
			font-size: font-size(40);
		}
		.p-index-recruit__text-en{
			gap: 60px;
			&-in{
				font-size: vwFont(160);
			}
			&-arw{
				width: 160px;
				&::before{
					width: 30px;
				}
			}
		}
	}
}