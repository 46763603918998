@use '../../fundation/index' as *;

/*  c-img01
---------------------------------------- */
.c-img01{
	display: grid;
	gap: 8px;
	.c-img01__img{
		img{
			border-radius: 4px;
		}
	}
	@include mq (large){
		grid-template-columns: 63.5% auto;
		&.-rev{
			grid-template-columns: auto 63.5%;
		}
		.c-img01__img{
			img{
				object-fit: cover;
				height: 100%;
			}
		}
	}
	@include mq (full){
		grid-template-columns: 752px auto;
		gap: 17px;
		&.-rev{
			grid-template-columns: auto 752px;
		}
	}
}