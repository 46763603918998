@use '../../fundation/index' as *;
.c-card01{
	.c-card01__link{
		display: block;
		@include trans-normal;
		&:hover,&:focus-visible{
			opacity: .7;
		}
	}
	.c-card01__img{
		position: relative;
		img{
			border-radius: 4px;
			border: 1px solid $clr_border_secondary;
			background: #fff;
		}
		&-category{
			position: absolute;
			top: 10px;
			left: 10px;
			display: inline-block;
			padding: 5px;
			background: #1E3054;
			color: #fff;
			border-radius: 2px;
			font-size: font-size(13);
			line-height: 1.1;
		}
	}
	.c-card01__text{
		margin-top: 14px;
		&-name{
			@include font_title;
			font-size: font-size(15);
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		&-info{
			margin-top: 4px;
			font-size: font-size(13);
		}
		&-detail{
			margin-top: 8px;
			color: $clr_text_secondary;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
	@include mq (large){
		width: 512px;
		.c-card01__img{
			&-category{
				padding: 4px 8px;
				font-size: font-size(14);
			}
		}
		.c-card01__text{
			margin-top: 20px;
			&-name{
				font-size: font-size(18);
			}
			&-info{
				margin-top: 8px;
				font-size: font-size(14);
			}
			&-detail{
				margin-top: 12px;
			}
		}
	}
}