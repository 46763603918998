@use '../../fundation/index' as *;

/*  p-gallery
---------------------------------------- */
.p-gallery{
	.p-gallery__inner{
		@include inner;
	}
	.p-gallery__contents{
		padding-top: 60px;
	}
	@include mq (large){
		.p-gallery__contents{
			padding-top: calc(200px - var(--headerH));
		}
	}
}

/*  p-gallery-sec
---------------------------------------- */
.p-gallery-sec{
	padding: 50px 0;
	&:first-child{
		padding-top: 0;
	}
	&:last-child{
		padding-bottom: 100px;
	}
	.p-gallery-sec__list{
		display: flex;
		flex-wrap: wrap;
		gap: 32px;
	}
	.p-gallery-sec__item{
		width: 100%;
	}
	.p-gallery-sec__item-video-iframe{
		position: relative;
		display: block;
		width: 100%;
		aspect-ratio: 16/9;
		iframe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.p-gallery-sec__item-in{
		border-radius: 4px;
	}
	.p-gallery-sec__item-title{
		margin-top: 16px;
		@include font_title;
	}
	@include mq (large){
		padding: 100px 0;
		&:last-child{
			padding-bottom: 240px;
		}
		.p-gallery-sec__list{
			gap: 40px 16px;
		}
		.p-gallery-sec__item{
			width: calc(50% - (16px / 2));
		}
	}
	@include mq (full){
		.p-gallery-sec__list{
			gap: 60px 32px;
		}
		.p-gallery-sec__item{
			width: calc(50% - (32px / 2));
		}
		.p-gallery-sec__item-title{
			margin-top: 18px;
			font-size: font-size(18);
		}
	}
}