@use '../../fundation/index' as *;

/*  c-layout01
---------------------------------------- */
.c-layout01{
	display: flex;
	flex-wrap: wrap;
	gap: 100px;
	.c-layout01__anchor{
		width: 100%;
	}
	.c-layout01__anchor-title{
		position: relative;
		margin-bottom: 12px;
		padding-left: 12px;
		color: $clr_text_gray;
		@include font_en;
		font-size: font-size(12);
		letter-spacing: 0;
		&::before,
		&::after{
			content:'';
			position: absolute;
			top: 50%;
			left: 0;
			translate: 0 -50%;
			width: 7px;
			height: 1px;
			background: $clr_text_gray;
		}
		&::after{
			rotate: 90deg;
		}
	}
	.c-layout01__anchor-list{
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		gap: 8px;
	}
	.c-layout01__anchor-item{
		width: calc(50% - (8px / 2));
	}
	.c-layout01__anchor-link{
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		padding: 20px 40px 20px 20px;
		border: 1px solid $clr_border;
		border-radius: 4px;
		@include trans-normal;
		&::before{
			content:'';
			position: absolute;
			top: 50%;
			right: 20px;
			translate: 0 -50%;
			width: 10px;
			height: 10px;
			rotate: 90deg;
			background: url(../img/common/icon-arw01.svg) no-repeat center /contain;
		}
		&:hover,&:focus-visible{
			opacity: 0.5;
		}
		&.is-active{
			opacity: 0.5;
		}
	}
	.c-layout01__contents{
		width: 100%;
	}
	@include mq (large){
		gap: 60px;
		align-items: start;
		.c-layout01__anchor{
			width: 200px;
			padding-top: var(--headerH);
			position: sticky;
			top: 50px;
			left: 0;
		}
		.c-layout01__anchor-inner{
			padding-bottom: 200px;
		}
		.c-layout01__anchor-title{
			margin-bottom: 24px;
			padding-left: 16px;
			font-size: font-size(13);
			&::before,
			&::after{
				width: 9px;
			}
		}
		.c-layout01__anchor-list{
			display: block;
			padding: 22px;
			border: 1px solid $clr_border;
			border-radius: 4px;
		}
		.c-layout01__anchor-item{
			width: 100%;
		}
		.c-layout01__anchor-link{
			display: block;
			padding: 8px 0;
			border: 0;
			&::before{
				content: none;
			}
		}
		.c-layout01__contents{
			padding-top: var(--headerH);
			width: calc(100% - (200px + 60px));
		}
	}
	@include mq (full){
		gap: 136px;
		.c-layout01__anchor{
			width: 272px;
		}
		.c-layout01__anchor-list{
			padding: 22px 32px;
		}
		.c-layout01__contents{
			width: calc(100% - (272px + 136px));
		}
	}
}

/*  c-layout02
---------------------------------------- */
.c-layout02{
	.c-layout02__item{
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
		padding: 40px 32px;
		background: $clr_bg;
		border-radius: 4px;
		&:nth-child(n+2){
			margin-top: 8px;
		}
	}
	.c-layout02__item-num{
		color: $clr_primary;
		@include font_en;
		font-size: font-size(12);
		letter-spacing: 0;
	}
	@include mq (large){
		.c-layout02__item{
			gap: 50px;
			padding: 50px;
			&:nth-child(n+2){
				margin-top: 16px;
			}
		}
		.c-layout02__item-num{
			width: 65px;
			font-size: font-size(14);
			line-height: 1.8;
		}
		.c-layout02__item-text{
			width: calc(100% - (50px + 65px));
			font-size: font-size(18);
		}
	}
	@include mq (full){
		.c-layout02__item{
			gap: 75px;
			padding: 77px 80px;
		}
		.c-layout02__item-text{
			width: calc(100% - (65px + 75px));
		}
	}
}

/*  c-layout03
---------------------------------------- */
.c-layout03{
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	.c-layout03__img{
		width: 100%;
	}
	.c-layout03__img-link{
		display: block;
		@include trans-normal;
		&:hover,&:focus-visible{
			opacity: 0.5;
		}
	}
	.c-layout03__contents{
		width: 100%;
	}
	@include mq (large){
		gap: 35px;
		.c-layout03__img{
			width: 45%;
		}
		.c-layout03__contents{
			width: calc(100% - (45% + 35px));
		}
	}
	@include mq (full){
		gap: 80px;
		.c-layout03__img{
			width: 400px;
		}
		.c-layout03__contents{
			width: calc(100% - (400px + 80px));
		}
	}
}

.c-layout03.-gapLarge{
	gap: 40px;
	@include mq (large){
		gap: 60px;
		.c-layout03__contents{
			width: calc(100% - (45% + 60px));
		}
	}
	@include mq (full){
		gap: 120px;
		.c-layout03__contents{
			width: calc(100% - (400px + 120px));
		}
	}
}