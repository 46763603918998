@use '../../fundation/index' as *;
/*  デフォルト
----------------------------------- */
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  border-radius: 0;
  border: 1px solid $clr_border;
  background: #fff;
}
input[type='text'],
input[type='email'],
textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 13px 16px;
  border-radius: 4px;
}
input::placeholder,
textarea::placeholder {
  color: $clr_text_gray;
  font-size: font-size(16);
}
input:-ms-input-placeholder {
  color: $clr_text_gray;
  font-size: font-size(16);
}
input::-ms-input-placeholder {
  color: $clr_text_gray;
  font-size: font-size(16);
}
button {
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
}
label {
  cursor: pointer;
}
input[type='checkbox'] {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
  &:focus {
    + span {
      &::before {
        background: $clr_text_gray;
      }
    }
  }
  + span {
    position: relative;
    display: inline-block;
    padding-left: 27px;
    &::before {
      content: '';
      position: absolute;
      top: 52%;
      left: 0;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      border: 1px solid $clr_primary;
      border-radius: 2px;
    }
  }
}
input[type='checkbox']:checked {
  + span {
    &::after {
      content: '';
      position: absolute;
      top: 40%;
      left: 4px;
      width: 10px;
      height: 5px;
      border-left: 2px solid $clr_primary;
      border-bottom: 2px solid $clr_primary;
      transform: rotate(-45deg);
    }
  }
}

/*  c-form01
----------------------------------- */
.c-form01 {
  .c-form01__contents {
    padding: 40px 20px;
    background: $clr_bg;
  }
  > * {
    font-size: font-size(16);
  }
  .c-form01__contents-item {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    gap: 16px;
    &:first-child {
      padding-top: 0;
    }
  }
  .c-form01__contents-ttl {
    font-weight: 700;
  }
  .c-form01__contents-ttl-required {
    display: inline-block;
    margin-left: 4px;
    padding: 1px 3px;
    color: #fff;
    font-size: font-size(12);
    background: $clr_primary;
  }
  .c-form01__contents-add-num {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .c-form01__contents-add-input {
    padding-top: 10px;
    &:first-child {
      padding-top: 0;
    }
  }
  .c-form01__privacy {
    padding-top: 40px;
  }
  .c-form01__privacy-ttl {
    padding-bottom: 16px;
    font-weight: 700;
  }
  .c-form01__privacy-txt {
    padding: 24px;
    color: $clr_text_gray;
    border: 1px solid $clr_border;
  }
  .c-form01__contents-check {
    padding-top: 16px;
    text-align: center;
  }
  .c-form01__contents-btnWrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    justify-content: center;
    gap: 10px;
  }
  .c-form01__contents-btn {
    padding: 13px;
    width: 220px;
    color: $clr_default;
    border: 1px solid $clr_default;
    border-radius: 4px;
    background: #fff;
    @include trans-normal;
    &:hover,
    &:focus-visible {
      color: #fff;
      background: $clr_primary;
    }
  }
  .c-form01__contents-btn.-back {
    background: $clr_border;
    &:hover,
    &:focus-visible {
      background: $clr_text_gray;
    }
  }
  @include mq(large) {
    .c-form01__contents {
      padding: 80px 100px;
    }
    .c-form01__contents-item {
      flex-direction: row;
      align-items: center;
      gap: 50px;
      dd {
        width: calc(100% - (20% + 50px));
      }
    }
    .c-form01__contents-ttl {
      width: 20%;
    }
    .c-form01__contents-ttl-required {
      margin-left: 10px;
    }
    .c-form01__contents-add-num {
      gap: 16px;
    }
    .c-form01__privacy {
      padding-top: 46px;
    }
    .c-form01__contents-btnWrap {
      margin-top: 56px;
    }
    .c-form01__contents-btn {
      padding: 18px;
      width: 360px;
      font-size: font-size(18);
    }
  }
}

.c-form01 {
  .c-form01__contents-item {
    .c-form01__contents-ttl.-pcCenter {
      align-self: self-start;
    }
  }
}
