@charset "utf-8";
@use './variable' as *;


/*  reset
================================================ */
* {
  margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	box-sizing: border-box;
	&::before,
	&::after{
		box-sizing: border-box;
	}
}

html{
	word-break: break-all;
}
body {
  -webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;

}
img, picture, video, canvas, svg {
	width: 100%;
	vertical-align: bottom;
}
input, button, textarea, select {
  font: inherit;
}
span{
	font-weight: inherit;
}
a{
	color: inherit;
}
a[class] {
	text-decoration: none;
}
mark {
	background-color:#ff9;
	font-weight:bold;
}
blockquote, q {
	quotes:none;
}
ul[class],
ol[class] {
	list-style:none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content:'';
	content:none;
}
table {
	width: 100%;
	table-layout: fixed;
	border-collapse:collapse;
	border-spacing:0;
}
#root, #__next {
  isolation: isolate;
}
//form
input[type="text"],input[type="email"],input[type="tel"], textarea, button, select, option {
	display: block;
	width: 100%;
	font: inherit;
	outline: none;
	border-radius: 0;
}
textarea{
	resize: vertical;
}
button{
	cursor: pointer;
}
address{
	font-style: normal;
}
summary{
	list-style: none;
}
summary::-webkit-details-marker {
  display:none;
}
