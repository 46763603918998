@use '../../fundation/index' as *;

/*  c-tag01
---------------------------------------- */
.c-tag01{
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	.c-tag01__item{
		padding: 2px 8px;
		color: #fff;
		background: $clr_primary;
		border-radius: 2px;
		font-size: font-size(13);
	}
	@include mq (large){
		.c-tag01__item{
			font-size: font-size(14);
		}
	}
}