@use '../../fundation/index' as *;

/*  p-recruit
---------------------------------------- */
.p-recruit{
	.p-recruit__inner{
		@include inner;
	}
	.p-recruit__contents{
		padding-top: 60px;
	}
	@include mq (large){
		.p-recruit__contents{
			padding-top: calc(200px - var(--headerH));
		}
	}
}

/*  p-recruit-about
---------------------------------------- */
.p-recruit-about{
	padding-bottom: 50px;
	.p-recruit-about__cardWrap{
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}
	.p-recruit-about__card{
		width: 100%;
		padding: 40px 32px;
		background: $clr_bg;
		border-radius: 4px;
	}
	.p-recruit-about__card-icon{
		width: 40px;
		margin-bottom: 24px;
	}
	.p-recruit-about__card-title{
		@include font_title;
		font-size: font-size(18);
		line-height: 1.4;
	}
	.p-recruit-about__card-text{
		margin-top: 14px;
		color: $clr_text_secondary;
	}
	.p-recruit-about__img{
		margin-top: 40px;
		img{
			border-radius: 4px;
		}
	}
	.p-recruit-about__contents{
		margin-top: 40px;
	}
	.p-recruit-about__contents-text{
		color: $clr_text_secondary;
	}
	.p-recruit-about__list{
		margin-top: 20px;
	}
	@include mq (large){
		padding-bottom: 100px;
		.p-recruit-about__card{
			width: calc(50% - (8px / 2));
		}
		.p-recruit-about__card-icon{
			width: 50px;
			margin-bottom: 32px;
		}
		.p-recruit-about__card-text{
			margin-top: 20px;
		}
		.p-recruit-about__img{
			margin-top: 60px;
		}
		.p-recruit-about__contents{
			margin-top: 60px;
		}
		.p-recruit-about__list{
			margin-top: 32px;
		}
	}
	@include mq (full){
		.p-recruit-about__cardWrap{
			gap: 16px;
		}
		.p-recruit-about__card{
			width: calc(50% - (16px / 2));
			padding: 60px 80px;
		}
		.p-recruit-about__card-title{
			font-size: font-size(20);
		}
	}
}

/*  p-recruit-date
---------------------------------------- */
.p-recruit-date{
	padding: 50px 0;
	.p-recruit-date__container{
		display: grid;
		gap: 32px;
	}
	.p-recruit-date__contents{
		padding: 16px 10px 0 10px;
		border-top: 1px solid $clr_default;
	}
	.p-recruit-date__contents-head{
		position: relative;
		padding-left: 23px;
		@include font_title;
		line-height: 1.2;
	}
	.p-recruit-date__contents-head-icon{
		position: absolute;
		top: 0;
		left: 0;
		width: 17px;
	}
	.p-recruit-date__contents-body{
		text-align: right;
		margin-top: 16px;
	}
	.p-recruit-date__contents-text{
		@include font_en;
		font-size: font-size(45);
		letter-spacing: 0;
	}
	.p-recruit-date__contents-text-side{
		margin-left: 4px;
		@include font_title;
		font-size: font-size(17);
	}
	.p-recruit-date__contents-img{
		width: 232px;
		margin: 0 0 0 auto;
	}
	@include mq (large){
		padding: 100px 0;
		.p-recruit-date__container{
			grid-template-columns: repeat(2 , 1fr);
			gap: 85px 16px;
			.p-recruit-date__contents{
				&:nth-child(1){
					grid-area: 1/1/2/2;
				}
				&:nth-child(2){
					grid-area: 2/1/3/2;
				}
				&:nth-child(3){
					grid-area: 1/2/3/3;
					padding: 26px 0 0 20px;
				}
			}
		}
		.p-recruit-date__contents{
			padding: 26px 20px 0 20px;
		}
		.p-recruit-date__contents-body{
			margin-top: 32px;
		}
		.p-recruit-date__contents-text{
			font-size: font-size(56);
		}
		.p-recruit-date__contents-text-side{
			margin-left: 8px;
			font-size: font-size(20);
		}
		.p-recruit-date__contents-img{
			width: min(100%,336px);
		}
	}
	@include mq (full){
		.p-recruit-date__container{
			gap: 85px 32px;
		}
		.p-recruit-date__contents-head{
			padding-left: 41px;
			font-size: font-size(20);
		}
		.p-recruit-date__contents-head-icon{
			width: 27px;
		}
		.p-recruit-date__contents-text{
			font-size: font-size(64);
		}
		.p-recruit-date__contents-text-side{
			font-size: font-size(26);
		}
	}
}

/*  p-recruit-works
---------------------------------------- */
.p-recruit-works{
	padding: 50px 0;
	.p-recruit-works__contents{
		&:nth-of-type(n+2){
			margin-top: 40px;
		}
	}
	.p-recruit-works__contents-box{
		margin-top: 20px;
		padding: 24px;
		background: $clr_bg;
		border-radius: 4px;
	}
	.p-recruit-works__contents-text{
		margin-top: 20px;
		color: $clr_text_secondary;
	}
	@include mq (large){
		padding: 100px 0;
		.p-recruit-works__contents{
			&:nth-of-type(n+2){
				margin-top: 80px;
			}
		}
		.p-recruit-works__contents-box{
			margin-top: 32px;
			padding: 40px;
		}
		.p-recruit-works__contents-text{
			margin-top: 32px;
			+.p-recruit-works__contents-box{
				margin-top: 24px;
			}
		}
	}
	@include mq (full){
		.p-recruit-works__contents-box{
			padding: 50px 60px;
		}
	}
}

/*  p-recruit-system
---------------------------------------- */
.p-recruit-system{
	padding: 50px 0;
	.p-recruit-system__flex{
		display: flex;
		flex-wrap: wrap;
		gap: 32px;
		&:nth-of-type(n+2){
			margin-top: 32px;
		}
		&.-col4{
			gap: 32px 8px;
			.p-recruit-system__contents{
				width: calc(50% - (8px / 2));
				border-top: 1px solid $clr_border;
			}
		}
	}
	.p-recruit-system__contents{
		width: 100%;
		padding: 16px 10px 0 10px;
		border-top: 1px solid $clr_default;
	}
	.p-recruit-system__contents-head{
		@include font_title;
	}
	.p-recruit-system__contents-text{
		margin-top: 3px;
		color: $clr_text_secondary;
		font-size: font-size(13);
	}
	.p-recruit-system__contents-icon{
		margin: 16px 0 0 auto;
		width: 40px;
	}
	.p-recruit-system__gallery{
		display: flex;
		width: calc(100vw - 20px);
		margin: 60px -20px 0 -120px;
	}
	.p-recruit-system__gallery-img{
		padding-right: 8px;
		&:first-child{
			animation: loop 50s -25s linear infinite;
		}
		&:last-child{
			animation: loop2 50s linear infinite;
		}
		img{
			width: auto;
			height: 164px;
		}
	}
	@include mq (medium){
		.p-recruit-system__gallery{
			width: calc(100vw - 120px);
			margin-right: -120px;
		}
	}
	@include mq (large){
		padding: 100px 0;
		.p-recruit-system__flex{
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			&.-col3{
				.p-recruit-system__contents{
					width: calc(50% - (16px / 2));
				}
			}
			&.-col4{
				.p-recruit-system__contents{
					width: calc(50% - (16px / 2));
				}
			}
		}
		.p-recruit-system__contents{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 20px;
			padding: 24px 20px 0 20px;
		}
		.p-recruit-system__contents-head{
			font-size: font-size(20);
		}
		.p-recruit-system__contents-text{
			margin-top: 5px;
			font-size: font-size(14);
		}
		.p-recruit-system__contents-icon{
			margin: 0 0 0 auto;
			width: 60px;
		}
		.p-recruit-system__gallery{
			margin: 100px 0 0 0;
			overflow: hidden;
		}
		.p-recruit-system__gallery-img{
			padding-right: 16px;
			img{
				height: 300px;
			}
		}
	}
	@include mq (full){
		.p-recruit-system__flex{
			display: flex;
			flex-wrap: wrap;
			gap: 80px 32px;
			&:nth-of-type(n+2){
				margin-top: 80px;
			}
			&.-col3{
				.p-recruit-system__contents{
					width: calc(33.33% - (64px / 3));
				}
			}
			&.-col4{
				gap: 80px 32px;
				.p-recruit-system__contents{
					width: calc(25% - (96px / 4));
				}
			}
		}
		.p-recruit-system__gallery-img{
			img{
				height: 386px;
			}
		}
	}
}
@keyframes loop {
	0% {
		transform: translateX(100%);
	}
	to {
		transform: translateX(-100%);
	}
}
@keyframes loop2 {
	0% {
		transform: translateX(0);
	}
	to {
		transform: translateX(-200%);
	}
}

/*  p-recruit-recruitment
---------------------------------------- */
.p-recruit-recruitment{
	padding: 50px 0 100px;
	.p-recruit-recruitment__contents{
		&:nth-of-type(n+2){
			margin-top: 40px;
		}
	}
	@include mq (large){
		padding: 100px 0 200px;
		.p-recruit-recruitment__contents{
			&:nth-of-type(n+2){
				margin-top: 80px;
			}
		}
	}
}