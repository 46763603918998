@use '../../fundation/index' as *;

/*  p-products
---------------------------------------- */
.p-products{
	.p-products__inner{
		@include inner;
	}
	.p-products__contents{
		padding-top: 60px;
	}
	@include mq (large){
		.p-products__contents{
			padding-top: calc(200px - var(--headerH));
		}
	}
}

/*  p-products-turnout
---------------------------------------- */
.p-products-turnout{
	padding-bottom: 50px;
	.p-products-turnout__contents{
		&:nth-of-type(n+2){
			margin-top: 40px;
		}
	}
	@include mq (large){
		padding-bottom: 100px;
		.p-products-turnout__contents{
			&:nth-of-type(n+2){
				margin-top: 80px;
			}
		}
	}
}

/*  p-products-sec
---------------------------------------- */
.p-products-sec{
	padding: 50px 0;
	@include mq (large){
		padding: 100px 0;
	}
}

/*  p-products-plan
---------------------------------------- */
.p-products-plan{
	padding: 50px 0;
	@include mq (large){
		padding: 100px 0;
	}
}

/*  p-products-gallery
---------------------------------------- */
.p-products-gallery{
	padding: 50px 0 100px;
	@include mq (large){
		padding: 100px 0 240px;
	}
}