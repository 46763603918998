@use '../../fundation/index' as *;

/*  c-list01
---------------------------------------- */
.c-list01{
	.c-list01__item{
		position: relative;
		padding-left: 13px;
		color: $clr_text_secondary;
		&:nth-child(n+2){
			margin-top: 3px;
		}
		&::before{
			content:'';
			position: absolute;
			top: 13px;
			left: 0;
			width: 6px;
			height: 2px;
			background: $clr_primary;
		}
	}
	@include mq (large){
		.c-list01__item{
			padding-left: 18px;
			&::before{
				top: 13px;
				width: 8px;
			}
		}
	}
}
.c-list01.-default{
	.c-list01__item{
		color: $clr_default;
	}
}

/*  c-list02
---------------------------------------- */
.c-list02{
	counter-reset: number 0;
	.c-list02__item{
		position: relative;
		padding-left: 24px;
		color: $clr_text_secondary;
		&::before{
			position: absolute;
			top: 7px;
			left: 0;
			counter-increment: number 1;
			content: counter(number) ".";
			color: $clr_primary;
			@include font_en;
		}
		&:nth-child(n+2){
			margin-top: 3px;
		}
	}
	@include mq (large){
		.c-list02__item{
			padding-left: 26px;
		}
	}
}

/*  c-list03
---------------------------------------- */
.c-list03{
	.c-list03__item{
		&:first-child{
			border-top: 1px solid $clr_border;
		}
	}
	.c-list03__item-link{
		position: relative;
		display: block;
		padding: 10px 35px 10px 0;
		border-bottom: 1px solid $clr_border;
		@include trans-normal;
		&::before,
		&::after{
			content:'';
			position: absolute;
			top: 50%;
			translate: 0 -50%;
			@include trans-normal;
		}
		&::before{
			right: 0;
			width: 24px;
			height: 24px;
			background: $clr_bg;
			border-radius: 2px;
		}
		&::after{
			right: 10px;
			width: 4px;
			height: 8px;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			background: $clr_default;
		}
		&:hover,&:focus-visible{
			color: $clr_primary;
			&::before{
				background: $clr_primary;
			}
			&::after{
				background: #fff;
			}
		}
	}
	@include mq (full){
		display: flex;
		flex-wrap: wrap;
		gap: 0 40px;
		.c-list03__item{
			width: calc(50% - (40px / 2));
			&:nth-child(2){
				border-top: 1px solid $clr_border;
			}
		}
		.c-list03__item-link{
			display: flex;
			align-items: center;
			height: 100%;
			padding: 18px 40px 18px 0;
			&::before{
				width: 32px;
				height: 32px;
				border-radius: 4px;
			}
			&::after{
				right: 13px;
				width: 5px;
				height: 10px;
			}
		}
	}
}

.c-list03.-anchor{
	.c-list03__item-link{
		&::before{
			background: $clr_border;
		}
		&::after{
			rotate: 90deg;
		}
		&:hover,&:focus-visible{
			&::before{
				background: $clr_primary;
			}
		}
	}
}