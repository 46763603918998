@use './variable' as *;

/*  base
================================================ */
html {
	overflow: auto;
	scroll-behavior: smooth;
	&:not([lang="ja"]) {
		word-break: break-word;
	}
}
body {
	overflow: clip;
	min-width: 320px;
	color: $clr_default;
	@include font_base();
	@include mq(medium) {
		min-width: auto;
	}
}