@use '../../fundation/index' as *;

/*  p-info
----------------------------------- */
.p-info {
  padding: 100px 0;
  .p-info__inner {
    @include inner(1096px);
  }
  .p-info__contents {
    border-top: 1px solid $clr_default;
  }
  @include mq(large) {
    padding: 200px 0 240px;
  }
}

/*  p-info-single
----------------------------------- */
.p-info-single {
  padding: 60px 0 100px;
  .p-info-single__inner {
    @include inner(1096px);
  }
  @include mq(large) {
    padding: 195px 0 240px;
  }
}
