@use '../../fundation/index' as *;
/*  p-contact
----------------------------------- */
.p-contact {
  padding: 60px 0 100px;
  .p-contact__inner {
    @include inner(1096px);
  }
  .p-contact__intro {
    padding: 40px 0;
    border-top: 1px solid $clr_border;
    border-bottom: 1px solid $clr_border;
  }
  .p-contact__intro-ttlArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .p-contact__intro-ico {
    width: 28px;
  }
  .p-contact__intro-ttl {
    font-size: font-size(26);
  }
  .p-contact__intro-note {
    padding-top: 20px;
  }
  .p-contact__intro-note-item {
    font-size: font-size(13);
    color: $clr_text_gray;
  }
  .p-contact__intro-tel {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 8px;
  }
  .p-contact__intro-tel-link {
    display: block;
    padding: 16px;
    text-align: center;
    background: $clr_bg;
    border-radius: 4px;
    @include trans-normal;
    &:hover,
    &:focus-visible {
      opacity: 0.5;
    }
  }
  .p-contact__intro-tel-en {
    font-size: font-size(13);
  }
  .p-contact__intro-tel-txt {
    display: inline-block;
    padding-left: 16px;
    font-size: font-size(20);
    @include font_en;
  }
  .p-contact-sec {
    padding-top: 100px;
  }
  .p-contact__form-contents {
    margin: 0 -20px;
  }
  @include mq(large) {
    padding: 200px 0 240px;
    .p-contact__intro {
      padding: 80px 35px;
    }
    .p-contact__intro-ttlArea {
      flex-direction: row;
      gap: 20px;
    }
    .p-contact__intro-ico {
      width: 38px;
    }
    .p-contact__intro-ttl {
      width: calc(100% - 58px);
      font-size: font-size(40);
    }
    .p-contact__intro-note {
      padding-top: 14px;
    }
    .p-contact__intro-tel {
      flex-direction: row;
      padding-top: 22px;
      gap: 32px;
    }
    .p-contact__intro-tel-link {
      padding: 20px;
      width: calc(50% - 16px);
    }
    .p-contact__intro-tel-en {
      font-size: font-size(20);
    }
    .p-contact__intro-tel-txt {
      padding-left: 24px;
      font-size: font-size(28);
    }
    .p-contact-sec {
      padding-top: 200px;
    }
    .p-contact__form-contents {
      margin: 0;
    }
  }
}
