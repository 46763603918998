@charset "UTF-8";

/*  color
================================================ */
$clr_default: #1A1A1A;
$clr_text_secondary: #505050;
$clr_text_gray: #777;
$clr_primary: #4D1798;
$clr_border: #DBDBDB;
$clr_border_secondary: #EDEDED;
$clr_bg: #F5F5F5;
//$shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);

//:root{
//	.hoge{
//		--primary: #{$clr_primary};
//		--secondary: #{$clr_secondary};
//		--ico: url() no-repeat center /contain;
//	}
//}

/*  width
================================================ */
$contents_width: 1600px;
$padding: 20px; //左右の余白を指定
$contents_plus_padding: calc($contents_width + ($padding * 2)); //1640px
$adjust: $padding * 6;
$boundary: 768px + ($adjust * 2);

//inner
@mixin inner( $contentWidth: $contents_plus_padding){
	width: min(100%, $contentWidth);
	margin: 0 auto;
	padding-left: $padding;
	padding-right: $padding;
	@media all and ( 768px <= width < $contents_plus_padding ){
		width: 100%;
		padding-left: $adjust;
		padding-right: $adjust;
	}
}
// breakpoints
$breakpoints: (
	'small': 'all and ( width < 768px)',
	'medium': 'all and ( 768px <= width )',
	'large': 'all and ( ' + $boundary +' <= width )', //768 + $adjust x 2(両サイド＋768px でコンテンツ自体は768pxのサイズでおさまる範囲)
	'full': 'all and ( ' +  $contents_plus_padding +' < width )',
	//'max': 'all and ( px <= width	)',
) !default;
@mixin mq($breakpoint: tb) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}



/*  transition
================================================ */
$speed: cubic-bezier(0.71, 0.41, 0.37, 0.72);

@mixin trans-normal( $prop: all ){
	transition: .5s $speed;
	transition-property: $prop;
}

/*  font
================================================ */
@function font-size($font-size-px) {
  @return calc($font-size-px / 16 * 1rem);
}

@mixin font_base(){
	font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
	line-height: 1.8;
	letter-spacing: 0;
	font-weight: 400;
	font-size: font-size(15);
	@include mq (medium) {
		font-size: font-size(16);
	}
}
@mixin font_title(){
	font-weight: 700;
	line-height: 1.6;
	@include mq (medium) {
	}
}

@mixin font_en(){
	font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
	letter-spacing: .08em;
	line-height: 1.1;
	text-transform: uppercase;
}

/*  vw計算
---------------------------------------- */
@function vw($pc, $baseViewportWidth: 1920) {
	$max-size: $pc * 1px;
	$min-size: calc(($pc * 0.75) * (100 / 1920 * 1vw));
	$variable-size: calc($pc / $baseViewportWidth) * 100vw;
	@return clamp($min-size,$variable-size,$max-size);
}

@function vwFont($pc, $baseViewportWidth: 1920) {
	$max-size: calc($pc / 16) * 1rem;
	$min-size: calc($pc / 16) * 0.75rem;
	$variable-size: calc($pc / $baseViewportWidth) * 100vw;
	@return clamp($min-size,$variable-size,$max-size);
}

/*  header
---------------------------------------- */
:root{
	--headerH: 56px;
	@include mq (large){
		--headerH: 100px;
	}
}