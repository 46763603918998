@use '../../fundation/index' as *;

/*  p-products-single-contents
---------------------------------------- */
.p-products-single-contents{
	padding: 60px 0;
	background: $clr_bg;
	.p-products-single-contents__inner{
		@include inner;
	}
	.p-products-single-contents__body{
		display: grid;
		gap: 40px;
	}
	.p-products-single-contents__imgWrap{
		width: 100%;
	}
	.p-products-single-contents__mainImg{
		img{
			width: 100%;
			height: 100%;
			border: 1px solid $clr_border;
			border-radius: 4px;
			aspect-ratio: 760/410;
			object-fit: cover;
			background: #fff;
		}
	}
	.p-products-single-contents__subImg-wrap{
		display: grid;
		grid-template-columns: repeat(3,1fr);
		gap: 4px;
		margin-top: 4px;
	}
	.p-products-single-contents__subImg{
		cursor: pointer;
		&.-current{
			position: relative;
			&::before{
				content:'';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				opacity: 0.3;
				background: $clr_default;
				border-radius: 2px;
				z-index: 1;
			}
		}
	}
	.p-products-single-contents__subImg{
		display: block;
		img{
			border: 1px solid $clr_border;
			border-radius: 2px;
		}
	}
	.p-products-single-contents__title{
		margin-bottom: 36px;
		@include font_title;
		font-size: font-size(26);
	}
	.p-products-single-contents__detail{
		margin-top: 20px;
	}
	.p-products-single-contents__detail-title{
		margin-bottom: 12px;
		padding-bottom: 8px;
		border-bottom: 1px solid $clr_border;
		@include font_title;
		font-size: font-size(16);
	}
	.p-products-single-contents__detail-text{
		p{
			color: $clr_text_secondary;
		}
		ul{
			list-style: none;
			li{
				position: relative;
				padding-left: 13px;
				color: $clr_text_secondary;
				&:nth-child(n+2){
					margin-top: 3px;
				}
				&::before{
					content:'';
					position: absolute;
					top: 13px;
					left: 0;
					width: 6px;
					height: 2px;
					background: $clr_primary;
				}
			}
		}
		strong,b{
			font-weight: 700;
		}
	}
	.p-products-single-contents__table{
		td{
			background: #fff;
		}
	}
	.p-products-single-contents__table-body{
		width: max(100%,1060px);
	}
	@include mq (medium){
		.p-products-single-contents__table{
			width: 100%;
		}
	}
	@include mq (large){
		padding: 100px 0;
		.p-products-single-contents__body{
			grid-template-columns: repeat(2,1fr);
			grid-template-rows: auto auto;
			gap: 60px 40px;
		}
		.p-products-single-contents__imgWrap{
			grid-area: 1/1/2/2;
		}
		.p-products-single-contents__subImg{
			&.-current{
				&::before{
					border-radius: 4px;
				}
			}
		}
		.p-products-single-contents__textWrap{
			grid-area: 1/2/2/3;
		}
		.p-products-single-contents__title{
			font-size: font-size(32);
		}
		.p-products-single-contents__tableWrap{
			grid-area: 2/1/3/3;
		}
		.p-products-single-contents__detail-text{
			ul{
				li{
					padding-left: 18px;
					&::before{
						top: 13px;
						width: 8px;
					}
				}
			}
		}
	}
	@include mq (full){
		.p-products-single-contents__body{
			gap: 60px 80px;
		}
		.p-products-single-contents__subImg-wrap{
			gap: 8px;
			margin-top: 8px;
		}
		.p-products-single-contents__subImg{
			img{
				border-radius: 4px;
			}
		}
		.p-products-single-contents__title{
			font-size: font-size(40);
		}
		.p-products-single-contents__detail{
			margin-top: 28px;
		}
		.p-products-single-contents__detail-title{
			padding-bottom: 10px;
			font-size: font-size(20);
		}
	}
}

/*  p-products-single-other
---------------------------------------- */
.p-products-single-other{
	padding: 100px 0;
	.p-products-single-other__inner{
		@include inner;
	}
	@include mq (large){
		padding: 200px 0;
		.p-products-single-other__title{
			margin-bottom: 70px;
		}
	}
	@include mq (full){
		.p-products-single-other__title{
			margin-bottom: 56px;
		}
	}
}