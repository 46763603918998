@charset "UTF-8";
/*  color
================================================ */
/*  width
================================================ */
/*  transition
================================================ */
/*  font
================================================ */
/*  vw計算
---------------------------------------- */
/*  header
---------------------------------------- */
:root {
  --headerH: 56px;
}
@media all and ( 1008px <= width ) {
  :root {
    --headerH: 100px;
  }
}

/*  reset
================================================ */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  box-sizing: border-box;
}
*::before, *::after {
  box-sizing: border-box;
}

html {
  word-break: break-all;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

img, picture, video, canvas, svg {
  width: 100%;
  vertical-align: bottom;
}

input, button, textarea, select {
  font: inherit;
}

span {
  font-weight: inherit;
}

a {
  color: inherit;
}

a[class] {
  text-decoration: none;
}

mark {
  background-color: #ff9;
  font-weight: bold;
}

blockquote, q {
  quotes: none;
}

ul[class],
ol[class] {
  list-style: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

#root, #__next {
  isolation: isolate;
}

input[type=text], input[type=email], input[type=tel], textarea, button, select, option {
  display: block;
  width: 100%;
  font: inherit;
  outline: none;
  border-radius: 0;
}

textarea {
  resize: vertical;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

summary {
  list-style: none;
}

summary::-webkit-details-marker {
  display: none;
}

/*  base
================================================ */
html {
  overflow: auto;
  scroll-behavior: smooth;
}
html:not([lang=ja]) {
  word-break: break-word;
}

body {
  overflow: clip;
  min-width: 320px;
  color: #1A1A1A;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  line-height: 1.8;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 0.9375rem;
}
@media all and ( 768px <= width ) {
  body {
    font-size: 1rem;
  }
}
@media all and ( 768px <= width ) {
  body {
    min-width: auto;
  }
}

/* .l-header
---------------------------------------- */
.l-header {
  position: relative;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
}
.l-header .l-header__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: var(--headerH);
  padding: 10px var(--headerH) 10px 20px;
}
.l-header .l-header__logo {
  position: relative;
  width: 145px;
  z-index: 1;
}
.l-header .l-header__logo-link {
  display: block;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.l-header .l-header__logo-link:hover, .l-header .l-header__logo-link:focus-visible {
  opacity: 0.6;
}
.l-header .l-header__logo-link img {
  display: block;
}
.l-header .l-header__btn {
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  right: 0;
  width: var(--headerH);
  aspect-ratio: 1/1;
  background: #4D1798;
  z-index: 2;
}
.l-header .l-header__btn-line {
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 24px;
  height: 22px;
}
.l-header .l-header__btn-line::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 100%;
  height: 2px;
  background: #fff;
}
.l-header .l-header__btn-line-in {
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 100%;
  height: 100%;
}
.l-header .l-header__btn-line-in::before, .l-header .l-header__btn-line-in::after {
  content: "";
  position: absolute;
  left: 50%;
  translate: -50% 0;
  height: 2px;
  width: 100%;
  background: #fff;
}
.l-header .l-header__btn-line-in::before {
  top: 0;
}
.l-header .l-header__btn-line-in::after {
  top: 20px;
}
.l-header .l-header__btn[aria-expanded=true] .l-header__btn-line::before {
  opacity: 0;
}
.l-header .l-header__btn[aria-expanded=true] .l-header__btn-line-in::before {
  rotate: 45deg;
  top: 9px;
}
.l-header .l-header__btn[aria-expanded=true] .l-header__btn-line-in::after {
  rotate: -45deg;
  top: 9px;
}
@media all and ( 1008px <= width ) {
  .l-header {
    position: absolute;
    z-index: 999;
  }
  .l-header .l-header__inner {
    height: var(--headerH);
    padding: 10px var(--headerH) 10px 40px;
  }
  .l-header.is-active .l-header__inner {
    background: #fff;
  }
  .l-header .l-header__logo {
    width: 241px;
  }
  .l-header .l-header__logo path, .l-header .l-header__logo rect {
    fill: #1A1A1A;
  }
  .l-header .l-header__btn-line {
    width: 40px;
    height: 30px;
  }
  .l-header .l-header__btn-line-in::after {
    top: 28px;
  }
  .l-header .l-header__btn[aria-expanded=true] .l-header__btn-line::before {
    opacity: 0;
  }
  .l-header .l-header__btn[aria-expanded=true] .l-header__btn-line-in::before {
    top: 11px;
  }
  .l-header .l-header__btn[aria-expanded=true] .l-header__btn-line-in::after {
    top: 11px;
  }
}
.l-header .l-gnav__main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
  padding-right: 40px;
}
.l-header .l-gnav__main-item-link {
  display: block;
  padding: 2px;
  font-size: 1rem;
  color: #1A1A1A;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.l-header .l-gnav__main-item-link:hover, .l-header .l-gnav__main-item-link:focus-visible {
  opacity: 0.5;
}

@media all and ( 1008px <= width ) {
  #home .l-header .l-header__logo path, #home .l-header .l-header__logo rect {
    fill: #fff;
  }
}
#home .l-header .l-gnav__main-item-link {
  color: #fff;
}

/* .l-gnav
---------------------------------------- */
.l-gnav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  padding-top: var(--headerH);
  background: #fff;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  transition: all 0.3s ease-out;
  z-index: 2;
}
.l-gnav::-webkit-scrollbar {
  display: none;
}
.l-gnav[aria-hidden=false] {
  opacity: 1;
  pointer-events: auto;
  user-select: auto;
}
.l-gnav .l-gnav__list {
  padding: 28px 40px;
}
.l-gnav .l-gnav__item:nth-child(n+2) {
  border-top: 1px solid #DBDBDB;
}
.l-gnav .l-gnav__item-link {
  display: block;
  padding: 24px 0;
  font-size: 1.125rem;
  font-weight: 700;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.l-gnav .l-gnav__item-link:hover, .l-gnav .l-gnav__item-link:focus-visible {
  opacity: 0.5;
}
.l-gnav .l-gnav__item-in {
  position: relative;
  display: block;
  padding: 24px 50px 24px 0;
  font-size: 1.125rem;
  font-weight: 700;
  cursor: pointer;
}
.l-gnav .l-gnav__sub {
  margin-bottom: 24px;
}
.l-gnav .l-gnav__sub-item:nth-child(n+2),
.l-gnav .l-gnav__sub-products-category:nth-child(n+2),
.l-gnav .l-gnav__sub-products-sub:nth-child(n+2) {
  margin-top: 20px;
}
.l-gnav .l-gnav__sub-item-link,
.l-gnav .l-gnav__sub-products-category-link,
.l-gnav .l-gnav__sub-products-sub-link {
  display: block;
  font-size: 0.9375rem;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.l-gnav .l-gnav__sub-item-link:hover, .l-gnav .l-gnav__sub-item-link:focus-visible,
.l-gnav .l-gnav__sub-products-category-link:hover,
.l-gnav .l-gnav__sub-products-category-link:focus-visible,
.l-gnav .l-gnav__sub-products-sub-link:hover,
.l-gnav .l-gnav__sub-products-sub-link:focus-visible {
  opacity: 0.5;
}
.l-gnav .l-gnav__contact {
  position: relative;
  padding: 40px 20px;
  background: #F5F5F5;
  z-index: 1;
}
.l-gnav .l-gnav__contact::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 190px;
  height: auto;
  aspect-ratio: 190/86;
  background: #fff;
  z-index: -1;
  mask: url(../img/common/bg-nav-logo.svg) no-repeat center/contain;
}
.l-gnav .l-gnav__contact-btn-item:nth-child(n+2) {
  margin-top: 12px;
}
.l-gnav .l-gnav__contact-btn-item-link {
  display: block;
  padding: 20px;
  border: 1px solid #1A1A1A;
  text-align: center;
  font-size: 1rem;
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.l-gnav .l-gnav__contact-btn-item-link:hover, .l-gnav .l-gnav__contact-btn-item-link:focus-visible {
  opacity: 0.5;
}
.l-gnav .l-gnav__contact-note {
  margin-top: 10px;
}
.l-gnav .l-gnav__contact-note-text {
  font-size: 0.75rem;
  padding-left: 1em;
  text-indent: -1em;
  color: #777;
}
.l-gnav .l-gnav__contact-note-text:nth-child(n+2) {
  margin-top: 2px;
}
@media all and ( 1008px <= width ) {
  .l-gnav {
    padding-top: 0;
    height: 100vh;
  }
  .l-gnav .l-gnav__inner {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "contact list";
    height: 100%;
  }
  .l-gnav .l-gnav__list {
    position: relative;
    grid-area: list;
    display: flex;
    flex-wrap: wrap;
    gap: 80px 60px;
    align-content: center;
    padding: 100px clamp(4.6875vw, 6.25vw, 120px);
  }
  .l-gnav .l-gnav__list::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: clamp(4.6875vw, 6.25vw, 120px);
    width: clamp(23.90625vw, 31.875vw, 612px);
    aspect-ratio: 612/271;
    background: #F5F5F5;
    z-index: -1;
    mask: url(../img/common/bg-nav-logo.svg) no-repeat center/contain;
  }
  .l-gnav .l-gnav__item {
    align-self: start;
  }
  .l-gnav .l-gnav__item:nth-child(n+2) {
    border-top: 0;
  }
  .l-gnav .l-gnav__item:has(.l-gnav__item-in) {
    width: 100%;
  }
  .l-gnav .l-gnav__item-link {
    padding: 0;
    font-size: 1.375rem;
  }
  .l-gnav .l-gnav__item-in {
    padding: 0;
    font-size: 1.25rem;
    cursor: auto;
  }
  .l-gnav .l-gnav__sub {
    margin: 25px 0 0;
  }
  .l-gnav .l-gnav__sub-item:nth-child(n+2),
.l-gnav .l-gnav__sub-products-category:nth-child(n+2),
.l-gnav .l-gnav__sub-products-sub:nth-child(n+2) {
    margin-top: 0;
  }
  .l-gnav .l-gnav__sub-item-link,
.l-gnav .l-gnav__sub-products-category-link,
.l-gnav .l-gnav__sub-products-sub-link {
    display: block;
    font-size: 1.25rem;
  }
  .l-gnav .l-gnav__sub-list {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }
  .l-gnav .l-gnav__sub-item-link {
    font-size: 1.125rem;
  }
  .l-gnav .l-gnav__sub-products {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas: "prod1 prod2 prod2 prod3" "prod4 prod4 prod5 prod5";
    gap: 16px;
  }
  .l-gnav .l-gnav__sub-products .-prod01 {
    grid-area: prod1;
  }
  .l-gnav .l-gnav__sub-products .-prod02 {
    grid-area: prod2;
  }
  .l-gnav .l-gnav__sub-products .-prod03 {
    grid-area: prod3;
  }
  .l-gnav .l-gnav__sub-products .-prod04 {
    grid-area: prod4;
  }
  .l-gnav .l-gnav__sub-products .-prod05 {
    grid-area: prod5;
  }
  .l-gnav .l-gnav__sub-products-category {
    overflow: hidden;
    border-radius: 4px;
  }
  .l-gnav .l-gnav__sub-products-category.-prod02 .l-gnav__sub-products-category-link .l-gnav__sub-products-category-arw {
    right: 6px;
  }
  .l-gnav .l-gnav__sub-products-category-link {
    position: relative;
    display: block;
    aspect-ratio: 278/417;
    height: 100%;
  }
  .l-gnav .l-gnav__sub-products-category-link::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 208px;
    background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.3;
    z-index: 1;
  }
  .l-gnav .l-gnav__sub-products-category-link:hover, .l-gnav .l-gnav__sub-products-category-link:focus-visible {
    opacity: 1;
  }
  .l-gnav .l-gnav__sub-products-category-link:hover .l-gnav__sub-products-category-img img, .l-gnav .l-gnav__sub-products-category-link:focus-visible .l-gnav__sub-products-category-img img {
    scale: 1.1;
  }
  .l-gnav .l-gnav__sub-products-category-link:hover .l-gnav__sub-products-category-arw::before, .l-gnav .l-gnav__sub-products-category-link:focus-visible .l-gnav__sub-products-category-arw::before {
    background: #4D1798;
  }
  .l-gnav .l-gnav__sub-products-category-link:hover .l-gnav__sub-products-category-arw::after, .l-gnav .l-gnav__sub-products-category-link:focus-visible .l-gnav__sub-products-category-arw::after {
    opacity: 1;
  }
  .l-gnav .l-gnav__sub-products-category-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .l-gnav .l-gnav__sub-products-category-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
    transition-property: all;
  }
  .l-gnav .l-gnav__sub-products-category-arw {
    position: absolute;
    bottom: 6px;
    right: 23px;
    width: 24px;
    height: 24px;
  }
  .l-gnav .l-gnav__sub-products-category-arw::before, .l-gnav .l-gnav__sub-products-category-arw::after {
    content: "";
    position: absolute;
    transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
    transition-property: all;
  }
  .l-gnav .l-gnav__sub-products-category-arw::before {
    top: 50%;
    right: 50%;
    translate: 50% -50%;
    width: 4px;
    height: 8px;
    mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
    background: #fff;
    z-index: 2;
  }
  .l-gnav .l-gnav__sub-products-category-arw::after {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 4px;
    opacity: 0.2;
    z-index: 1;
  }
  .l-gnav .l-gnav__sub-products-category-text {
    position: absolute;
    top: clamp(1.25vw, 1.6666666667vw, 32px);
    left: clamp(1.25vw, 1.6666666667vw, 32px);
    color: #fff;
    font-weight: 700;
    line-height: 1.6;
    font-size: 1.25rem;
    z-index: 2;
  }
  .l-gnav .l-gnav__sub-products-sub-link {
    position: relative;
    height: 100%;
    padding: 32px;
    border: 1px solid #DBDBDB;
    background: #fff;
    border-radius: 4px;
    transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
    transition-property: all;
  }
  .l-gnav .l-gnav__sub-products-sub-link:hover, .l-gnav .l-gnav__sub-products-sub-link:focus-visible {
    opacity: 1;
    color: #4D1798;
  }
  .l-gnav .l-gnav__sub-products-sub-link:hover .l-gnav__sub-products-sub-arw, .l-gnav .l-gnav__sub-products-sub-link:focus-visible .l-gnav__sub-products-sub-arw {
    background: #4D1798;
  }
  .l-gnav .l-gnav__sub-products-sub-link:hover .l-gnav__sub-products-sub-arw::before, .l-gnav .l-gnav__sub-products-sub-link:focus-visible .l-gnav__sub-products-sub-arw::before {
    background: #fff;
  }
  .l-gnav .l-gnav__sub-products-sub-text {
    font-size: 1.25rem;
    font-weight: 700;
  }
  .l-gnav .l-gnav__sub-products-sub-img {
    width: 50px;
    margin-top: 16px;
  }
  .l-gnav .l-gnav__sub-products-sub-arw {
    position: absolute;
    bottom: 6px;
    right: 6px;
    display: block;
    background: #F5F5F5;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
    transition-property: all;
  }
  .l-gnav .l-gnav__sub-products-sub-arw::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 4px;
    height: 8px;
    mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
    background: #1A1A1A;
  }
  .l-gnav .l-gnav__contact {
    grid-area: contact;
    display: grid;
    place-content: center;
    width: clamp(20.3125vw, 27.0833333333vw, 520px);
    padding: 40px clamp(3.125vw, 4.1666666667vw, 80px);
  }
  .l-gnav .l-gnav__contact::before {
    content: none;
  }
  .l-gnav .l-gnav__contact-logo {
    width: clamp(11.2890625vw, 15.0520833333vw, 289px);
    margin: 0 auto 80px;
  }
  .l-gnav .l-gnav__contact-note-text {
    font-size: 0.8125rem;
  }
  .l-gnav .l-gnav__contact-note-text:nth-child(n+2) {
    margin-top: 1px;
  }
  .l-gnav .l-gnav__sub-products-sub-img.-plan svg path {
    fill: #505050;
  }
}
@media all and ( 1640px < width ) {
  .l-gnav .l-gnav__sub-products {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: "prod1 prod2 prod3 prod4" "prod1 prod2 prod3 prod5";
  }
  .l-gnav .l-gnav__sub-products-category-arw {
    right: 6px;
  }
}

.js-header.is-hidden {
  translate: 0 -100%;
  transition: all 0.3s linear;
}
.js-header.is-active {
  position: fixed;
  translate: 0 0;
}
.js-header.is-active .l-header__logo path, .js-header.is-active .l-header__logo rect {
  fill: #1A1A1A !important;
}
.js-header.is-active .l-gnav__main-item-link {
  color: #1A1A1A !important;
}

/*  l-footer
---------------------------------------- */
.l-footer {
  border-top: 1px solid #DBDBDB;
  padding-top: 100px;
}
.l-footer .l-footer__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .l-footer .l-footer__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.l-footer .l-footer__logo-img {
  width: 267px;
}
.l-footer .l-footer__logo-img-link {
  display: block;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.l-footer .l-footer__logo-img-link:hover, .l-footer .l-footer__logo-img-link:focus-visible {
  opacity: 0.5;
}
.l-footer .l-footer__logo-add {
  margin-top: 14px;
  font-size: 0.75rem;
}
.l-footer .l-footer__bottom {
  margin-top: 80px;
}
.l-footer .l-footer__qualification-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.l-footer .l-footer__qualification-item {
  width: 69px;
}
.l-footer .l-footer__cr {
  margin-top: 20px;
  padding: 16px 0 10px;
  border-top: 1px solid #EDEDED;
  color: #777;
  font-size: 0.625rem;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
}
@media all and ( 1008px <= width ) {
  .l-footer {
    padding-top: 200px;
    padding-bottom: 40px;
  }
  .l-footer .l-footer__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 80px;
  }
  .l-footer .l-footer__logo-img {
    width: 360px;
  }
  .l-footer .l-footer__logo-add {
    margin-top: 24px;
    font-size: 0.875rem;
  }
  .l-footer .l-footer__contact {
    width: 340px;
  }
  .l-footer .l-footer__contact-btn {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;
  }
  .l-footer .l-footer__contact-btn-item {
    width: 100%;
  }
  .l-footer .l-footer__contact-btn-item-link {
    display: grid;
    place-content: center;
    height: 100%;
    padding: 24px;
    border: 1px solid #1A1A1A;
    border-radius: 4px;
    text-align: center;
    font-size: 1.125rem;
    transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
    transition-property: all;
  }
  .l-footer .l-footer__contact-btn-item-link.-num {
    font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
    letter-spacing: 0.08em;
    line-height: 1.1;
    text-transform: uppercase;
  }
  .l-footer .l-footer__contact-btn-item-link:hover, .l-footer .l-footer__contact-btn-item-link:focus-visible {
    background: #4D1798;
    color: #fff;
  }
  .l-footer .l-footer__note {
    margin-top: 12px;
  }
  .l-footer .l-footer__note-text {
    font-size: 0.8125rem;
    color: #777;
  }
  .l-footer .l-footer__bottom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    gap: 80px;
    margin-top: 100px;
    padding-top: 100px;
    border-top: 1px solid #EDEDED;
  }
  .l-footer .l-footer__qualification-list {
    gap: 32px;
  }
  .l-footer .l-footer__qualification-item {
    width: 103px;
  }
  .l-footer .l-footer__nav {
    display: flex;
    flex-wrap: wrap;
    gap: 100px;
  }
  .l-footer .l-footer__nav-item:nth-child(n+2) {
    margin-top: 24px;
  }
  .l-footer .l-footer__nav-item:has(.l-footer__nav-item-in) {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
  .l-footer .l-footer__nav-item-in,
.l-footer .l-footer__nav-item-link {
    display: block;
    font-size: 1.125rem;
    font-weight: 700;
    font-weight: 700;
    line-height: 1.6;
  }
  .l-footer .l-footer__nav-item-link {
    display: block;
    transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
    transition-property: all;
  }
  .l-footer .l-footer__nav-item-link:hover, .l-footer .l-footer__nav-item-link:focus-visible {
    opacity: 0.5;
  }
  .l-footer .l-footer__nav-item-sub-item:nth-child(n+2) {
    margin-top: 20px;
  }
  .l-footer .l-footer__nav-item-sub-link {
    display: block;
    transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
    transition-property: all;
  }
  .l-footer .l-footer__nav-item-sub-link:hover, .l-footer .l-footer__nav-item-sub-link:focus-visible {
    opacity: 0.5;
  }
  .l-footer .l-footer__cr {
    margin-top: 160px;
    padding: 0;
    border-top: 0;
    text-align: right;
    font-size: 0.75rem;
  }
}
@media all and ( 1640px < width ) {
  .l-footer .l-footer__logo-img {
    width: 388px;
  }
  .l-footer .l-footer__contact {
    width: 832px;
  }
  .l-footer .l-footer__contact-btn {
    flex-direction: row;
  }
  .l-footer .l-footer__contact-btn-item {
    width: calc(50% - 8px);
  }
  .l-footer .l-footer__bottom {
    flex-direction: row;
  }
}

.c-head01 {
  margin-bottom: 40px;
  padding-left: 28px;
}
.c-head01 .c-head01__en {
  position: relative;
  font-size: 3.125rem;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
}
.c-head01 .c-head01__en::before {
  content: "";
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  left: -28px;
  width: 14px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid #1A1A1A;
}
.c-head01 .c-head01__ja {
  margin-top: 5px;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1rem;
  font-weight: 700;
}
@media all and ( 1008px <= width ) {
  .c-head01 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 32px;
    margin-bottom: 80px;
    padding-left: 64px;
  }
  .c-head01 .c-head01__en {
    font-size: 5.5rem;
  }
  .c-head01 .c-head01__en::before {
    left: -64px;
    width: 24px;
    border-width: 8px;
  }
  .c-head01 .c-head01__ja {
    margin-top: 0;
    font-size: 2rem;
  }
}

/*  c-head02
---------------------------------------- */
.c-head02 {
  position: relative;
  margin-bottom: 24px;
  padding-left: 16px;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1.25rem;
}
.c-head02::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  translate: 0 -50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #1A1A1A;
}
@media all and ( 1008px <= width ) {
  .c-head02 {
    margin-bottom: 32px;
    padding-left: 32px;
    font-size: 2rem;
  }
  .c-head02::before {
    width: 14px;
    height: 14px;
    border-width: 4px;
  }
}

.c-head02.-large {
  margin-bottom: 30px;
  padding-left: 24px;
  font-size: 1.625rem;
}
.c-head02.-large::before {
  width: 10px;
  height: 10px;
  border: 3px solid #1A1A1A;
}
@media all and ( 1008px <= width ) {
  .c-head02.-large {
    margin-bottom: 56px;
    padding-left: 44px;
    font-size: 2.5rem;
  }
  .c-head02.-large::before {
    width: 16px;
    height: 16px;
    border: 5px solid #1A1A1A;
  }
}

/*  c-head03
---------------------------------------- */
.c-head03 {
  position: relative;
  margin-bottom: 20px;
  padding-left: 18px;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1.25rem;
}
.c-head03::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #4D1798;
  border-radius: 1px;
}
@media all and ( 1008px <= width ) {
  .c-head03 {
    margin-bottom: 26px;
    padding-left: 25px;
    font-size: 1.5rem;
  }
  .c-head03::before {
    top: 50%;
    left: 0;
    translate: 0 -50%;
    width: 5px;
    height: calc(100% - 8px);
  }
}

/*
---------------------------------------- */
.c-btn01 {
  width: min(100%, 220px);
  margin: 32px auto 0;
}
.c-btn01 .c-btn01__link {
  display: block;
  padding: 15px;
  border: 1px solid #1A1A1A;
  text-align: center;
  border-radius: 4px;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-btn01 .c-btn01__link:hover, .c-btn01 .c-btn01__link:focus-visible {
  border-color: #4D1798;
  background: #4D1798;
  color: #fff;
}
@media all and ( 1008px <= width ) {
  .c-btn01 {
    width: 360px;
    margin-top: 53px;
  }
  .c-btn01 .c-btn01__link {
    padding: 22px;
    font-size: 1.125rem;
  }
}

.c-btn01.-pcRight {
  margin-right: 0;
}

/*  c-btn02
---------------------------------------- */
.c-btn02 .c-btn02__link {
  position: relative;
  display: block;
  padding: 20px 20px 40px;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  background: #fff;
  font-weight: 700;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-btn02 .c-btn02__link:hover, .c-btn02 .c-btn02__link:focus-visible {
  color: #4D1798;
}
.c-btn02 .c-btn02__link:hover .c-btn02__arw, .c-btn02 .c-btn02__link:focus-visible .c-btn02__arw {
  background: #4D1798;
}
.c-btn02 .c-btn02__link:hover .c-btn02__arw::before, .c-btn02 .c-btn02__link:focus-visible .c-btn02__arw::before {
  background: #fff;
}
.c-btn02 .c-btn02__arw {
  position: absolute;
  bottom: 6px;
  right: 6px;
  display: block;
  background: #F5F5F5;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-btn02 .c-btn02__arw::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 4px;
  height: 8px;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  background: #1A1A1A;
}
@media all and ( 1008px <= width ) {
  .c-btn02 {
    width: min(100%, 522px);
  }
  .c-btn02 .c-btn02__link {
    padding: 28px 32px 70px;
    font-size: 1.25rem;
  }
  .c-btn02 .c-btn02__arw {
    bottom: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
  }
  .c-btn02 .c-btn02__arw::before {
    width: 5px;
    height: 10px;
  }
}

.c-btn02__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}
.c-btn02__wrap .c-btn02 {
  width: calc(50% - 4px);
}
@media all and ( 1008px <= width ) {
  .c-btn02__wrap {
    gap: 16px;
  }
  .c-btn02__wrap .c-btn02 {
    width: calc(50% - 8px);
  }
}

/*  c-btn03
---------------------------------------- */
.c-btn03 {
  width: min(100%, 220px);
  margin: 32px auto 0;
}
.c-btn03 .c-btn03__link {
  display: block;
  padding: 15px;
  border: 1px solid #1A1A1A;
  text-align: center;
  border-radius: 4px;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-btn03 .c-btn03__link:hover, .c-btn03 .c-btn03__link:focus-visible {
  border-color: #4D1798;
  background: #4D1798;
  color: #fff;
}
.c-btn03 .c-btn03__link:hover .c-btn03__text::before, .c-btn03 .c-btn03__link:focus-visible .c-btn03__text::before {
  background: #fff;
}
.c-btn03 .c-btn03__text {
  position: relative;
  display: inline-block;
  padding-right: 21px;
}
.c-btn03 .c-btn03__text::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  translate: 0 -50%;
  width: 15px;
  height: 15px;
  mask: url(../img/common/icon-blank.svg) no-repeat center/contain;
  background: #1A1A1A;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
@media all and ( 1008px <= width ) {
  .c-btn03 {
    width: 360px;
    margin-top: 53px;
  }
  .c-btn03 .c-btn03__link {
    padding: 22px;
  }
  .c-btn03 .c-btn03__text {
    font-size: 1.125rem;
  }
}

.c-btn03.-download {
  min-width: 220px;
  width: fit-content;
}
.c-btn03.-download .c-btn03__text::before {
  width: 17px;
  height: 17px;
  mask: url(../img/common/icon-download.svg) no-repeat center/contain;
}
@media all and ( 1008px <= width ) {
  .c-btn03.-download {
    width: 360px;
  }
  .c-btn03.-download .c-btn03__text {
    padding-right: 30px;
  }
  .c-btn03.-download .c-btn03__text::before {
    width: 22px;
    height: 22px;
  }
}

/*  c-btn-plan01
---------------------------------------- */
.c-btn-plan01 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}
.c-btn-plan01 .c-btn-plan01__btn:nth-child(1) {
  grid-area: 1/1/2/3;
}
.c-btn-plan01 .c-btn-plan01__link {
  position: relative;
  display: block;
  padding: 20px 20px 40px;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  background: #fff;
  font-weight: 700;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-btn-plan01 .c-btn-plan01__link:hover, .c-btn-plan01 .c-btn-plan01__link:focus-visible {
  color: #4D1798;
}
.c-btn-plan01 .c-btn-plan01__link:hover .c-btn-plan01__arw, .c-btn-plan01 .c-btn-plan01__link:focus-visible .c-btn-plan01__arw {
  background: #4D1798;
}
.c-btn-plan01 .c-btn-plan01__link:hover .c-btn-plan01__arw::before, .c-btn-plan01 .c-btn-plan01__link:focus-visible .c-btn-plan01__arw::before {
  background: #fff;
}
.c-btn-plan01 .c-btn-plan01__icon {
  display: block;
  margin-top: 14px;
  width: 40px;
}
.c-btn-plan01 .c-btn-plan01__arw {
  position: absolute;
  bottom: 6px;
  right: 6px;
  display: block;
  background: #F5F5F5;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-btn-plan01 .c-btn-plan01__arw::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 4px;
  height: 8px;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  background: #1A1A1A;
}
@media all and ( 1008px <= width ) {
  .c-btn-plan01 {
    grid-template-columns: repeat(3, 1fr);
    gap: 17px;
  }
  .c-btn-plan01 .c-btn-plan01__btn:nth-child(1) {
    grid-area: auto;
  }
  .c-btn-plan01 .c-btn-plan01__link {
    height: 100%;
    padding: 28px 32px 60px;
    font-size: 1.375rem;
  }
  .c-btn-plan01 .c-btn-plan01__icon {
    margin-top: 12px;
    width: 60px;
  }
  .c-btn-plan01 .c-btn-plan01__arw {
    bottom: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
  }
  .c-btn-plan01 .c-btn-plan01__arw::before {
    width: 5px;
    height: 10px;
  }
}

@media all and ( 1008px <= width ) {
  .c-btn-plan01.-spLarge {
    grid-template-columns: repeat(2, 1fr);
  }
}
.c-btn-plan01.-spLarge .c-btn-plan01__btn:nth-child(1) {
  grid-area: 1/1/2/3;
}
@media all and ( 1640px < width ) {
  .c-btn-plan01.-spLarge {
    grid-template-columns: repeat(3, 1fr);
  }
  .c-btn-plan01.-spLarge .c-btn-plan01__btn:nth-child(1) {
    grid-area: auto;
  }
}

/*  c-btn-pdf01
---------------------------------------- */
.c-btn-pdf01 .c-btn-pdf01__link {
  position: relative;
  display: block;
  padding-left: 24px;
  text-decoration: underline;
}
.c-btn-pdf01 .c-btn-pdf01__link::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 18px;
  height: 18px;
  background: url(../img/common/icon-pdf.svg) no-repeat center/contain;
}
.c-btn-pdf01 .c-btn-pdf01__link:hover, .c-btn-pdf01 .c-btn-pdf01__link:focus-visible {
  text-decoration: none;
}
@media all and ( 1008px <= width ) {
  .c-btn-pdf01 .c-btn-pdf01__link {
    padding-left: 32px;
  }
  .c-btn-pdf01 .c-btn-pdf01__link::before {
    top: 3px;
    width: 24px;
    height: 24px;
  }
}

.c-card01 .c-card01__link {
  display: block;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-card01 .c-card01__link:hover, .c-card01 .c-card01__link:focus-visible {
  opacity: 0.7;
}
.c-card01 .c-card01__img {
  position: relative;
}
.c-card01 .c-card01__img img {
  border-radius: 4px;
  border: 1px solid #EDEDED;
  background: #fff;
}
.c-card01 .c-card01__img-category {
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-block;
  padding: 5px;
  background: #1E3054;
  color: #fff;
  border-radius: 2px;
  font-size: 0.8125rem;
  line-height: 1.1;
}
.c-card01 .c-card01__text {
  margin-top: 14px;
}
.c-card01 .c-card01__text-name {
  font-weight: 700;
  line-height: 1.6;
  font-size: 0.9375rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.c-card01 .c-card01__text-info {
  margin-top: 4px;
  font-size: 0.8125rem;
}
.c-card01 .c-card01__text-detail {
  margin-top: 8px;
  color: #505050;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and ( 1008px <= width ) {
  .c-card01 {
    width: 512px;
  }
  .c-card01 .c-card01__img-category {
    padding: 4px 8px;
    font-size: 0.875rem;
  }
  .c-card01 .c-card01__text {
    margin-top: 20px;
  }
  .c-card01 .c-card01__text-name {
    font-size: 1.125rem;
  }
  .c-card01 .c-card01__text-info {
    margin-top: 8px;
    font-size: 0.875rem;
  }
  .c-card01 .c-card01__text-detail {
    margin-top: 12px;
  }
}

/*
---------------------------------------- */
.c-archive01 .c-archive01__item {
  border-bottom: 1px solid #DBDBDB;
}
.c-archive01 .c-archive01__item-link {
  display: block;
  padding: 32px 0;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-archive01 .c-archive01__item-link:hover, .c-archive01 .c-archive01__item-link:focus-visible {
  opacity: 0.5;
}
.c-archive01 .c-archive01__item-date {
  margin-bottom: 5px;
  color: #505050;
  font-size: 0.8125rem;
}
.c-archive01 .c-archive01__item-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and ( 1008px <= width ) {
  .c-archive01 .c-archive01__item-link {
    display: flex;
    align-items: center;
    padding: 48px 40px;
    gap: 44px;
  }
  .c-archive01 .c-archive01__item-date {
    margin-bottom: 0;
    flex-shrink: 0;
    font-size: 1rem;
  }
  .c-archive01 .c-archive01__item-title {
    -webkit-line-clamp: 1;
  }
}

/*  c-archive02
---------------------------------------- */
.c-archive02 {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.c-archive02 .c-archive02__item {
  width: 100%;
}
.c-archive02 .c-archive02__link {
  display: block;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-archive02 .c-archive02__link:hover .c-archive02__img::before, .c-archive02 .c-archive02__link:focus-visible .c-archive02__img::before {
  background: #4D1798;
}
.c-archive02 .c-archive02__link:hover .c-archive02__img::after, .c-archive02 .c-archive02__link:focus-visible .c-archive02__img::after {
  background: #fff;
}
.c-archive02 .c-archive02__img {
  position: relative;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  aspect-ratio: 512/288;
}
.c-archive02 .c-archive02__img::before, .c-archive02 .c-archive02__img::after {
  content: "";
  position: absolute;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-archive02 .c-archive02__img::before {
  bottom: 6px;
  right: 6px;
  width: 24px;
  height: 24px;
  background: #F5F5F5;
  border-radius: 4px;
}
.c-archive02 .c-archive02__img::after {
  bottom: 14px;
  right: 16px;
  width: 4px;
  height: 8px;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  background: #1A1A1A;
}
.c-archive02 .c-archive02__textWrap {
  margin-top: 14px;
}
.c-archive02 .c-archive02__title {
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.6;
}
.c-archive02 .c-archive02__text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #505050;
}
@media all and ( 1008px <= width ) {
  .c-archive02 {
    gap: 32px 16px;
  }
  .c-archive02 .c-archive02__item {
    width: calc(33.33% - 10.6666666667px);
  }
}
@media all and ( 1640px < width ) {
  .c-archive02 {
    gap: 60px 32px;
  }
  .c-archive02 .c-archive02__item {
    width: calc(33.33% - 21.3333333333px);
  }
  .c-archive02 .c-archive02__img::before {
    bottom: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
  }
  .c-archive02 .c-archive02__img::after {
    bottom: 26px;
    right: 29px;
    width: 5px;
    height: 10px;
  }
  .c-archive02 .c-archive02__textWrap {
    margin-top: 20px;
  }
  .c-archive02 .c-archive02__title {
    margin-bottom: 12px;
    font-size: 1.125rem;
  }
}

.c-slider {
  position: relative;
}
.c-slider .c-slide-pagination.swiper-pagination {
  position: relative;
  bottom: auto;
  left: auto;
  margin-top: 24px;
}
.c-slider .c-slide-pagination.swiper-pagination .swiper-pagination-bullet {
  width: 4px;
  height: auto;
  aspect-ratio: 1;
  margin: 0 3px;
  padding: 0;
  border-radius: 0;
}
.c-slider .c-slide-pagination.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #1A1A1A;
}
@media all and ( 1008px <= width ) {
  .c-slider {
    padding-top: 64px !important;
    margin-top: -64px;
  }
  .c-slider .c-slide-pagination.swiper-pagination {
    margin-top: 40px;
  }
  .c-slider .c-slide-pagination.swiper-pagination .swiper-pagination-bullet {
    width: 8px;
    margin: 0 5px;
  }
  .c-slider .c-slide-pager {
    position: absolute;
    top: 0;
    right: 0;
    width: 72px;
    height: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-slider .c-slide-pager__prev.swiper-button-prev,
.c-slider .c-slide-pager__next.swiper-button-next {
    position: relative;
    top: 0;
    right: auto;
    left: 0;
    margin: 0;
    width: 32px;
    height: auto;
    aspect-ratio: 1;
    background-color: transparent;
    border: 1px solid #1A1A1A;
    border-radius: 4px;
    transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
    transition-property: all;
  }
  .c-slider .c-slide-pager__prev.swiper-button-prev::before,
.c-slider .c-slide-pager__next.swiper-button-next::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    width: 5px;
    height: auto;
    aspect-ratio: 1/2;
    background: #1A1A1A;
    mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  }
  .c-slider .c-slide-pager__prev.swiper-button-prev::after,
.c-slider .c-slide-pager__next.swiper-button-next::after {
    content: none;
  }
  .c-slider .c-slide-pager__prev.swiper-button-prev:hover, .c-slider .c-slide-pager__prev.swiper-button-prev:focus-visible,
.c-slider .c-slide-pager__next.swiper-button-next:hover,
.c-slider .c-slide-pager__next.swiper-button-next:focus-visible {
    background: #4D1798;
  }
  .c-slider .c-slide-pager__prev.swiper-button-prev:hover::before, .c-slider .c-slide-pager__prev.swiper-button-prev:focus-visible::before,
.c-slider .c-slide-pager__next.swiper-button-next:hover::before,
.c-slider .c-slide-pager__next.swiper-button-next:focus-visible::before {
    background: #fff;
  }
  .c-slider .c-slide-pager__prev.swiper-button-prev::before {
    scale: -1 1;
  }
}

/*  c-hero01
---------------------------------------- */
.c-hero01 {
  position: relative;
  padding-top: 90px;
}
.c-hero01 .c-hero01__inner {
  position: relative;
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .c-hero01 .c-hero01__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.c-hero01 .c-hero01__title {
  margin-bottom: 45px;
  font-weight: 700;
  line-height: 1.6;
  font-size: 2.25rem;
  letter-spacing: 0.06em;
}
.c-hero01 .c-hero01__logo {
  position: absolute;
  top: -56px;
  right: -79vw;
  width: 188vw;
  z-index: -1;
}
@media all and ( 768px <= width ) {
  .c-hero01 .c-hero01__logo {
    right: -40vw;
    width: 100vw;
  }
  .c-hero01 .c-hero01__img {
    padding: 0 120px;
  }
  .c-hero01 .c-hero01__img img {
    border-radius: 4px;
  }
}
@media all and ( 1008px <= width ) {
  .c-hero01 {
    padding-top: 290px;
  }
  .c-hero01 .c-hero01__title {
    margin-bottom: 100px;
    font-size: 3.5rem;
  }
  .c-hero01 .c-hero01__logo {
    top: 0;
    right: -15vw;
    width: 1060px;
  }
  .c-hero01 .c-hero01__img {
    padding: 0 80px;
  }
  .c-hero01 .c-hero01__contents {
    margin-top: 24px;
  }
}
@media all and ( 1640px < width ) {
  .c-hero01 .c-hero01__logo {
    right: 0;
  }
}

/*  c-head02
---------------------------------------- */
.c-hero02 {
  position: relative;
  padding-top: 90px;
}
.c-hero02 .c-hero02__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .c-hero02 .c-hero02__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.c-hero02 .c-hero02__title {
  margin-bottom: 45px;
  font-size: 2.25rem;
  letter-spacing: 0.06em;
}
.c-hero02 .c-hero02__logo {
  position: absolute;
  top: -56px;
  right: -79vw;
  width: 188vw;
  z-index: -1;
}
.c-hero02 .c-hero02__body {
  margin-bottom: 30px;
}
.c-hero02 .c-hero02__btnWrap {
  display: flex;
  flex-wrap: wrap;
}
.c-hero02 .c-hero02__btn {
  width: 50%;
}
.c-hero02 .c-hero02__btn:nth-child(2n+1) .c-hero02__btn-link {
  border-radius: 4px 0 0 4px;
}
.c-hero02 .c-hero02__btn:nth-child(2n) .c-hero02__btn-link {
  border-radius: 0 4px 4px 0;
}
.c-hero02 .c-hero02__btn.-current .c-hero02__btn-link {
  background: #4D1798;
  color: #fff;
  border-color: #4D1798;
}
.c-hero02 .c-hero02__btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 12px;
  background: #fff;
  font-weight: 700;
  border: 1px solid #DBDBDB;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-hero02 .c-hero02__btn-link:hover, .c-hero02 .c-hero02__btn-link:focus-visible {
  opacity: 0.5;
}
@media all and ( 768px <= width ) {
  .c-hero02 .c-hero02__logo {
    right: -40vw;
    width: 100vw;
  }
  .c-hero02 .c-hero02__img {
    padding: 0 120px;
  }
  .c-hero02 .c-hero02__img img {
    border-radius: 4px;
  }
}
@media all and ( 1008px <= width ) {
  .c-hero02 {
    padding-top: 290px;
  }
  .c-hero02 .c-hero02__title {
    margin-bottom: 100px;
    font-size: 3.5rem;
  }
  .c-hero02 .c-hero02__logo {
    top: 0;
    right: -15vw;
    width: 1060px;
  }
  .c-hero02 .c-hero02__img {
    padding: 0 80px;
  }
  .c-hero02 .c-hero02__body {
    display: flex;
    gap: 0 40px;
    margin-bottom: 0;
  }
  .c-hero02 .c-hero02__contents {
    margin-top: 24px;
  }
  .c-hero02 .c-hero02__btn {
    width: 214px;
  }
  .c-hero02 .c-hero02__btn:nth-child(2n+1) .c-hero02__btn-link {
    border-radius: 0;
  }
  .c-hero02 .c-hero02__btn:nth-child(2n) .c-hero02__btn-link {
    border-radius: 0;
  }
  .c-hero02 .c-hero02__btn:first-child .c-hero02__btn-link {
    border-radius: 4px 0 0 4px;
  }
  .c-hero02 .c-hero02__btn:last-child .c-hero02__btn-link {
    border-radius: 0 4px 4px 0;
  }
  .c-hero02 .c-hero02__btn-link {
    font-size: 1.125rem;
  }
}
@media all and ( 1640px < width ) {
  .c-hero02 .c-hero02__logo {
    right: 0;
  }
  .c-hero02 .c-hero02__body {
    gap: 0 76px;
  }
}

/*  c-layout01
---------------------------------------- */
.c-layout01 {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
}
.c-layout01 .c-layout01__anchor {
  width: 100%;
}
.c-layout01 .c-layout01__anchor-title {
  position: relative;
  margin-bottom: 12px;
  padding-left: 12px;
  color: #777;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0;
}
.c-layout01 .c-layout01__anchor-title::before, .c-layout01 .c-layout01__anchor-title::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  translate: 0 -50%;
  width: 7px;
  height: 1px;
  background: #777;
}
.c-layout01 .c-layout01__anchor-title::after {
  rotate: 90deg;
}
.c-layout01 .c-layout01__anchor-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 8px;
}
.c-layout01 .c-layout01__anchor-item {
  width: calc(50% - 4px);
}
.c-layout01 .c-layout01__anchor-link {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 20px 40px 20px 20px;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-layout01 .c-layout01__anchor-link::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  translate: 0 -50%;
  width: 10px;
  height: 10px;
  rotate: 90deg;
  background: url(../img/common/icon-arw01.svg) no-repeat center/contain;
}
.c-layout01 .c-layout01__anchor-link:hover, .c-layout01 .c-layout01__anchor-link:focus-visible {
  opacity: 0.5;
}
.c-layout01 .c-layout01__anchor-link.is-active {
  opacity: 0.5;
}
.c-layout01 .c-layout01__contents {
  width: 100%;
}
@media all and ( 1008px <= width ) {
  .c-layout01 {
    gap: 60px;
    align-items: start;
  }
  .c-layout01 .c-layout01__anchor {
    width: 200px;
    padding-top: var(--headerH);
    position: sticky;
    top: 50px;
    left: 0;
  }
  .c-layout01 .c-layout01__anchor-inner {
    padding-bottom: 200px;
  }
  .c-layout01 .c-layout01__anchor-title {
    margin-bottom: 24px;
    padding-left: 16px;
    font-size: 0.8125rem;
  }
  .c-layout01 .c-layout01__anchor-title::before, .c-layout01 .c-layout01__anchor-title::after {
    width: 9px;
  }
  .c-layout01 .c-layout01__anchor-list {
    display: block;
    padding: 22px;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
  }
  .c-layout01 .c-layout01__anchor-item {
    width: 100%;
  }
  .c-layout01 .c-layout01__anchor-link {
    display: block;
    padding: 8px 0;
    border: 0;
  }
  .c-layout01 .c-layout01__anchor-link::before {
    content: none;
  }
  .c-layout01 .c-layout01__contents {
    padding-top: var(--headerH);
    width: calc(100% - 260px);
  }
}
@media all and ( 1640px < width ) {
  .c-layout01 {
    gap: 136px;
  }
  .c-layout01 .c-layout01__anchor {
    width: 272px;
  }
  .c-layout01 .c-layout01__anchor-list {
    padding: 22px 32px;
  }
  .c-layout01 .c-layout01__contents {
    width: calc(100% - 408px);
  }
}

/*  c-layout02
---------------------------------------- */
.c-layout02 .c-layout02__item {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 40px 32px;
  background: #F5F5F5;
  border-radius: 4px;
}
.c-layout02 .c-layout02__item:nth-child(n+2) {
  margin-top: 8px;
}
.c-layout02 .c-layout02__item-num {
  color: #4D1798;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0;
}
@media all and ( 1008px <= width ) {
  .c-layout02 .c-layout02__item {
    gap: 50px;
    padding: 50px;
  }
  .c-layout02 .c-layout02__item:nth-child(n+2) {
    margin-top: 16px;
  }
  .c-layout02 .c-layout02__item-num {
    width: 65px;
    font-size: 0.875rem;
    line-height: 1.8;
  }
  .c-layout02 .c-layout02__item-text {
    width: calc(100% - 115px);
    font-size: 1.125rem;
  }
}
@media all and ( 1640px < width ) {
  .c-layout02 .c-layout02__item {
    gap: 75px;
    padding: 77px 80px;
  }
  .c-layout02 .c-layout02__item-text {
    width: calc(100% - 140px);
  }
}

/*  c-layout03
---------------------------------------- */
.c-layout03 {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.c-layout03 .c-layout03__img {
  width: 100%;
}
.c-layout03 .c-layout03__img-link {
  display: block;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-layout03 .c-layout03__img-link:hover, .c-layout03 .c-layout03__img-link:focus-visible {
  opacity: 0.5;
}
.c-layout03 .c-layout03__contents {
  width: 100%;
}
@media all and ( 1008px <= width ) {
  .c-layout03 {
    gap: 35px;
  }
  .c-layout03 .c-layout03__img {
    width: 45%;
  }
  .c-layout03 .c-layout03__contents {
    width: calc(100% - (45% + 35px));
  }
}
@media all and ( 1640px < width ) {
  .c-layout03 {
    gap: 80px;
  }
  .c-layout03 .c-layout03__img {
    width: 400px;
  }
  .c-layout03 .c-layout03__contents {
    width: calc(100% - 480px);
  }
}

.c-layout03.-gapLarge {
  gap: 40px;
}
@media all and ( 1008px <= width ) {
  .c-layout03.-gapLarge {
    gap: 60px;
  }
  .c-layout03.-gapLarge .c-layout03__contents {
    width: calc(100% - (45% + 60px));
  }
}
@media all and ( 1640px < width ) {
  .c-layout03.-gapLarge {
    gap: 120px;
  }
  .c-layout03.-gapLarge .c-layout03__contents {
    width: calc(100% - 520px);
  }
}

/*  c-table01
---------------------------------------- */
.c-table01 {
  border-top: 1px solid #1A1A1A;
}
.c-table01 th, .c-table01 td {
  display: block;
}
.c-table01 th {
  text-align: left;
  padding: 20px 10px 10px;
  color: #505050;
  font-weight: 400;
}
.c-table01 td {
  padding: 0 10px 20px;
  border-bottom: 1px solid #DBDBDB;
}
@media all and ( 1008px <= width ) {
  .c-table01 th, .c-table01 td {
    display: table-cell;
    border-bottom: 1px solid #DBDBDB;
  }
  .c-table01 th {
    vertical-align: top;
    padding: 30px 40px 30px 30px;
    border-right: 1px solid #DBDBDB;
  }
  .c-table01 td {
    padding: 30px 40px;
  }
}
@media all and ( 1640px < width ) {
  .c-table01 th {
    padding: 30px 60px 30px 40px;
  }
  .c-table01 td {
    padding: 30px 60px;
  }
}

/*  c-table02
---------------------------------------- */
.c-table02 {
  overflow: auto;
}
.c-table02 table {
  table-layout: auto;
  width: 100%;
}
.c-table02 th, .c-table02 td {
  text-align: center;
  padding: 14px 20px;
  border: 1px solid #DBDBDB;
}
.c-table02 th:first-child, .c-table02 td:first-child {
  border-width: 0 1px 0 0;
}
.c-table02 th:nth-child(n+2), .c-table02 td:nth-child(n+2) {
  border-width: 0 1px 0 1px;
}
.c-table02 th:last-child, .c-table02 td:last-child {
  border-width: 0 0 0 1px;
}
.c-table02 th {
  color: #fff;
  background: #1A1A1A;
}
.c-table02 tbody tr {
  border-bottom: 1px solid #DBDBDB;
}
.c-table02 tbody tr:nth-child(2n) {
  background: #F5F5F5;
}
@media all and ( 768px <= width ) {
  .c-table02 {
    margin-right: -120px;
  }
}
@media all and ( 1640px < width ) {
  .c-table02 {
    margin-right: 0;
  }
  .c-table02 th, .c-table02 td {
    padding: 16px 30px;
  }
}

/*  c-table03
---------------------------------------- */
.c-table03 {
  border-top: 1px solid #DBDBDB;
}
.c-table03 th, .c-table03 td {
  display: block;
  text-align: left;
  padding: 15px 20px;
  border-bottom: 1px solid #DBDBDB;
}
.c-table03 th {
  background: #F5F5F5;
  font-weight: 400;
}
.c-table03 td {
  color: #505050;
}
@media all and ( 1008px <= width ) {
  .c-table03 th, .c-table03 td {
    display: table-cell;
    vertical-align: top;
    padding: 17px 32px;
  }
}

/*  c-list01
---------------------------------------- */
.c-list01 .c-list01__item {
  position: relative;
  padding-left: 13px;
  color: #505050;
}
.c-list01 .c-list01__item:nth-child(n+2) {
  margin-top: 3px;
}
.c-list01 .c-list01__item::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  width: 6px;
  height: 2px;
  background: #4D1798;
}
@media all and ( 1008px <= width ) {
  .c-list01 .c-list01__item {
    padding-left: 18px;
  }
  .c-list01 .c-list01__item::before {
    top: 13px;
    width: 8px;
  }
}

.c-list01.-default .c-list01__item {
  color: #1A1A1A;
}

/*  c-list02
---------------------------------------- */
.c-list02 {
  counter-reset: number 0;
}
.c-list02 .c-list02__item {
  position: relative;
  padding-left: 24px;
  color: #505050;
}
.c-list02 .c-list02__item::before {
  position: absolute;
  top: 7px;
  left: 0;
  counter-increment: number 1;
  content: counter(number) ".";
  color: #4D1798;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
}
.c-list02 .c-list02__item:nth-child(n+2) {
  margin-top: 3px;
}
@media all and ( 1008px <= width ) {
  .c-list02 .c-list02__item {
    padding-left: 26px;
  }
}

/*  c-list03
---------------------------------------- */
.c-list03 .c-list03__item:first-child {
  border-top: 1px solid #DBDBDB;
}
.c-list03 .c-list03__item-link {
  position: relative;
  display: block;
  padding: 10px 35px 10px 0;
  border-bottom: 1px solid #DBDBDB;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-list03 .c-list03__item-link::before, .c-list03 .c-list03__item-link::after {
  content: "";
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-list03 .c-list03__item-link::before {
  right: 0;
  width: 24px;
  height: 24px;
  background: #F5F5F5;
  border-radius: 2px;
}
.c-list03 .c-list03__item-link::after {
  right: 10px;
  width: 4px;
  height: 8px;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  background: #1A1A1A;
}
.c-list03 .c-list03__item-link:hover, .c-list03 .c-list03__item-link:focus-visible {
  color: #4D1798;
}
.c-list03 .c-list03__item-link:hover::before, .c-list03 .c-list03__item-link:focus-visible::before {
  background: #4D1798;
}
.c-list03 .c-list03__item-link:hover::after, .c-list03 .c-list03__item-link:focus-visible::after {
  background: #fff;
}
@media all and ( 1640px < width ) {
  .c-list03 {
    display: flex;
    flex-wrap: wrap;
    gap: 0 40px;
  }
  .c-list03 .c-list03__item {
    width: calc(50% - 20px);
  }
  .c-list03 .c-list03__item:nth-child(2) {
    border-top: 1px solid #DBDBDB;
  }
  .c-list03 .c-list03__item-link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 18px 40px 18px 0;
  }
  .c-list03 .c-list03__item-link::before {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
  .c-list03 .c-list03__item-link::after {
    right: 13px;
    width: 5px;
    height: 10px;
  }
}

.c-list03.-anchor .c-list03__item-link::before {
  background: #DBDBDB;
}
.c-list03.-anchor .c-list03__item-link::after {
  rotate: 90deg;
}
.c-list03.-anchor .c-list03__item-link:hover::before, .c-list03.-anchor .c-list03__item-link:focus-visible::before {
  background: #4D1798;
}

/*  c-tag01
---------------------------------------- */
.c-tag01 {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.c-tag01 .c-tag01__item {
  padding: 2px 8px;
  color: #fff;
  background: #4D1798;
  border-radius: 2px;
  font-size: 0.8125rem;
}
@media all and ( 1008px <= width ) {
  .c-tag01 .c-tag01__item {
    font-size: 0.875rem;
  }
}

/*  c-img01
---------------------------------------- */
.c-img01 {
  display: grid;
  gap: 8px;
}
.c-img01 .c-img01__img img {
  border-radius: 4px;
}
@media all and ( 1008px <= width ) {
  .c-img01 {
    grid-template-columns: 63.5% auto;
  }
  .c-img01.-rev {
    grid-template-columns: auto 63.5%;
  }
  .c-img01 .c-img01__img img {
    object-fit: cover;
    height: 100%;
  }
}
@media all and ( 1640px < width ) {
  .c-img01 {
    grid-template-columns: 752px auto;
    gap: 17px;
  }
  .c-img01.-rev {
    grid-template-columns: auto 752px;
  }
}

/*  c-pager01
----------------------------------- */
.c-pager01 {
  margin: 32px auto 0;
  display: flex;
  width: 275px;
  justify-content: center;
  align-items: center;
}
.c-pager01 > * {
  width: 40px;
  height: 40px;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
}
.c-pager01 .previous {
  border-width: 1px 0 1px 1px;
  border-radius: 4px 0 0 4px;
}
.c-pager01 .current {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #4D1798;
  background: #4D1798;
  aspect-ratio: 1/1;
}
.c-pager01 .page,
.c-pager01 .extend,
.c-pager01 .larger {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
}
.c-pager01 .extend {
  border: solid #DBDBDB;
  border-width: 1px 1px 1px 0;
}
.c-pager01 .larger {
  border-width: 1px 1px 1px 0;
}
.c-pager01 a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: solid #DBDBDB;
  border-width: 1px 1px 1px 0;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-pager01 a:hover, .c-pager01 a:focus-visible {
  color: #fff;
  border-color: #4D1798;
  background: #4D1798;
}
.c-pager01 .previouspostslink,
.c-pager01 .nextpostslink {
  position: relative;
  border: none;
  border: solid #DBDBDB;
}
.c-pager01 .previouspostslink:hover::before, .c-pager01 .previouspostslink:focus-visible::before,
.c-pager01 .nextpostslink:hover::before,
.c-pager01 .nextpostslink:focus-visible::before {
  background: #fff;
}
.c-pager01 .previouspostslink {
  border-width: 1px 1px 1px 1px;
  border-radius: 4px 0 0 4px;
}
.c-pager01 .nextpostslink {
  border-width: 1px 1px 1px 0;
  border-radius: 0 4px 4px 0;
}
@media all and ( 1008px <= width ) {
  .c-pager01 {
    margin: 80px auto 0;
    width: 400px;
  }
  .c-pager01 > * {
    width: 50px;
    height: 50px;
  }
  .c-pager01 a {
    width: 50px;
    height: 50px;
    font-size: 1.125rem;
  }
  .c-pager01 .previouspostslink::before,
.c-pager01 .nextpostslink::before {
    right: 45%;
    top: 41%;
  }
}

/*  c-pager02
----------------------------------- */
.c-pager02 {
  position: relative;
  margin: 40px auto 0;
  padding-top: 60px;
}
.c-pager02 .c-pager02__prev,
.c-pager02 .c-pager02__next {
  position: absolute;
  display: block;
  width: min(50%, 164px);
  height: 24px;
  top: 0;
}
.c-pager02 .c-pager02__prev {
  left: 0;
}
.c-pager02 .c-pager02__next {
  right: 0;
}
.c-pager02 .c-pager02__back {
  margin: auto;
  width: 220px;
  text-align: center;
}
.c-pager02 .c-pager02__prev-link,
.c-pager02 .c-pager02__next-link {
  position: relative;
  display: block;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-pager02 .c-pager02__prev-link::before,
.c-pager02 .c-pager02__next-link::before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 3px;
  background: #F5F5F5;
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-pager02 .c-pager02__prev-link::after,
.c-pager02 .c-pager02__next-link::after {
  content: "";
  position: absolute;
  display: block;
  width: 4px;
  height: 8px;
  top: 11px;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  background: #1A1A1A;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-pager02 .c-pager02__prev-link:hover::before, .c-pager02 .c-pager02__prev-link:focus-visible::before,
.c-pager02 .c-pager02__next-link:hover::before,
.c-pager02 .c-pager02__next-link:focus-visible::before {
  background: #4D1798;
}
.c-pager02 .c-pager02__prev-link:hover::after, .c-pager02 .c-pager02__prev-link:focus-visible::after,
.c-pager02 .c-pager02__next-link:hover::after,
.c-pager02 .c-pager02__next-link:focus-visible::after {
  background: #fff;
}
.c-pager02 .c-pager02__prev-link {
  padding-left: 36px;
}
.c-pager02 .c-pager02__prev-link::before {
  left: 0;
}
.c-pager02 .c-pager02__prev-link::after {
  left: 10px;
  scale: -1;
}
.c-pager02 .c-pager02__next-link {
  padding-right: 36px;
  text-align: right;
}
.c-pager02 .c-pager02__next-link::before {
  right: 0;
}
.c-pager02 .c-pager02__next-link::after {
  right: 10px;
}
.c-pager02 .c-pager02__back-link {
  display: block;
  padding: 14px;
  width: 100%;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
  border: 1px solid #1A1A1A;
  border-radius: 4px;
}
.c-pager02 .c-pager02__back-link:hover, .c-pager02 .c-pager02__back-link:focus-visible {
  color: #fff;
  background: #4D1798;
}
@media all and ( 1008px <= width ) {
  .c-pager02 {
    margin-top: 110px;
    padding-top: 0;
  }
  .c-pager02 .c-pager02__prev,
.c-pager02 .c-pager02__next {
    top: 50%;
    translate: 0 -50%;
  }
  .c-pager02 .c-pager02__back {
    width: 360px;
  }
  .c-pager02 .c-pager02__back-link {
    padding: 18px;
    font-size: 1.125rem;
  }
  .c-pager02 .c-pager02__prev-link::before,
.c-pager02 .c-pager02__next-link::before {
    width: 32px;
    height: 32px;
    top: -1px;
    background: #F5F5F5;
    border-radius: 4px;
  }
  .c-pager02 .c-pager02__prev-link::after,
.c-pager02 .c-pager02__next-link::after {
    width: 5px;
    height: 10px;
    top: 11px;
    background: #1A1A1A;
  }
  .c-pager02 .c-pager02__prev-link {
    padding-left: 55px;
  }
  .c-pager02 .c-pager02__prev-link::after {
    left: 12px;
  }
  .c-pager02 .c-pager02__next-link {
    padding-right: 55px;
  }
  .c-pager02 .c-pager02__next-link::after {
    top: 9px;
    right: 13px;
  }
}

/*  c-single01
----------------------------------- */
.c-single01 .c-single01__header {
  margin-bottom: 32px;
  border-bottom: 1px solid #DBDBDB;
}
.c-single01 .c-single01__header-date {
  display: block;
  padding-bottom: 10px;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 0.8125rem;
  color: #505050;
}
.c-single01 .c-single01__header-title {
  padding-bottom: 24px;
  font-size: 1.625rem;
}
.c-single01 .c-single01__contents {
  overflow: hidden;
}
.c-single01 .c-single01__contents > * {
  margin-top: 32px;
  clear: both;
}
.c-single01 .c-single01__contents p {
  color: #505050;
}
.c-single01 .c-single01__contents strong {
  color: #4D1798;
}
.c-single01 .c-single01__contents a {
  display: inline-block;
  color: #4D1798;
}
.c-single01 .c-single01__contents a:hover, .c-single01 .c-single01__contents a:focus-visible {
  text-decoration: none;
}
.c-single01 .c-single01__contents ul {
  padding-left: 16px;
}
.c-single01 .c-single01__contents ul li {
  position: relative;
  list-style: none;
}
.c-single01 .c-single01__contents ul li::before {
  content: "";
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  top: 13px;
  left: -15px;
  background: #4D1798;
  border-radius: 50%;
}
.c-single01 .c-single01__contents ul li:nth-child(n+2) {
  margin-top: 10px;
}
.c-single01 .c-single01__contents img {
  display: block;
  max-width: 100%;
  width: auto;
}
@media all and ( 1008px <= width ) {
  .c-single01 .c-single01__header {
    margin-bottom: 60px;
  }
  .c-single01 .c-single01__header-date {
    padding-bottom: 15px;
    font-size: 1rem;
  }
  .c-single01 .c-single01__header-title {
    padding-bottom: 35px;
    font-size: 2rem;
  }
  .c-single01 .c-single01__contents > * {
    margin-top: 60px;
  }
  .c-single01 .c-single01__contents .aligncenter {
    display: block;
    margin: 40px auto 0;
    text-align: center;
  }
  .c-single01 .c-single01__contents .aligncenter img {
    display: block;
    margin: 0 auto;
  }
  .c-single01 .c-single01__contents .alighleft {
    float: left;
    padding: 0 40px 40px 0;
  }
  .c-single01 .c-single01__contents .alignright {
    float: right;
    padding: 0 0 40px 40px;
  }
}

/*  c-nav-products
---------------------------------------- */
.c-nav-products {
  padding: 60px 0;
  background: #F5F5F5;
}
.c-nav-products .c-nav-products__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .c-nav-products .c-nav-products__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.c-nav-products .c-nav-products__contents:nth-child(n+2) {
  margin-top: 40px;
}
.c-nav-products .c-nav-products__info-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.c-nav-products .c-nav-products__info-item {
  width: calc(50% - 4px);
  min-height: 164px;
  height: auto;
}
.c-nav-products .c-nav-products__info-link {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 12px 12px 30px;
  aspect-ratio: 1/1;
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-nav-products .c-nav-products__info-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 164px;
  opacity: 0.3;
  background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}
.c-nav-products .c-nav-products__info-link:hover .c-nav-products__info-img img, .c-nav-products .c-nav-products__info-link:focus-visible .c-nav-products__info-img img {
  scale: 1.1;
}
.c-nav-products .c-nav-products__info-link:hover .c-nav-products__info-arw::before, .c-nav-products .c-nav-products__info-link:focus-visible .c-nav-products__info-arw::before {
  background: #4D1798;
}
.c-nav-products .c-nav-products__info-link:hover .c-nav-products__info-arw::after, .c-nav-products .c-nav-products__info-link:focus-visible .c-nav-products__info-arw::after {
  opacity: 1;
}
.c-nav-products .c-nav-products__info-title {
  position: relative;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1rem;
  color: #fff;
  z-index: 2;
}
.c-nav-products .c-nav-products__info-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
  z-index: 0;
}
.c-nav-products .c-nav-products__info-img img {
  height: 100%;
  object-fit: cover;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-nav-products .c-nav-products__info-arw {
  position: absolute;
  bottom: 6px;
  right: 6px;
  width: 24px;
  height: 24px;
}
.c-nav-products .c-nav-products__info-arw::before, .c-nav-products .c-nav-products__info-arw::after {
  content: "";
  position: absolute;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-nav-products .c-nav-products__info-arw::before {
  top: 50%;
  right: 50%;
  translate: 50% -50%;
  width: 4px;
  height: 8px;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  background: #fff;
  z-index: 2;
}
.c-nav-products .c-nav-products__info-arw::after {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 4px;
  opacity: 0.2;
  z-index: 1;
}
.c-nav-products .c-nav-products__subContents {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #DBDBDB;
}
@media all and ( 1008px <= width ) {
  .c-nav-products {
    padding: 100px 0;
  }
  .c-nav-products .c-nav-products__contents:nth-child(n+2) {
    margin-top: 60px;
  }
  .c-nav-products .c-nav-products__info-list {
    gap: 16px;
  }
  .c-nav-products .c-nav-products__info-item {
    width: calc(25% - 12px);
    min-height: 218px;
    height: auto;
  }
  .c-nav-products .c-nav-products__info-link {
    padding: 20px 20px 60px;
    aspect-ratio: 388/218;
  }
  .c-nav-products .c-nav-products__info-link::before {
    height: 170px;
  }
  .c-nav-products .c-nav-products__info-title {
    font-size: 1.125rem;
  }
  .c-nav-products .c-nav-products__info-arw {
    bottom: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
  }
  .c-nav-products .c-nav-products__info-arw::before {
    width: 5px;
    height: 10px;
  }
  .c-nav-products .c-nav-products__subContents {
    margin-top: 60px;
    padding-top: 60px;
  }
}
@media all and ( 1640px < width ) {
  .c-nav-products .c-nav-products__info-title {
    font-size: 1.375rem;
  }
  .c-nav-products .c-nav-products__info-link {
    padding: 30px 30px 60px;
  }
}

/*  デフォルト
----------------------------------- */
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  border-radius: 0;
  border: 1px solid #DBDBDB;
  background: #fff;
}

input[type=text],
input[type=email],
textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 13px 16px;
  border-radius: 4px;
}

input::placeholder,
textarea::placeholder {
  color: #777;
  font-size: 1rem;
}

input:-ms-input-placeholder {
  color: #777;
  font-size: 1rem;
}

input::-ms-input-placeholder {
  color: #777;
  font-size: 1rem;
}

button {
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
}

label {
  cursor: pointer;
}

input[type=checkbox] {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}
input[type=checkbox]:focus + span::before {
  background: #777;
}
input[type=checkbox] + span {
  position: relative;
  display: inline-block;
  padding-left: 27px;
}
input[type=checkbox] + span::before {
  content: "";
  position: absolute;
  top: 52%;
  left: 0;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border: 1px solid #4D1798;
  border-radius: 2px;
}

input[type=checkbox]:checked + span::after {
  content: "";
  position: absolute;
  top: 40%;
  left: 4px;
  width: 10px;
  height: 5px;
  border-left: 2px solid #4D1798;
  border-bottom: 2px solid #4D1798;
  transform: rotate(-45deg);
}

/*  c-form01
----------------------------------- */
.c-form01 .c-form01__contents {
  padding: 40px 20px;
  background: #F5F5F5;
}
.c-form01 > * {
  font-size: 1rem;
}
.c-form01 .c-form01__contents-item {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 16px;
}
.c-form01 .c-form01__contents-item:first-child {
  padding-top: 0;
}
.c-form01 .c-form01__contents-ttl {
  font-weight: 700;
}
.c-form01 .c-form01__contents-ttl-required {
  display: inline-block;
  margin-left: 4px;
  padding: 1px 3px;
  color: #fff;
  font-size: 0.75rem;
  background: #4D1798;
}
.c-form01 .c-form01__contents-add-num {
  display: flex;
  align-items: center;
  gap: 6px;
}
.c-form01 .c-form01__contents-add-input {
  padding-top: 10px;
}
.c-form01 .c-form01__contents-add-input:first-child {
  padding-top: 0;
}
.c-form01 .c-form01__privacy {
  padding-top: 40px;
}
.c-form01 .c-form01__privacy-ttl {
  padding-bottom: 16px;
  font-weight: 700;
}
.c-form01 .c-form01__privacy-txt {
  padding: 24px;
  color: #777;
  border: 1px solid #DBDBDB;
}
.c-form01 .c-form01__contents-check {
  padding-top: 16px;
  text-align: center;
}
.c-form01 .c-form01__contents-btnWrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  justify-content: center;
  gap: 10px;
}
.c-form01 .c-form01__contents-btn {
  padding: 13px;
  width: 220px;
  color: #1A1A1A;
  border: 1px solid #1A1A1A;
  border-radius: 4px;
  background: #fff;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-form01 .c-form01__contents-btn:hover, .c-form01 .c-form01__contents-btn:focus-visible {
  color: #fff;
  background: #4D1798;
}
.c-form01 .c-form01__contents-btn.-back {
  background: #DBDBDB;
}
.c-form01 .c-form01__contents-btn.-back:hover, .c-form01 .c-form01__contents-btn.-back:focus-visible {
  background: #777;
}
@media all and ( 1008px <= width ) {
  .c-form01 .c-form01__contents {
    padding: 80px 100px;
  }
  .c-form01 .c-form01__contents-item {
    flex-direction: row;
    align-items: center;
    gap: 50px;
  }
  .c-form01 .c-form01__contents-item dd {
    width: calc(100% - (20% + 50px));
  }
  .c-form01 .c-form01__contents-ttl {
    width: 20%;
  }
  .c-form01 .c-form01__contents-ttl-required {
    margin-left: 10px;
  }
  .c-form01 .c-form01__contents-add-num {
    gap: 16px;
  }
  .c-form01 .c-form01__privacy {
    padding-top: 46px;
  }
  .c-form01 .c-form01__contents-btnWrap {
    margin-top: 56px;
  }
  .c-form01 .c-form01__contents-btn {
    padding: 18px;
    width: 360px;
    font-size: 1.125rem;
  }
}

.c-form01 .c-form01__contents-item .c-form01__contents-ttl.-pcCenter {
  align-self: self-start;
}

/*  c-tab01
---------------------------------------- */
.c-tab01 .c-tab01__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.c-tab01 .c-tab01__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 12px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #DBDBDB;
  font-weight: 700;
}
.c-tab01 .c-tab01__item:nth-child(2n+1) {
  border-radius: 4px 0 0 4px;
}
.c-tab01 .c-tab01__item:nth-child(2n) {
  border-radius: 0 4px 4px 0;
}
.c-tab01 .c-tab01__item.is-active {
  background: #4D1798;
  color: #fff;
  border-color: #4D1798;
}
.c-tab01 .c-tab01__contents {
  display: none;
  padding: 24px 16px;
  border: 1px solid #DBDBDB;
}
.c-tab01 .c-tab01__contents.is-active {
  display: block;
}
@media all and ( 1008px <= width ) {
  .c-tab01 .c-tab01__item {
    width: 214px;
    font-size: 1.125rem;
  }
  .c-tab01 .c-tab01__item:nth-child(2n+1) {
    border-radius: 0;
  }
  .c-tab01 .c-tab01__item:nth-child(2n) {
    border-radius: 0;
  }
  .c-tab01 .c-tab01__item:first-child {
    border-radius: 4px 0 0 4px;
  }
  .c-tab01 .c-tab01__item:last-child {
    border-radius: 0 4px 4px 0;
  }
  .c-tab01 .c-tab01__contents {
    padding: 38px 40px;
  }
}

.c-breadcrumb {
  position: absolute;
  width: max-content;
  bottom: -90px;
  right: 120px;
}
.c-breadcrumb .c-breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  background: #F5F5F5;
  gap: 22px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.8125rem;
}
.c-breadcrumb .c-breadcrumb__list > span a {
  display: inline-block;
  color: #1A1A1A;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.c-breadcrumb .c-breadcrumb__list > span a:hover, .c-breadcrumb .c-breadcrumb__list > span a:focus-visible {
  opacity: 0.5;
}
.c-breadcrumb .c-breadcrumb__list > span:nth-child(n+2) {
  position: relative;
  color: #777;
}
.c-breadcrumb .c-breadcrumb__list > span:nth-child(n+2)::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -16px;
  width: 10px;
  height: 1px;
  background: #ADADAD;
}
@media all and ( 1640px < width ) {
  .c-breadcrumb {
    right: 20px;
  }
}

.u-clearfix {
  position: relative;
}
.u-clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.u-txt-uppercase {
  text-transform: uppercase;
}

.u-txt-center {
  text-align: center;
}

.u-txt-left {
  text-align: left;
}

.u-txt-right {
  text-align: right;
}

@media all and ( 768px <= width ) {
  .u-view-small {
    display: none !important;
  }
}

@media all and ( width < 768px) {
  .u-view-medium {
    display: none !important;
  }
}
@media all and ( 1008px <= width ) {
  .u-view-medium {
    display: none !important;
  }
}

@media all and (width < 1008px) {
  .u-view-large {
    display: none !important;
  }
}
@media all and (1640px <= width) {
  .u-view-large {
    display: none !important;
  }
}

@media all and (width < 1640px) {
  .u-view-full {
    display: none !important;
  }
}

@media all and ( 1008px <= width ) {
  .u-view-under-medium {
    display: none !important;
  }
}

@media all and (width < 1008px) {
  .u-view-upper-large {
    display: none !important;
  }
}

.u-clr-primary {
  color: #4D1798;
}

/*  404
================================================ */
.p-page404 {
  padding: 50px 0;
}
.p-page404 .p-page404__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-page404 .p-page404__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media all and ( 768px <= width ) {
  .p-page404 {
    padding: 100px 0;
  }
}

.p-index-hero .p-index-hero__text {
  position: relative;
  z-index: 1;
  padding: 50px 0 60px;
}
.p-index-hero .p-index-hero__text-outer {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-index-hero .p-index-hero__text-outer {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-hero .p-index-hero__text-logo {
  width: 24px;
  margin: 0 auto 24px;
}
.p-index-hero .p-index-hero__text-logo path {
  fill: #1A1A1A;
}
.p-index-hero .p-index-hero__text-row {
  text-align: center;
  font-weight: 700;
  line-height: 1.6;
  line-height: 1.6;
  font-size: 2.375rem;
  letter-spacing: 0.06em;
}
.p-index-hero .p-index-hero__text-row span {
  display: block;
}
.p-index-hero .p-index-hero__text-row .-spSmall {
  font-size: 1.25rem;
}
.p-index-hero .p-index-hero__topics {
  display: flex;
  height: 80px;
  border-bottom: 1px solid #EDEDED;
}
.p-index-hero .p-index-hero__topics-header {
  display: grid;
  place-content: center;
  width: auto;
  height: 100%;
  aspect-ratio: 1/1;
  background: #4D1798;
  color: #fff;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 0.75rem;
}
.p-index-hero .p-index-hero__topics-body {
  width: calc(100% - 80px);
}
.p-index-hero .p-index-hero__topics-link {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5px 20px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-hero .p-index-hero__topics-link:hover, .p-index-hero .p-index-hero__topics-link:focus-visible {
  opacity: 0.5;
}
.p-index-hero .p-index-hero__topics-title {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.8125rem;
  color: #505050;
}
@media all and ( 1008px <= width ) {
  .p-index-hero {
    position: relative;
    z-index: 2;
  }
  .p-index-hero .p-index-hero__text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .p-index-hero .p-index-hero__text-outer {
    height: 100%;
    width: min(100%, 1640px);
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media all and ( 1008px <= width ) and (768px <= width < 1640px) {
  .p-index-hero .p-index-hero__text-outer {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media all and ( 1008px <= width ) {
  .p-index-hero .p-index-hero__text-inner {
    position: relative;
    height: 100%;
  }
}
@media all and ( 1008px <= width ) {
  .p-index-hero .p-index-hero__video {
    position: relative;
  }
  .p-index-hero .p-index-hero__video::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
  }
}
@media all and ( 1008px <= width ) {
  .p-index-hero .p-index-hero__text-row {
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    left: 0;
    color: #fff;
    text-align: left;
    font-size: clamp(3.1875rem, 3.5416666667vw, 4.25rem);
  }
  .p-index-hero .p-index-hero__text-row .-spSmall {
    font-size: clamp(2.25rem, 2.5vw, 3rem);
  }
  .p-index-hero .p-index-hero__text-row .-pcLarge {
    font-size: clamp(3.1875rem, 3.5416666667vw, 4.25rem);
  }
}
@media all and ( 1008px <= width ) {
  .p-index-hero .p-index-hero__topics {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 593px;
    height: 65px;
    border-bottom: 0;
    background: #fff;
    z-index: 2;
  }
  .p-index-hero .p-index-hero__topics-header {
    height: 100%;
    aspect-ratio: 95/65;
    font-size: 0.875rem;
  }
  .p-index-hero .p-index-hero__topics-link {
    padding: 5px 24px;
  }
  .p-index-hero .p-index-hero__topics-title {
    -webkit-line-clamp: 1;
    font-size: 0.875rem;
  }
}

/*  p-index-link
---------------------------------------- */
.p-index-link {
  padding: 60px 0 50px;
}
.p-index-link .p-index-link__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-index-link .p-index-link__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-link .p-index-link__header {
  position: relative;
  margin-bottom: 12px;
  padding-left: 12px;
  color: #777;
  font-size: 0.75rem;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
}
.p-index-link .p-index-link__header::before, .p-index-link .p-index-link__header::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 7px;
  height: 1px;
  background: #777;
}
.p-index-link .p-index-link__header::after {
  rotate: 90deg;
}
.p-index-link .p-index-link__card {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.p-index-link .p-index-link__card-item {
  width: calc(50% - 4px);
  aspect-ratio: 1/1;
}
.p-index-link .p-index-link__card-item-link {
  position: relative;
  display: block;
  height: 100%;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  padding: 20px 20px 40px;
}
.p-index-link .p-index-link__card-item-text {
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.6;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-link .p-index-link__card-item-img {
  width: 40px;
  margin-top: 14px;
}
.p-index-link .p-index-link__card-item-arw {
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: 24px;
  aspect-ratio: 1;
  background: #F5F5F5;
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-link .p-index-link__card-item-arw::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 4px;
  aspect-ratio: 4/8;
  background: #1A1A1A;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-link .p-index-link__card-item-link:hover .p-index-link__card-item-text, .p-index-link .p-index-link__card-item-link:focus-visible .p-index-link__card-item-text {
  color: #4D1798;
}
.p-index-link .p-index-link__card-item-link:hover .p-index-link__card-item-arw, .p-index-link .p-index-link__card-item-link:focus-visible .p-index-link__card-item-arw {
  background: #4D1798;
}
.p-index-link .p-index-link__card-item-link:hover .p-index-link__card-item-arw::before, .p-index-link .p-index-link__card-item-link:focus-visible .p-index-link__card-item-arw::before {
  background: #fff;
}
@media all and ( 1008px <= width ) {
  .p-index-link {
    padding: 140px 0 50px;
  }
  .p-index-link .p-index-link__header {
    margin-bottom: 24px;
    padding-left: 17px;
    font-size: 0.8125rem;
  }
  .p-index-link .p-index-link__header::before, .p-index-link .p-index-link__header::after {
    width: 9px;
  }
  .p-index-link .p-index-link__card {
    gap: 16px;
  }
  .p-index-link .p-index-link__card-item {
    width: calc(50% - 8px);
    aspect-ratio: 388/201;
  }
  .p-index-link .p-index-link__card-item-link {
    padding: 32px 32px 55px;
  }
  .p-index-link .p-index-link__card-item-text {
    font-size: 1.25rem;
  }
  .p-index-link .p-index-link__card-item-img {
    width: 50px;
    margin-top: 16px;
  }
  .p-index-link .p-index-link__card-item-arw {
    right: 10px;
    bottom: 10px;
    width: 32px;
  }
  .p-index-link .p-index-link__card-item-arw::before {
    width: 5px;
  }
}
@media all and (1500px <= width) {
  .p-index-link .p-index-link__card-item {
    width: calc(25% - 12px);
  }
  .p-index-link .p-index-link__card-item-text {
    font-size: clamp(0.9375rem, 1.0416666667vw, 1.25rem);
  }
}

/* p-index-intro
---------------------------------------- */
.p-index-intro {
  padding: 50px 0;
}
.p-index-intro .p-index-intro__inner {
  width: min(100%, 1328px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-index-intro .p-index-intro__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-intro .p-index-intro__header {
  position: relative;
  margin-bottom: 68px;
  text-align: center;
  font-weight: 700;
  line-height: 1.6;
}
.p-index-intro .p-index-intro__header::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 205px;
  aspect-ratio: 1;
  background: url(../img/common/icon-crest.svg) no-repeat center/contain;
  z-index: -1;
}
.p-index-intro .p-index-intro__header-sub {
  display: block;
  font-size: 1.0625rem;
}
.p-index-intro .p-index-intro__header-main {
  display: block;
  margin-top: 5px;
  font-size: 1.75rem;
}
.p-index-intro .p-index-intro__text {
  padding: 0 20px;
  color: #505050;
  line-height: 2;
}
.p-index-intro .p-index-intro__text strong {
  color: #1A1A1A;
}
@media all and ( 1008px <= width ) {
  .p-index-intro {
    padding: 50px 0 80px;
  }
  .p-index-intro .p-index-intro__contents {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 740px;
  }
  .p-index-intro .p-index-intro__contents::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    width: 740px;
    aspect-ratio: 1;
    background: url(../img/common/icon-crest.svg) no-repeat center/contain;
    z-index: -1;
  }
  .p-index-intro .p-index-intro__header {
    margin-bottom: 56px;
  }
  .p-index-intro .p-index-intro__header::before {
    content: none;
  }
  .p-index-intro .p-index-intro__header-sub {
    font-size: 2.5rem;
  }
  .p-index-intro .p-index-intro__header-main {
    margin-top: 0;
    font-size: 2.5rem;
  }
  .p-index-intro .p-index-intro__text {
    padding: 0;
    font-size: 1.25rem;
  }
}

/*  p-index-date
---------------------------------------- */
.p-index-date {
  padding: 50px 0 100px;
}
.p-index-date .p-index-date__inner {
  width: min(100%, 1328px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-index-date .p-index-date__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-date .p-index-date__container {
  position: relative;
  border-bottom: 1px solid #1A1A1A;
}
.p-index-date .p-index-date__container::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 514px;
  aspect-ratio: 514/530;
  background: url(../img/home/img-japan.svg) no-repeat center/contain;
  z-index: -1;
}
.p-index-date .p-index-date__contents {
  border-top: 1px solid #1A1A1A;
  padding: 16px 0 32px;
}
.p-index-date .p-index-date__contents-header {
  font-weight: 700;
  line-height: 1.6;
  font-size: 0.9375rem;
  font-weight: 700;
}
.p-index-date .p-index-date__contents-body {
  margin-top: 24px;
}
.p-index-date .p-index-date__contents-body.-mtLarge {
  margin-top: 32px;
}
.p-index-date .p-index-date__contents-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.p-index-date .p-index-date__contents-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}
.p-index-date .p-index-date__contents-text {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  gap: 4px;
}
.p-index-date .p-index-date__contents-text-num {
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 2.8125rem;
}
.p-index-date .p-index-date__contents-text-small {
  font-size: 0.9375rem;
  font-weight: 700;
}
.p-index-date .p-index-date__contents-text-side {
  font-size: 1.0625rem;
  font-weight: 700;
}
.p-index-date .p-index-date__contents-item-add {
  margin-top: 2px;
  text-align: right;
  font-size: 0.8125rem;
}
.p-index-date .p-index-date__contents-iso {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 16px;
}
.p-index-date .p-index-date__contents-iso-item {
  width: 86px;
}
@media all and ( 1008px <= width ) {
  .p-index-date {
    padding: 80px 0 200px;
  }
  .p-index-date .p-index-date__container {
    position: relative;
    height: 690px;
    border-bottom: 0;
  }
  .p-index-date .p-index-date__container::before {
    width: 1050px;
  }
  .p-index-date .p-index-date__contents {
    position: absolute;
    padding: 24px 20px 0;
  }
  .p-index-date .p-index-date__contents:nth-child(1) {
    top: 0;
    left: 0;
    width: 50%;
  }
  .p-index-date .p-index-date__contents:nth-child(2) {
    top: 0;
    right: 0;
    width: 40%;
  }
  .p-index-date .p-index-date__contents:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 50%;
  }
  .p-index-date .p-index-date__contents-header {
    font-size: 1.25rem;
  }
  .p-index-date .p-index-date__contents-body {
    margin-top: 24px;
  }
  .p-index-date .p-index-date__contents-body.-mtLarge {
    margin-top: 40px;
  }
  .p-index-date .p-index-date__contents-list {
    gap: 10px;
  }
  .p-index-date .p-index-date__contents-text {
    gap: 12px;
  }
  .p-index-date .p-index-date__contents-text-num {
    font-size: clamp(3.75rem, 4.1666666667vw, 5rem);
  }
  .p-index-date .p-index-date__contents-text-small {
    font-size: clamp(1.125rem, 1.25vw, 1.5rem);
  }
  .p-index-date .p-index-date__contents-text-side {
    font-size: clamp(1.40625rem, 1.5625vw, 1.875rem);
  }
  .p-index-date .p-index-date__contents-item-add {
    margin-top: 10px;
    font-size: 1.125rem;
  }
  .p-index-date .p-index-date__contents-iso {
    gap: 32px;
  }
  .p-index-date .p-index-date__contents-iso-item {
    width: 137px;
  }
}
@media all and ( 1640px < width ) {
  .p-index-date .p-index-date__container::before {
    left: -16px;
    top: -48px;
    translate: 0% 0%;
  }
  .p-index-date .p-index-date__contents:nth-child(1) {
    width: clamp(25.3125vw, 33.75vw, 648px);
  }
  .p-index-date .p-index-date__contents:nth-child(2) {
    width: clamp(14.6875vw, 19.5833333333vw, 376px);
  }
  .p-index-date .p-index-date__contents:nth-child(3) {
    width: clamp(14.6875vw, 19.5833333333vw, 376px);
  }
  .p-index-date .p-index-date__contents:nth-child(4) {
    width: clamp(25.3125vw, 33.75vw, 648px);
  }
}

/*  p-index-business
---------------------------------------- */
.p-index-business {
  padding: 100px 0;
  background: #F5F5F5;
}
.p-index-business .p-index-business__inner {
  position: relative;
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1;
}
@media all and (768px <= width < 1640px) {
  .p-index-business .p-index-business__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-business .p-index-business__inner::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -100px;
  width: 221px;
  height: 100px;
  background: #fff;
  mask: url(../img/common/bg-nav-logo.svg) no-repeat center/contain;
  z-index: -1;
}
.p-index-business .p-index-business__item:nth-child(n+2) {
  margin-top: 60px;
}
.p-index-business .p-index-business__item-link {
  display: block;
  height: 100%;
}
.p-index-business .p-index-business__item-img {
  margin: 0 -20px;
}
.p-index-business .p-index-business__item-text {
  margin-top: 24px;
}
.p-index-business .p-index-business__item-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 9px;
  margin-bottom: 20px;
}
.p-index-business .p-index-business__item-header-num {
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 400;
}
.p-index-business .p-index-business__item-header-ja {
  font-weight: 700;
  line-height: 1.6;
  font-size: 1.375rem;
  font-weight: 700;
}
.p-index-business .p-index-business__item-text-row {
  color: #777;
}
.p-index-business .p-index-business__item-text-btn {
  position: relative;
  display: block;
  width: 105px;
  margin-top: 20px;
  padding-right: 17px;
  color: #1A1A1A;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
}
.p-index-business .p-index-business__item-text-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  translate: 0 -50%;
  width: 5px;
  height: 10px;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  background: #1A1A1A;
}
@media all and ( 1008px <= width ) {
  .p-index-business {
    padding: 200px 0;
  }
  .p-index-business .p-index-business__inner::before {
    bottom: -200px;
    right: 120px;
    width: 707px;
    height: 320px;
  }
  .p-index-business .p-index-business__list {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
  .p-index-business .p-index-business__item {
    position: relative;
    overflow: hidden;
    flex: 0 0 120px;
    height: 797px;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
    transition-property: all;
    transition: 0.3s;
  }
  .p-index-business .p-index-business__item::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 50%;
    border-radius: 4px 4px 0 0;
    opacity: 0.5;
    background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }
  .p-index-business .p-index-business__item::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60%;
    border-radius: 0 0 4px 4px;
    opacity: 0.5;
    background-image: linear-gradient(to top, #000 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }
  .p-index-business .p-index-business__item:nth-child(n+2) {
    margin-top: 0;
  }
  .p-index-business .p-index-business__item.is-active {
    flex: 1;
  }
  .p-index-business .p-index-business__item.is-active::after {
    content: none;
  }
  .p-index-business .p-index-business__item.is-active .p-index-business__item-text-row {
    opacity: 1;
    transition: opacity 0.1s 0.3s linear;
  }
  .p-index-business .p-index-business__item.is-active .p-index-business__item-header {
    left: 80px;
    translate: 0 0;
    animation: titleAnm 0.5s linear;
  }
  .p-index-business .p-index-business__item.is-active .p-index-business__item-header-num {
    font-size: 1.25rem;
  }
  .p-index-business .p-index-business__item.is-active .p-index-business__item-header-ja {
    writing-mode: horizontal-tb;
    text-orientation: mixed;
    font-size: 2.5rem;
  }
  .p-index-business .p-index-business__item-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .p-index-business .p-index-business__item-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    width: 100%;
    height: 797px;
    object-fit: cover;
    border-radius: 4px;
  }
  .p-index-business .p-index-business__item-text {
    margin-top: 0;
  }
  .p-index-business .p-index-business__item-header {
    position: absolute;
    left: 50%;
    translate: -50% 0;
    top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-bottom: 0;
    color: #fff;
    z-index: 2;
  }
  .p-index-business .p-index-business__item-header-num {
    font-size: 0.875rem;
  }
  .p-index-business .p-index-business__item-header-ja {
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 1.75rem;
  }
  .p-index-business .p-index-business__item-text-row {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 80px;
    color: #fff;
    font-size: 1.125rem;
    opacity: 0;
    transition: opacity 0s linear;
    z-index: 2;
  }
  .p-index-business .p-index-business__item-text-btn {
    margin: 32px 0 0 auto;
    color: #fff;
  }
  .p-index-business .p-index-business__item-text-btn::before {
    background: #fff;
  }
}
@media all and ( 1640px < width ) {
  .p-index-business .p-index-business__inner::before {
    right: 20px;
  }
}

@keyframes titleAnm {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* p-index-products
---------------------------------------- */
.p-index-products {
  padding: 100px 0;
}
.p-index-products .p-index-products__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-index-products .p-index-products__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media all and ( 1008px <= width ) {
  .p-index-products {
    padding: 200px 0 192px;
  }
}

.p-index-products-pickup {
  position: relative;
  padding: 60px 24px;
}
.p-index-products-pickup::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #F5F5F5;
  z-index: -1;
}
@media all and ( 1008px <= width ) {
  .p-index-products-pickup {
    padding: 100px 0;
  }
  .p-index-products-pickup::before {
    left: -80px;
    right: -80px;
  }
}

.p-index-products-main {
  margin-top: 60px;
}
.p-index-products-main .p-index-products-main__list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.p-index-products-main .p-index-products-main__item-link {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 335/223;
  border-radius: 4px;
}
.p-index-products-main .p-index-products-main__item-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 110px;
  opacity: 0.3;
  background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}
.p-index-products-main .p-index-products-main__item-text {
  position: absolute;
  top: 20px;
  left: 20px;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1.125rem;
  color: #fff;
  z-index: 1;
}
.p-index-products-main .p-index-products-main__item-img {
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-products-main .p-index-products-main__item-arw {
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: 24px;
  aspect-ratio: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.4);
  z-index: 1;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-products-main .p-index-products-main__item-arw::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  background: #fff;
  width: 4px;
  aspect-ratio: 1/2;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-products-main .p-index-products-main__item-link {
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-products-main .p-index-products-main__item-link:hover .p-index-products-main__item-img, .p-index-products-main .p-index-products-main__item-link:focus-visible .p-index-products-main__item-img {
  scale: 1.1;
}
.p-index-products-main .p-index-products-main__item-link:hover .p-index-products-main__item-arw::before, .p-index-products-main .p-index-products-main__item-link:focus-visible .p-index-products-main__item-arw::before {
  background: #4D1798;
}
.p-index-products-main .p-index-products-main__item-link:hover .p-index-products-main__item-arw, .p-index-products-main .p-index-products-main__item-link:focus-visible .p-index-products-main__item-arw {
  background: white;
}
@media all and ( 1008px <= width ) {
  .p-index-products-main {
    margin-top: 100px;
  }
  .p-index-products-main .p-index-products-main__list {
    gap: 15px;
  }
  .p-index-products-main .p-index-products-main__item {
    width: calc(25% - 11.25px);
  }
  .p-index-products-main .p-index-products-main__item-link {
    aspect-ratio: 388/582;
  }
  .p-index-products-main .p-index-products-main__item-link::before {
    height: 290px;
  }
  .p-index-products-main .p-index-products-main__item-text {
    top: clamp(1.5625vw, 2.0833333333vw, 40px);
    left: clamp(1.5625vw, 2.0833333333vw, 40px);
    font-size: clamp(1.03125rem, 1.1458333333vw, 1.375rem);
  }
  .p-index-products-main .p-index-products-main__item-arw {
    right: 16px;
    bottom: 16px;
    width: 32px;
  }
  .p-index-products-main .p-index-products-main__item-arw::before {
    width: 5px;
  }
}

.p-index-products-other {
  margin-top: 60px;
}
.p-index-products-other .p-index-products-other__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "item01 item01" "item02 item03";
  gap: 8px;
}
.p-index-products-other .p-index-products-other__item:nth-child(1) {
  grid-area: item01;
}
.p-index-products-other .p-index-products-other__item:nth-child(2) {
  grid-area: item02;
}
.p-index-products-other .p-index-products-other__item:nth-child(3) {
  grid-area: item03;
}
.p-index-products-other .p-index-products-other__item-link {
  position: relative;
  display: block;
  height: 100%;
  padding: 20px 20px 35px;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-products-other .p-index-products-other__item-link:hover .p-index-products-other__item-text, .p-index-products-other .p-index-products-other__item-link:focus-visible .p-index-products-other__item-text {
  color: #4D1798;
}
.p-index-products-other .p-index-products-other__item-link:hover .p-index-products-other__item-arw, .p-index-products-other .p-index-products-other__item-link:focus-visible .p-index-products-other__item-arw {
  background: #4D1798;
}
.p-index-products-other .p-index-products-other__item-link:hover .p-index-products-other__item-arw::before, .p-index-products-other .p-index-products-other__item-link:focus-visible .p-index-products-other__item-arw::before {
  background: #fff;
}
.p-index-products-other .p-index-products-other__item-text {
  font-weight: 700;
  line-height: 1.6;
  font-size: 0.9375rem;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-products-other .p-index-products-other__item-icon {
  margin-top: 10px;
  width: 40px;
}
.p-index-products-other .p-index-products-other__item-arw {
  position: absolute;
  bottom: 6px;
  right: 6px;
  width: 24px;
  aspect-ratio: 1;
  background: #F5F5F5;
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-products-other .p-index-products-other__item-arw::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 4px;
  aspect-ratio: 1/2;
  background: #777;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
}
@media all and ( 1008px <= width ) {
  .p-index-products-other {
    margin-top: 60px;
  }
  .p-index-products-other .p-index-products-other__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "item01 item02 item03";
    gap: 17px;
  }
  .p-index-products-other .p-index-products-other__item-link {
    aspect-ratio: 522/230;
    height: auto;
    padding: 40px 40px 55px;
  }
  .p-index-products-other .p-index-products-other__item-text {
    font-size: 1.375rem;
  }
  .p-index-products-other .p-index-products-other__item-icon {
    margin-top: 16px;
    width: 60px;
  }
  .p-index-products-other .p-index-products-other__item-arw {
    bottom: 10px;
    right: 10px;
    width: 32px;
  }
  .p-index-products-other .p-index-products-other__item-arw::before {
    width: 5px;
  }
}

/*  p-index-innovation
---------------------------------------- */
.p-index-innovation {
  padding: 60px 0;
  background: #F5F5F5;
}
.p-index-innovation .p-index-innovation__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-index-innovation .p-index-innovation__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-innovation .p-index-innovation__container {
  display: flex;
  flex-wrap: wrap;
  gap: 34px;
}
.p-index-innovation .p-index-innovation__contents {
  border-top: 1px solid #1A1A1A;
  width: 100%;
  padding: 0 10px;
}
.p-index-innovation .p-index-innovation__contents-item {
  padding: 16px 0 32px;
}
.p-index-innovation .p-index-innovation__contents-item:nth-child(n+2) {
  border-top: 1px solid #DBDBDB;
}
.p-index-innovation .p-index-innovation__contents-item-head {
  font-size: 0.9375rem;
  font-weight: 700;
}
.p-index-innovation .p-index-innovation__contents-item-text {
  margin-top: 2px;
  color: #505050;
  font-size: 0.8125rem;
}
.p-index-innovation .p-index-innovation__contents-more {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 16px;
}
.p-index-innovation .p-index-innovation__contents-more-item {
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
}
.p-index-innovation .p-index-innovation__contents-more-item-link {
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 315/177;
}
.p-index-innovation .p-index-innovation__contents-more-item-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 110px;
  opacity: 0.3;
  background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}
.p-index-innovation .p-index-innovation__contents-more-item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-innovation .p-index-innovation__contents-more-item-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.p-index-innovation .p-index-innovation__contents-more-item-text {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1.125rem;
  z-index: 2;
}
.p-index-innovation .p-index-innovation__contents-more-item-arw {
  position: absolute;
  bottom: 6px;
  right: 6px;
  width: 24px;
  aspect-ratio: 1;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-innovation .p-index-innovation__contents-more-item-arw::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 4px;
  aspect-ratio: 1/2;
  background: #fff;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-innovation .p-index-innovation__contents-more-item-link {
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-innovation .p-index-innovation__contents-more-item-link:hover .p-index-innovation__contents-more-item-img, .p-index-innovation .p-index-innovation__contents-more-item-link:focus-visible .p-index-innovation__contents-more-item-img {
  scale: 1.1;
}
.p-index-innovation .p-index-innovation__contents-more-item-link:hover .p-index-innovation__contents-more-item-arw, .p-index-innovation .p-index-innovation__contents-more-item-link:focus-visible .p-index-innovation__contents-more-item-arw {
  background: #fff;
}
.p-index-innovation .p-index-innovation__contents-more-item-link:hover .p-index-innovation__contents-more-item-arw::before, .p-index-innovation .p-index-innovation__contents-more-item-link:focus-visible .p-index-innovation__contents-more-item-arw::before {
  background: #4D1798;
}
@media all and ( 1008px <= width ) {
  .p-index-innovation {
    padding: 160px 0;
  }
  .p-index-innovation .p-index3innovation__container {
    gap: 32px;
  }
  .p-index-innovation .p-index-innovation__contents {
    width: calc(50% - 17px);
    padding: 0 5px;
  }
  .p-index-innovation .p-index-innovation__contents-item {
    padding: 24px 20px;
  }
  .p-index-innovation .p-index-innovation__contents-item-head {
    font-size: 1.25rem;
  }
  .p-index-innovation .p-index-innovation__contents-item-text {
    margin-top: 6px;
    font-size: 0.875rem;
  }
  .p-index-innovation .p-index-innovation__contents-more {
    gap: 16px;
    padding-top: 24px;
  }
  .p-index-innovation .p-index-innovation__contents-more-item-link {
    aspect-ratio: 492/277;
  }
  .p-index-innovation .p-index-innovation__contents-more-item-link::before {
    height: 139px;
  }
  .p-index-innovation .p-index-innovation__contents-more-item-text {
    top: clamp(1.5625vw, 2.0833333333vw, 40px);
    left: clamp(1.5625vw, 2.0833333333vw, 40px);
    font-size: 1.375rem;
  }
  .p-index-innovation .p-index-innovation__contents-more-item-arw {
    bottom: 10px;
    right: 10px;
    width: 32px;
  }
  .p-index-innovation .p-index-innovation__contents-more-item-arw::before {
    width: 5px;
  }
}
@media all and ( 1640px < width ) {
  .p-index-innovation .p-index-innovation__wrap {
    display: grid;
    grid-template-columns: auto 1056px;
  }
}

/*  p-index-company
---------------------------------------- */
.p-index-company {
  padding: 100px 0 40px;
}
.p-index-company .p-index-company__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-index-company .p-index-company__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-company .p-index-company__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "area1 area1" "area2 area3" "area4 area4";
  gap: 8px;
}
.p-index-company .p-index-company__item:nth-child(1) {
  grid-area: area1;
}
.p-index-company .p-index-company__item:nth-child(2) {
  grid-area: area2;
}
.p-index-company .p-index-company__item:nth-child(3) {
  grid-area: area3;
}
.p-index-company .p-index-company__item:nth-child(4) {
  grid-area: area4;
}
.p-index-company .p-index-company__item-link {
  overflow: hidden;
  position: relative;
  display: block;
  border-radius: 4px;
}
.p-index-company .p-index-company__item-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 94px;
  opacity: 0.3;
  background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}
.p-index-company .p-index-company__item-img {
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-company .p-index-company__item-text {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  z-index: 1;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.6;
}
.p-index-company .p-index-company__item-arw {
  position: absolute;
  bottom: 6px;
  right: 6px;
  width: 24px;
  aspect-ratio: 1;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-company .p-index-company__item-arw::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 4px;
  aspect-ratio: 1/2;
  background: #fff;
  mask: url(../img/common/icon-arw01.svg) no-repeat center/contain;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-company .p-index-company__item-link {
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-company .p-index-company__item-link:hover .p-index-company__item-img, .p-index-company .p-index-company__item-link:focus-visible .p-index-company__item-img {
  scale: 1.1;
}
.p-index-company .p-index-company__item-link:hover .p-index-company__item-arw, .p-index-company .p-index-company__item-link:focus-visible .p-index-company__item-arw {
  background: #fff;
}
.p-index-company .p-index-company__item-link:hover .p-index-company__item-arw::before, .p-index-company .p-index-company__item-link:focus-visible .p-index-company__item-arw::before {
  background: #4D1798;
}
@media all and ( 1008px <= width ) {
  .p-index-company {
    padding: 200px 0 80px;
  }
  .p-index-company .p-index-company__list {
    grid-template-columns: clamp(22.3828125vw, 29.84375vw, 573px) 1fr clamp(22.3828125vw, 29.84375vw, 573px);
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "area1 area1 area2" "area3 area4 area4";
    gap: 16px;
  }
  .p-index-company .p-index-company__item:nth-child(2), .p-index-company .p-index-company__item:nth-child(3) {
    aspect-ratio: 1/1;
  }
  .p-index-company .p-index-company__item-link {
    height: 100%;
  }
  .p-index-company .p-index-company__item-link::before {
    height: clamp(11.1328125vw, 14.84375vw, 285px);
  }
  .p-index-company .p-index-company__item-img {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    width: 100%;
    height: 100%;
  }
  .p-index-company .p-index-company__item-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .p-index-company .p-index-company__item-text {
    top: clamp(2.34375vw, 3.125vw, 60px);
    left: clamp(2.421875vw, 3.2291666667vw, 62px);
    font-size: clamp(1.25vw, 1.6666666667vw, 32px);
  }
  .p-index-company .p-index-company__item-arw {
    bottom: 24px;
    right: 24px;
    width: 40px;
  }
  .p-index-company .p-index-company__item-arw::before {
    width: 7px;
  }
}

/* p-index-topics
---------------------------------------- */
.p-index-topics {
  padding: 40px 0 100px;
}
.p-index-topics .p-index-topics__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-index-topics .p-index-topics__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-index-topics .p-index-topics__body {
  border-top: 1px solid #1A1A1A;
}
@media all and ( 1008px <= width ) {
  .p-index-topics {
    padding: 80px 0 200px;
  }
}
@media all and ( 1640px < width ) {
  .p-index-topics .p-index-topics__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .p-index-topics .p-index-topics__header {
    align-self: start;
    width: calc(100% - 1190px);
  }
  .p-index-topics .p-index-topics__body {
    width: 1190px;
  }
}

/*  p-index-recruit
---------------------------------------- */
.p-index-recruit .p-index-recruit__link {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 375/563;
}
.p-index-recruit .p-index-recruit__img {
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-recruit .p-index-recruit__text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.p-index-recruit .p-index-recruit__text-ja {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
}
.p-index-recruit .p-index-recruit__text-en {
  align-self: end;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  color: #fff;
}
.p-index-recruit .p-index-recruit__text-en-in {
  font-size: 3rem;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
}
.p-index-recruit .p-index-recruit__text-en-arw {
  position: relative;
  display: inline-block;
  width: 40px;
  aspect-ratio: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-recruit .p-index-recruit__text-en-arw::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  width: 7px;
  aspect-ratio: 1/2;
  background: #fff;
  mask: url(../img/common/icon-arw02.svg) no-repeat center/contain;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-recruit .p-index-recruit__link {
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-index-recruit .p-index-recruit__link:hover .p-index-recruit__img, .p-index-recruit .p-index-recruit__link:focus-visible .p-index-recruit__img {
  scale: 1.1;
}
.p-index-recruit .p-index-recruit__link:hover .p-index-recruit__text-en-arw, .p-index-recruit .p-index-recruit__link:focus-visible .p-index-recruit__text-en-arw {
  background: white;
}
.p-index-recruit .p-index-recruit__link:hover .p-index-recruit__text-en-arw::before, .p-index-recruit .p-index-recruit__link:focus-visible .p-index-recruit__text-en-arw::before {
  background: #4D1798;
}
@media all and ( 1008px <= width ) {
  .p-index-recruit .p-index-recruit__link {
    aspect-ratio: 1920/1080;
  }
  .p-index-recruit .p-index-recruit__text {
    padding: 80px 160px;
  }
  .p-index-recruit .p-index-recruit__text-ja {
    font-size: 2.5rem;
  }
  .p-index-recruit .p-index-recruit__text-en {
    gap: 60px;
  }
  .p-index-recruit .p-index-recruit__text-en-in {
    font-size: clamp(7.5rem, 8.3333333333vw, 10rem);
  }
  .p-index-recruit .p-index-recruit__text-en-arw {
    width: 160px;
  }
  .p-index-recruit .p-index-recruit__text-en-arw::before {
    width: 30px;
  }
}

/*  p-about
---------------------------------------- */
.p-about .p-about__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-about .p-about__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-about .p-about__contents {
  padding-top: 60px;
}
@media all and ( 1008px <= width ) {
  .p-about .p-about__contents {
    padding-top: calc(200px - var(--headerH));
  }
}

/*  p-about-date
---------------------------------------- */
.p-about-date {
  padding-bottom: 50px;
}
.p-about-date .p-about-date__flex:nth-child(n+2) {
  margin-top: 32px;
}
.p-about-date .p-about-date__contents {
  padding: 16px 10px 0 10px;
  border-top: 1px solid #1A1A1A;
}
.p-about-date .p-about-date__contents:nth-child(n+2) {
  margin-top: 32px;
}
.p-about-date .p-about-date__contents-head {
  font-weight: 700;
  line-height: 1.6;
}
.p-about-date .p-about-date__contents-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 16px;
}
.p-about-date .p-about-date__contents-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}
.p-about-date .p-about-date__contents-text {
  display: flex;
  align-items: end;
  gap: 4px;
  font-size: 2.8125rem;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: 0;
}
.p-about-date .p-about-date__contents-text-side {
  display: inline-block;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  line-height: 1.8;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 0.9375rem;
  font-size: 1.0625rem;
  font-weight: 700;
}
@media all and ( 768px <= width ) {
  .p-about-date .p-about-date__contents-text-side {
    font-size: 1rem;
  }
}
.p-about-date .p-about-date__contents-text-small {
  display: inline-block;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  line-height: 1.8;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 0.9375rem;
  font-weight: 700;
}
@media all and ( 768px <= width ) {
  .p-about-date .p-about-date__contents-text-small {
    font-size: 1rem;
  }
}
.p-about-date .p-about-date__contents-item-add {
  text-align: right;
  margin-top: 2px;
}
.p-about-date .p-about-date__contents-detail {
  margin-top: 6px;
  color: #505050;
  font-size: 0.8125rem;
}
@media all and ( 1008px <= width ) {
  .p-about-date {
    position: relative;
    padding-bottom: 100px;
  }
  .p-about-date::before {
    content: "";
    position: absolute;
    top: 43%;
    left: 50%;
    translate: -50% -50%;
    width: 360px;
    height: 360px;
    background: url(../img/common/icon-crest.svg) no-repeat center/contain;
    z-index: -1;
  }
  .p-about-date .p-about-date__flex {
    display: flex;
    flex-wrap: wrap;
    gap: 60px 16px;
    margin-bottom: 60px;
  }
  .p-about-date .p-about-date__flex:nth-child(n+2) {
    margin: 60px 0 0 0;
  }
  .p-about-date .p-about-date__flex.-col1 .p-about-date__contents {
    width: 100%;
  }
  .p-about-date .p-about-date__flex.-col3 .p-about-date__contents {
    width: calc(50% - 8px);
  }
  .p-about-date .p-about-date__contents:nth-child(n+2) {
    margin-top: 0;
  }
  .p-about-date .p-about-date__contents-body {
    margin-top: 22px;
  }
}
@media all and ( 1640px < width ) {
  .p-about-date .p-about-date__flex {
    gap: 32px;
    margin-bottom: 78px;
  }
  .p-about-date .p-about-date__flex:nth-child(n+2) {
    margin: 80px 0 0 0;
  }
  .p-about-date .p-about-date__flex.-col2 .p-about-date__contents {
    width: calc(50% - 16px);
    padding: 24px 0 0 20px;
  }
  .p-about-date .p-about-date__flex.-col3 .p-about-date__contents {
    width: calc(33.33% - 21.3333333333px);
  }
  .p-about-date .p-about-date__contents {
    padding: 24px 20px 0 20px;
  }
  .p-about-date .p-about-date__contents-head {
    font-size: 1.25rem;
  }
  .p-about-date .p-about-date__contents-list {
    flex-direction: row;
    gap: 40px;
  }
  .p-about-date .p-about-date__contents-text {
    gap: 8px;
    font-size: 4rem;
  }
  .p-about-date .p-about-date__contents-text-side {
    font-size: 1.625rem;
  }
  .p-about-date .p-about-date__contents-text-small {
    font-size: 1.25rem;
  }
  .p-about-date .p-about-date__contents-item-add {
    margin-top: 10px;
  }
  .p-about-date .p-about-date__contents-detail {
    font-size: 0.875rem;
  }
}

/*  p-about-vision
---------------------------------------- */
.p-about-vision {
  padding: 50px 0;
}
@media all and ( 1008px <= width ) {
  .p-about-vision {
    padding: 100px 0;
  }
}

/*  p-about-message
---------------------------------------- */
.p-about-message {
  padding: 50px 0;
}
.p-about-message .p-about-message__img img {
  border-radius: 4px;
}
.p-about-message .p-about-message__textWrap {
  margin-top: 24px;
}
.p-about-message .p-about-message__text {
  color: #505050;
}
.p-about-message .p-about-message__nameWrap {
  margin-top: 20px;
  text-align: right;
}
.p-about-message .p-about-message__duty {
  font-size: 0.8125rem;
}
.p-about-message .p-about-message__name {
  width: 107px;
  margin: 14px 0 0 auto;
}
@media all and ( 1008px <= width ) {
  .p-about-message {
    padding: 100px 0;
  }
  .p-about-message .p-about-message__textWrap {
    margin-top: 55px;
  }
  .p-about-message .p-about-message__text + .p-about-message__text {
    margin-top: 16px;
  }
  .p-about-message .p-about-message__nameWrap {
    margin-top: 45px;
  }
  .p-about-message .p-about-message__duty {
    font-size: 0.875rem;
  }
  .p-about-message .p-about-message__name {
    width: 128px;
    margin: 10px 0 0 auto;
  }
}

/*  p-about-company
---------------------------------------- */
.p-about-company {
  padding: 50px 0;
}
.p-about-company .p-about-company__dl-contents:nth-child(n+2) {
  margin-top: 14px;
}
.p-about-company .p-about-company__dl-contents dt {
  margin-bottom: 3px;
  font-weight: 700;
}
@media all and ( 1008px <= width ) {
  .p-about-company {
    padding: 100px 0;
  }
  .p-about-company .p-about-company__table th {
    width: 28%;
  }
  .p-about-company .p-about-company__dl-contents:nth-child(n+2) {
    margin-top: 20px;
  }
}
@media all and ( 1640px < width ) {
  .p-about-company .p-about-company__table th {
    width: 15%;
  }
}

/*  p-about-environment
---------------------------------------- */
.p-about-environment {
  padding: 50px 0;
}
.p-about-environment .p-about-environment__contents:nth-of-type(n+2) {
  margin-top: 40px;
}
.p-about-environment .p-about-environment__contents-text {
  color: #505050;
}
.p-about-environment .p-about-environment__contents-box {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  padding: 10px 10px 24px 10px;
  background: #F5F5F5;
  border-radius: 4px;
}
.p-about-environment .p-about-environment__contents-box-img {
  width: 100%;
  padding: 45px;
  background: #fff;
  border-radius: 4px;
}
.p-about-environment .p-about-environment__contents-box-img img {
  display: block;
  width: 151px;
  margin: auto;
}
.p-about-environment .p-about-environment__contents-textWrap {
  padding: 0 10px;
}
.p-about-environment .p-about-environment__contents-box-title {
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.6;
}
.p-about-environment .p-about-environment__contents-img {
  margin-top: 20px;
}
.p-about-environment .p-about-environment__contents-list {
  margin-top: 32px;
}
.p-about-environment .p-about-environment__contents-item {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 32px 10px;
  border-top: 1px solid #DBDBDB;
}
.p-about-environment .p-about-environment__contents-item:last-child {
  border-bottom: 1px solid #DBDBDB;
}
.p-about-environment .p-about-environment__contents-item-img {
  width: 110px;
  margin: 0 auto;
}
.p-about-environment .p-about-environment__contents-item-textWrap {
  width: 100%;
}
.p-about-environment .p-about-environment__contents-item-title {
  text-align: center;
  margin-bottom: 14px;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1.125rem;
}
.p-about-environment .p-about-environment__contents-item-text {
  color: #505050;
}
.p-about-environment .p-about-environment__contents-item-box {
  margin-top: 14px;
  padding: 14px 18px;
  background: #F5F5F5;
  border-radius: 4px;
}
@media all and ( 1008px <= width ) {
  .p-about-environment {
    padding: 100px 0;
  }
  .p-about-environment .p-about-environment__contents:nth-of-type(n+2) {
    margin-top: 80px;
  }
  .p-about-environment .p-about-environment__contents-box {
    margin-top: 24px;
  }
  .p-about-environment .p-about-environment__contents-box-img {
    padding: 56px;
  }
  .p-about-environment .p-about-environment__contents-box-img img {
    width: 188px;
  }
  .p-about-environment .p-about-environment__contents-img {
    margin-top: 45px;
  }
  .p-about-environment .p-about-environment__contents-list {
    margin-top: 50px;
  }
  .p-about-environment .p-about-environment__contents-item {
    gap: 30px;
    padding: 50px 0;
  }
  .p-about-environment .p-about-environment__contents-item-img {
    width: 130px;
  }
  .p-about-environment .p-about-environment__contents-item-textWrap {
    width: calc(100% - 160px);
  }
  .p-about-environment .p-about-environment__contents-item-title {
    text-align: left;
    margin-bottom: 18px;
    font-size: 1.25rem;
  }
  .p-about-environment .p-about-environment__contents-item-box {
    margin-top: 14px;
    padding: 18px 26px;
  }
}
@media all and ( 1640px < width ) {
  .p-about-environment .p-about-environment__contents-box {
    gap: 60px;
    align-items: center;
    padding: 16px 60px 16px 16px;
  }
  .p-about-environment .p-about-environment__contents-box-img {
    width: 393px;
  }
  .p-about-environment .p-about-environment__contents-textWrap {
    width: calc(100% - 453px);
    padding: 0;
  }
  .p-about-environment .p-about-environment__contents-box-title {
    margin-bottom: 12px;
  }
  .p-about-environment .p-about-environment__contents-img {
    padding: 0 120px;
  }
  .p-about-environment .p-about-environment__contents-item {
    gap: 60px;
    padding: 50px 40px;
  }
  .p-about-environment .p-about-environment__contents-item-img {
    width: 160px;
  }
  .p-about-environment .p-about-environment__contents-item-textWrap {
    width: calc(100% - 220px);
  }
}

/*  p-about-certification
---------------------------------------- */
.p-about-certification {
  padding: 50px 0 100px;
}
.p-about-certification .p-about-certification__table-body {
  width: max(100%, 709px);
}
.p-about-certification .p-about-certification__table-th.-width01 {
  width: 90px;
}
.p-about-certification .p-about-certification__table-th.-width02 {
  width: 125px;
}
.p-about-certification .scroll-hint-icon-wrap {
  top: -25%;
}
@media all and ( 768px <= width ) {
  .p-about-certification .p-about-certification__table {
    width: 100%;
  }
}
@media all and ( 1008px <= width ) {
  .p-about-certification {
    padding: 100px 0 200px;
  }
  .p-about-certification .p-about-certification__table-body {
    width: max(100%, 940px);
  }
  .p-about-certification .p-about-certification__table-th.-width01 {
    width: 140px;
  }
  .p-about-certification .p-about-certification__table-th.-width02 {
    width: 180px;
  }
}
@media all and ( 1640px < width ) {
  .p-about-certification .p-about-certification__table-th.-width01 {
    width: 170px;
  }
  .p-about-certification .p-about-certification__table-th.-width02 {
    width: 220px;
  }
}

/*  p-recruit
---------------------------------------- */
.p-recruit .p-recruit__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-recruit .p-recruit__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-recruit .p-recruit__contents {
  padding-top: 60px;
}
@media all and ( 1008px <= width ) {
  .p-recruit .p-recruit__contents {
    padding-top: calc(200px - var(--headerH));
  }
}

/*  p-recruit-about
---------------------------------------- */
.p-recruit-about {
  padding-bottom: 50px;
}
.p-recruit-about .p-recruit-about__cardWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.p-recruit-about .p-recruit-about__card {
  width: 100%;
  padding: 40px 32px;
  background: #F5F5F5;
  border-radius: 4px;
}
.p-recruit-about .p-recruit-about__card-icon {
  width: 40px;
  margin-bottom: 24px;
}
.p-recruit-about .p-recruit-about__card-title {
  font-weight: 700;
  line-height: 1.6;
  font-size: 1.125rem;
  line-height: 1.4;
}
.p-recruit-about .p-recruit-about__card-text {
  margin-top: 14px;
  color: #505050;
}
.p-recruit-about .p-recruit-about__img {
  margin-top: 40px;
}
.p-recruit-about .p-recruit-about__img img {
  border-radius: 4px;
}
.p-recruit-about .p-recruit-about__contents {
  margin-top: 40px;
}
.p-recruit-about .p-recruit-about__contents-text {
  color: #505050;
}
.p-recruit-about .p-recruit-about__list {
  margin-top: 20px;
}
@media all and ( 1008px <= width ) {
  .p-recruit-about {
    padding-bottom: 100px;
  }
  .p-recruit-about .p-recruit-about__card {
    width: calc(50% - 4px);
  }
  .p-recruit-about .p-recruit-about__card-icon {
    width: 50px;
    margin-bottom: 32px;
  }
  .p-recruit-about .p-recruit-about__card-text {
    margin-top: 20px;
  }
  .p-recruit-about .p-recruit-about__img {
    margin-top: 60px;
  }
  .p-recruit-about .p-recruit-about__contents {
    margin-top: 60px;
  }
  .p-recruit-about .p-recruit-about__list {
    margin-top: 32px;
  }
}
@media all and ( 1640px < width ) {
  .p-recruit-about .p-recruit-about__cardWrap {
    gap: 16px;
  }
  .p-recruit-about .p-recruit-about__card {
    width: calc(50% - 8px);
    padding: 60px 80px;
  }
  .p-recruit-about .p-recruit-about__card-title {
    font-size: 1.25rem;
  }
}

/*  p-recruit-date
---------------------------------------- */
.p-recruit-date {
  padding: 50px 0;
}
.p-recruit-date .p-recruit-date__container {
  display: grid;
  gap: 32px;
}
.p-recruit-date .p-recruit-date__contents {
  padding: 16px 10px 0 10px;
  border-top: 1px solid #1A1A1A;
}
.p-recruit-date .p-recruit-date__contents-head {
  position: relative;
  padding-left: 23px;
  font-weight: 700;
  line-height: 1.6;
  line-height: 1.2;
}
.p-recruit-date .p-recruit-date__contents-head-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
}
.p-recruit-date .p-recruit-date__contents-body {
  text-align: right;
  margin-top: 16px;
}
.p-recruit-date .p-recruit-date__contents-text {
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 2.8125rem;
  letter-spacing: 0;
}
.p-recruit-date .p-recruit-date__contents-text-side {
  margin-left: 4px;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1.0625rem;
}
.p-recruit-date .p-recruit-date__contents-img {
  width: 232px;
  margin: 0 0 0 auto;
}
@media all and ( 1008px <= width ) {
  .p-recruit-date {
    padding: 100px 0;
  }
  .p-recruit-date .p-recruit-date__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 85px 16px;
  }
  .p-recruit-date .p-recruit-date__container .p-recruit-date__contents:nth-child(1) {
    grid-area: 1/1/2/2;
  }
  .p-recruit-date .p-recruit-date__container .p-recruit-date__contents:nth-child(2) {
    grid-area: 2/1/3/2;
  }
  .p-recruit-date .p-recruit-date__container .p-recruit-date__contents:nth-child(3) {
    grid-area: 1/2/3/3;
    padding: 26px 0 0 20px;
  }
  .p-recruit-date .p-recruit-date__contents {
    padding: 26px 20px 0 20px;
  }
  .p-recruit-date .p-recruit-date__contents-body {
    margin-top: 32px;
  }
  .p-recruit-date .p-recruit-date__contents-text {
    font-size: 3.5rem;
  }
  .p-recruit-date .p-recruit-date__contents-text-side {
    margin-left: 8px;
    font-size: 1.25rem;
  }
  .p-recruit-date .p-recruit-date__contents-img {
    width: min(100%, 336px);
  }
}
@media all and ( 1640px < width ) {
  .p-recruit-date .p-recruit-date__container {
    gap: 85px 32px;
  }
  .p-recruit-date .p-recruit-date__contents-head {
    padding-left: 41px;
    font-size: 1.25rem;
  }
  .p-recruit-date .p-recruit-date__contents-head-icon {
    width: 27px;
  }
  .p-recruit-date .p-recruit-date__contents-text {
    font-size: 4rem;
  }
  .p-recruit-date .p-recruit-date__contents-text-side {
    font-size: 1.625rem;
  }
}

/*  p-recruit-works
---------------------------------------- */
.p-recruit-works {
  padding: 50px 0;
}
.p-recruit-works .p-recruit-works__contents:nth-of-type(n+2) {
  margin-top: 40px;
}
.p-recruit-works .p-recruit-works__contents-box {
  margin-top: 20px;
  padding: 24px;
  background: #F5F5F5;
  border-radius: 4px;
}
.p-recruit-works .p-recruit-works__contents-text {
  margin-top: 20px;
  color: #505050;
}
@media all and ( 1008px <= width ) {
  .p-recruit-works {
    padding: 100px 0;
  }
  .p-recruit-works .p-recruit-works__contents:nth-of-type(n+2) {
    margin-top: 80px;
  }
  .p-recruit-works .p-recruit-works__contents-box {
    margin-top: 32px;
    padding: 40px;
  }
  .p-recruit-works .p-recruit-works__contents-text {
    margin-top: 32px;
  }
  .p-recruit-works .p-recruit-works__contents-text + .p-recruit-works__contents-box {
    margin-top: 24px;
  }
}
@media all and ( 1640px < width ) {
  .p-recruit-works .p-recruit-works__contents-box {
    padding: 50px 60px;
  }
}

/*  p-recruit-system
---------------------------------------- */
.p-recruit-system {
  padding: 50px 0;
}
.p-recruit-system .p-recruit-system__flex {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.p-recruit-system .p-recruit-system__flex:nth-of-type(n+2) {
  margin-top: 32px;
}
.p-recruit-system .p-recruit-system__flex.-col4 {
  gap: 32px 8px;
}
.p-recruit-system .p-recruit-system__flex.-col4 .p-recruit-system__contents {
  width: calc(50% - 4px);
  border-top: 1px solid #DBDBDB;
}
.p-recruit-system .p-recruit-system__contents {
  width: 100%;
  padding: 16px 10px 0 10px;
  border-top: 1px solid #1A1A1A;
}
.p-recruit-system .p-recruit-system__contents-head {
  font-weight: 700;
  line-height: 1.6;
}
.p-recruit-system .p-recruit-system__contents-text {
  margin-top: 3px;
  color: #505050;
  font-size: 0.8125rem;
}
.p-recruit-system .p-recruit-system__contents-icon {
  margin: 16px 0 0 auto;
  width: 40px;
}
.p-recruit-system .p-recruit-system__gallery {
  display: flex;
  width: calc(100vw - 20px);
  margin: 60px -20px 0 -120px;
}
.p-recruit-system .p-recruit-system__gallery-img {
  padding-right: 8px;
}
.p-recruit-system .p-recruit-system__gallery-img:first-child {
  animation: loop 50s -25s linear infinite;
}
.p-recruit-system .p-recruit-system__gallery-img:last-child {
  animation: loop2 50s linear infinite;
}
.p-recruit-system .p-recruit-system__gallery-img img {
  width: auto;
  height: 164px;
}
@media all and ( 768px <= width ) {
  .p-recruit-system .p-recruit-system__gallery {
    width: calc(100vw - 120px);
    margin-right: -120px;
  }
}
@media all and ( 1008px <= width ) {
  .p-recruit-system {
    padding: 100px 0;
  }
  .p-recruit-system .p-recruit-system__flex {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .p-recruit-system .p-recruit-system__flex.-col3 .p-recruit-system__contents {
    width: calc(50% - 8px);
  }
  .p-recruit-system .p-recruit-system__flex.-col4 .p-recruit-system__contents {
    width: calc(50% - 8px);
  }
  .p-recruit-system .p-recruit-system__contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 24px 20px 0 20px;
  }
  .p-recruit-system .p-recruit-system__contents-head {
    font-size: 1.25rem;
  }
  .p-recruit-system .p-recruit-system__contents-text {
    margin-top: 5px;
    font-size: 0.875rem;
  }
  .p-recruit-system .p-recruit-system__contents-icon {
    margin: 0 0 0 auto;
    width: 60px;
  }
  .p-recruit-system .p-recruit-system__gallery {
    margin: 100px 0 0 0;
    overflow: hidden;
  }
  .p-recruit-system .p-recruit-system__gallery-img {
    padding-right: 16px;
  }
  .p-recruit-system .p-recruit-system__gallery-img img {
    height: 300px;
  }
}
@media all and ( 1640px < width ) {
  .p-recruit-system .p-recruit-system__flex {
    display: flex;
    flex-wrap: wrap;
    gap: 80px 32px;
  }
  .p-recruit-system .p-recruit-system__flex:nth-of-type(n+2) {
    margin-top: 80px;
  }
  .p-recruit-system .p-recruit-system__flex.-col3 .p-recruit-system__contents {
    width: calc(33.33% - 21.3333333333px);
  }
  .p-recruit-system .p-recruit-system__flex.-col4 {
    gap: 80px 32px;
  }
  .p-recruit-system .p-recruit-system__flex.-col4 .p-recruit-system__contents {
    width: calc(25% - 24px);
  }
  .p-recruit-system .p-recruit-system__gallery-img img {
    height: 386px;
  }
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}
/*  p-recruit-recruitment
---------------------------------------- */
.p-recruit-recruitment {
  padding: 50px 0 100px;
}
.p-recruit-recruitment .p-recruit-recruitment__contents:nth-of-type(n+2) {
  margin-top: 40px;
}
@media all and ( 1008px <= width ) {
  .p-recruit-recruitment {
    padding: 100px 0 200px;
  }
  .p-recruit-recruitment .p-recruit-recruitment__contents:nth-of-type(n+2) {
    margin-top: 80px;
  }
}

/*  p-requirements
---------------------------------------- */
.p-requirements {
  padding-bottom: 100px;
  background: #fff;
}
.p-requirements .p-requirements__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-requirements .p-requirements__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-requirements .p-requirements__contents {
  padding-top: 60px;
}
.p-requirements .p-requirements-gallery {
  position: relative;
  overflow: auto;
  width: calc(100vw - 20px);
  margin-top: 40px;
}
.p-requirements .p-requirements-gallery__imgWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  width: max(100%, 680px);
}
.p-requirements .p-requirements-gallery__img {
  overflow: hidden;
  border-radius: 4px;
}
@media all and ( 1008px <= width ) {
  .p-requirements {
    padding-bottom: 240px;
  }
  .p-requirements .p-requirements__contents {
    padding-top: calc(200px - var(--headerH));
  }
  .p-requirements .p-requirements-gallery {
    width: 100%;
    margin-top: 60px;
  }
  .p-requirements .p-requirements-gallery__imgWrap {
    gap: 16px;
  }
}
@media all and ( 1640px < width ) {
  .p-requirements .p-requirements__contents {
    margin-right: 136px;
  }
}

/*  p-requirements-sec
---------------------------------------- */
.p-requirements-sec:nth-of-type(n+2) {
  padding-top: 40px;
}
.p-requirements-sec .p-requirements-sec__tagWrap {
  margin-bottom: 16px;
}
.p-requirements-sec .p-requirements-sec__contents > * + * {
  margin-top: 1.6em;
}
@media all and ( 1008px <= width ) {
  .p-requirements-sec:nth-of-type(n+2) {
    padding-top: 60px;
  }
  .p-requirements-sec .p-requirements-sec__table th {
    width: 180px;
  }
}
@media all and ( 1640px < width ) {
  .p-requirements-sec .p-requirements-sec__table th {
    width: 256px;
  }
}

/*  p-info
----------------------------------- */
.p-info {
  padding: 100px 0;
}
.p-info .p-info__inner {
  width: min(100%, 1096px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-info .p-info__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-info .p-info__contents {
  border-top: 1px solid #1A1A1A;
}
@media all and ( 1008px <= width ) {
  .p-info {
    padding: 200px 0 240px;
  }
}

/*  p-info-single
----------------------------------- */
.p-info-single {
  padding: 60px 0 100px;
}
.p-info-single .p-info-single__inner {
  width: min(100%, 1096px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-info-single .p-info-single__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media all and ( 1008px <= width ) {
  .p-info-single {
    padding: 195px 0 240px;
  }
}

/*  p-products
---------------------------------------- */
.p-products .p-products__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-products .p-products__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-products .p-products__contents {
  padding-top: 60px;
}
@media all and ( 1008px <= width ) {
  .p-products .p-products__contents {
    padding-top: calc(200px - var(--headerH));
  }
}

/*  p-products-turnout
---------------------------------------- */
.p-products-turnout {
  padding-bottom: 50px;
}
.p-products-turnout .p-products-turnout__contents:nth-of-type(n+2) {
  margin-top: 40px;
}
@media all and ( 1008px <= width ) {
  .p-products-turnout {
    padding-bottom: 100px;
  }
  .p-products-turnout .p-products-turnout__contents:nth-of-type(n+2) {
    margin-top: 80px;
  }
}

/*  p-products-sec
---------------------------------------- */
.p-products-sec {
  padding: 50px 0;
}
@media all and ( 1008px <= width ) {
  .p-products-sec {
    padding: 100px 0;
  }
}

/*  p-products-plan
---------------------------------------- */
.p-products-plan {
  padding: 50px 0;
}
@media all and ( 1008px <= width ) {
  .p-products-plan {
    padding: 100px 0;
  }
}

/*  p-products-gallery
---------------------------------------- */
.p-products-gallery {
  padding: 50px 0 100px;
}
@media all and ( 1008px <= width ) {
  .p-products-gallery {
    padding: 100px 0 240px;
  }
}

/*  p-products-parts
---------------------------------------- */
.p-products-parts {
  padding-bottom: 80px;
}
.p-products-parts .p-products-parts-anchor {
  padding: 60px 0;
  background: #F5F5F5;
}
.p-products-parts .p-products-parts-anchor__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-products-parts .p-products-parts-anchor__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-products-parts .p-products-parts-container {
  padding-top: 60px;
}
.p-products-parts .p-products-parts-container__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-products-parts .p-products-parts-container__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-products-parts .p-products-parts-container__sec:nth-of-type(n+2) {
  padding-top: 40px;
}
@media all and ( 1008px <= width ) {
  .p-products-parts {
    padding-bottom: 240px;
  }
  .p-products-parts .p-products-parts-anchor {
    padding: 100px 0;
  }
  .p-products-parts .p-products-parts-container {
    padding-top: 100px;
  }
  .p-products-parts .p-products-parts-container__sec:nth-of-type(n+2) {
    padding-top: 80px;
  }
}
@media all and ( 1640px < width ) {
  .p-products-parts .p-products-parts-anchor__inner {
    width: min(100%, 1272px);
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media all and ( 1640px < width ) and (768px <= width < 1640px) {
  .p-products-parts .p-products-parts-anchor__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}

/*  p-contact
----------------------------------- */
.p-contact {
  padding: 60px 0 100px;
}
.p-contact .p-contact__inner {
  width: min(100%, 1096px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-contact .p-contact__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-contact .p-contact__intro {
  padding: 40px 0;
  border-top: 1px solid #DBDBDB;
  border-bottom: 1px solid #DBDBDB;
}
.p-contact .p-contact__intro-ttlArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.p-contact .p-contact__intro-ico {
  width: 28px;
}
.p-contact .p-contact__intro-ttl {
  font-size: 1.625rem;
}
.p-contact .p-contact__intro-note {
  padding-top: 20px;
}
.p-contact .p-contact__intro-note-item {
  font-size: 0.8125rem;
  color: #777;
}
.p-contact .p-contact__intro-tel {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 8px;
}
.p-contact .p-contact__intro-tel-link {
  display: block;
  padding: 16px;
  text-align: center;
  background: #F5F5F5;
  border-radius: 4px;
  transition: 0.5s cubic-bezier(0.71, 0.41, 0.37, 0.72);
  transition-property: all;
}
.p-contact .p-contact__intro-tel-link:hover, .p-contact .p-contact__intro-tel-link:focus-visible {
  opacity: 0.5;
}
.p-contact .p-contact__intro-tel-en {
  font-size: 0.8125rem;
}
.p-contact .p-contact__intro-tel-txt {
  display: inline-block;
  padding-left: 16px;
  font-size: 1.25rem;
  font-family: "Oxygen", "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  letter-spacing: 0.08em;
  line-height: 1.1;
  text-transform: uppercase;
}
.p-contact .p-contact-sec {
  padding-top: 100px;
}
.p-contact .p-contact__form-contents {
  margin: 0 -20px;
}
@media all and ( 1008px <= width ) {
  .p-contact {
    padding: 200px 0 240px;
  }
  .p-contact .p-contact__intro {
    padding: 80px 35px;
  }
  .p-contact .p-contact__intro-ttlArea {
    flex-direction: row;
    gap: 20px;
  }
  .p-contact .p-contact__intro-ico {
    width: 38px;
  }
  .p-contact .p-contact__intro-ttl {
    width: calc(100% - 58px);
    font-size: 2.5rem;
  }
  .p-contact .p-contact__intro-note {
    padding-top: 14px;
  }
  .p-contact .p-contact__intro-tel {
    flex-direction: row;
    padding-top: 22px;
    gap: 32px;
  }
  .p-contact .p-contact__intro-tel-link {
    padding: 20px;
    width: calc(50% - 16px);
  }
  .p-contact .p-contact__intro-tel-en {
    font-size: 1.25rem;
  }
  .p-contact .p-contact__intro-tel-txt {
    padding-left: 24px;
    font-size: 1.75rem;
  }
  .p-contact .p-contact-sec {
    padding-top: 200px;
  }
  .p-contact .p-contact__form-contents {
    margin: 0;
  }
}

/*  p-business
----------------------------------- */
.p-business {
  padding-top: 60px;
}
.p-business .p-business__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-business .p-business__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-business .p-business__contents-item {
  padding: 50px 0;
}
.p-business .p-business__contents-item:first-child {
  padding: 0 0 50px;
}
.p-business .p-business__contents-item:last-child {
  padding: 50px 0 100px;
}
.p-business .p-business__contents-txt {
  padding-bottom: 24px;
  color: #505050;
}
@media all and ( 1008px <= width ) {
  .p-business {
    padding-top: calc(200px - var(--headerH));
  }
  .p-business .p-business__contents-item {
    padding: 100px 0;
  }
  .p-business .p-business__contents-item:first-child {
    padding: 0 0 100px;
  }
  .p-business .p-business__contents-item:last-child {
    padding: 100px 0 240px;
  }
  .p-business .p-business__contents-txt {
    padding-bottom: 30px;
  }
}

/*  p-products-other
---------------------------------------- */
.p-products-other {
  padding: 100px 0;
}
.p-products-other .p-products-other__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-products-other .p-products-other__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-products-other .p-products-other__titleWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 40px;
}
.p-products-other .p-products-other__title {
  width: 100%;
  margin: 0;
}
.p-products-other .p-products-other__btn {
  margin: 0 auto;
}
@media all and ( 1008px <= width ) {
  .p-products-other {
    padding: 200px 0;
  }
  .p-products-other .p-products-other__titleWrap {
    margin-bottom: 56px;
  }
  .p-products-other .p-products-other__title {
    width: fit-content;
    margin: 0;
  }
  .p-products-other .p-products-other__btn {
    margin: 0 0 0 auto;
  }
}

/*  p-history
----------------------------------- */
.p-history {
  padding: 60px 0 100px;
}
.p-history .p-history__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-history .p-history__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-history .p-history__contents-ttlArea {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.p-history .p-history__contents-ttl-logo {
  width: 17px;
}
.p-history .p-history__contents-ttl {
  display: flex;
  padding: 8px;
  width: calc(50% - 5px);
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: #F5F5F5;
}
.p-history .p-history__contents-ttl-item {
  font-size: 1rem;
}
.p-history .p-history__contents-itemWrapper {
  position: relative;
}
.p-history .p-history__contents-bg {
  position: absolute;
  display: flex;
  width: 100%;
  column-gap: 40px;
  top: 110px;
}
.p-history .p-history__contents-bg-img {
  width: calc(50% - 20px);
}
.p-history .p-history__contents-bg-img img {
  display: block;
  margin: 0 auto;
  width: 70%;
  aspect-ratio: 1/1;
}
.p-history .p-history__contents-item {
  padding-top: 40px;
}
.p-history .p-history__contents-item-ttl {
  font-size: 1rem;
  text-align: center;
}
.p-history .p-history__contents-child {
  position: relative;
  margin-top: 20px;
  padding-top: 20px;
}
.p-history .p-history__contents-child::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: #DBDBDB;
  z-index: -1;
}
.p-history .p-history__heisei-list {
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  gap: 12px;
}
.p-history .p-history__heisei-img-txt {
  padding-top: 8px;
  color: #505050;
  font-size: 0.8125rem;
}
.p-history .p-history__contents-child-txt,
.p-history .p-history__contents-child-img-txt {
  padding-top: 8px;
  color: #505050;
}
.p-history .p-history__contents-child-img-txt {
  font-size: 0.8125rem;
}
.p-history .p-history__contents-child-img {
  margin-top: 12px;
}
.p-history .p-history__contents-child-img img {
  border-radius: 4px;
}
.p-history .p-history__contents-item-row {
  display: flex;
}
.p-history .p-history__contents-child-right,
.p-history .p-history__contents-child-left {
  position: relative;
  margin-left: auto;
  width: 50%;
  border-top: 1px solid #DBDBDB;
}
.p-history .p-history__contents-child-right::before,
.p-history .p-history__contents-child-left::before {
  content: "";
  position: absolute;
  display: block;
  top: -5px;
  width: 9px;
  height: 9px;
  background: #4D1798;
  border-radius: 50%;
}
.p-history .p-history__contents-child-right {
  padding: 17px 0 24px 20px;
}
.p-history .p-history__contents-child-right::before {
  left: -4px;
}
.p-history .p-history__contents-child-left {
  margin-right: auto;
  margin-left: 0;
  padding: 12px 20px 24px 0;
}
.p-history .p-history__contents-child-left::before {
  right: -5px;
}
@media all and ( 1008px <= width ) {
  .p-history {
    padding: 200px 0 240px;
  }
  .p-history .p-history__contents-ttlArea {
    gap: 32px;
  }
  .p-history .p-history__contents-ttl-logo {
    width: 36px;
  }
  .p-history .p-history__contents-ttl {
    padding: 16px;
    width: calc(50% - 16px);
    gap: 12px;
  }
  .p-history .p-history__contents-ttl-item {
    font-size: 1.5rem;
  }
  .p-history .p-history__contents-bg {
    column-gap: 120px;
    top: 180px;
  }
  .p-history .p-history__contents-bg-img {
    width: calc(50% - 60px);
  }
  .p-history .p-history__contents-bg-img img {
    width: min(100%, 400px);
  }
  .p-history .p-history__contents-item {
    padding-top: 50px;
  }
  .p-history .p-history__contents-item-ttl {
    font-size: 2rem;
  }
  .p-history .p-history__contents-child {
    margin-top: 40px;
    padding: 40px 0 0;
  }
  .p-history .p-history__contents-child::before {
    width: 2px;
  }
  .p-history .p-history__heisei-list {
    padding-top: 16px;
    flex-direction: row;
    gap: 16px;
  }
  .p-history .p-history__heisei-img-txt,
.p-history .p-history__contents-child-img-txt {
    font-size: 0.875rem;
  }
  .p-history .p-history__contents-child-txt,
.p-history .p-history__contents-child-img-txt {
    padding-top: 0;
  }
  .p-history .p-history__contents-child-img {
    margin-top: 16px;
    width: min(100%, 250px);
  }
  .p-history .p-history__contents-child-img.-pcSmall {
    width: 160px;
  }
}
@media all and ( 1640px < width ) {
  .p-history .p-history__contents-ttl-item {
    font-size: 2rem;
  }
  .p-history .p-history__contents-child-img {
    padding-top: 16px;
    width: min(100%, 250px);
  }
  .p-history .p-history__contents-child-img.-pcSmall {
    width: 160px;
  }
}
@media all and ( 1640px < width ) {
  .p-history .p-history__contents-ttl-item {
    font-size: 2rem;
  }
  .p-history .p-history__contents-item:has(.p-history__contents-child) .p-history__contents-child-right,
.p-history .p-history__contents-item:has(.p-history__contents-child) .p-history__contents-child-left {
    display: grid;
    grid-template: "ttl txt" auto ". img" 1fr/70px auto;
    column-gap: 24px;
  }
  .p-history .p-history__contents-child-right::before,
.p-history .p-history__contents-child-left::before {
    width: 12px;
    height: 12px;
  }
  .p-history .p-history__contents-child-right {
    padding: 17px 0 32px 60px;
  }
  .p-history .p-history__contents-child-right::before {
    left: -5px;
  }
  .p-history .p-history__contents-child-left {
    padding: 17px 60px 32px 0;
  }
  .p-history .p-history__contents-child-left::before {
    right: -7px;
  }
  .p-history .p-history__contents-child-ttl {
    grid-area: ttl;
  }
  .p-history .p-history__contents-child-txt {
    grid-area: txt;
  }
  .p-history .p-history__contents-child-img,
.p-history .p-history__heisei-list {
    grid-area: img;
  }
}

.p-history .p-history__contents-child-item.-row {
  display: flex;
}

.p-history .p-history__heisei-item-img.-hasBorder,
.p-history .p-history__contents-child-img.-hasBorder {
  border-radius: 4px;
  overflow: hidden;
}

/*  p-products-single-contents
---------------------------------------- */
.p-products-single-contents {
  padding: 60px 0;
  background: #F5F5F5;
}
.p-products-single-contents .p-products-single-contents__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-products-single-contents .p-products-single-contents__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-products-single-contents .p-products-single-contents__body {
  display: grid;
  gap: 40px;
}
.p-products-single-contents .p-products-single-contents__imgWrap {
  width: 100%;
}
.p-products-single-contents .p-products-single-contents__mainImg img {
  width: 100%;
  height: 100%;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  aspect-ratio: 760/410;
  object-fit: cover;
  background: #fff;
}
.p-products-single-contents .p-products-single-contents__subImg-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  margin-top: 4px;
}
.p-products-single-contents .p-products-single-contents__subImg {
  cursor: pointer;
}
.p-products-single-contents .p-products-single-contents__subImg.-current {
  position: relative;
}
.p-products-single-contents .p-products-single-contents__subImg.-current::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: #1A1A1A;
  border-radius: 2px;
  z-index: 1;
}
.p-products-single-contents .p-products-single-contents__subImg {
  display: block;
}
.p-products-single-contents .p-products-single-contents__subImg img {
  border: 1px solid #DBDBDB;
  border-radius: 2px;
}
.p-products-single-contents .p-products-single-contents__title {
  margin-bottom: 36px;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1.625rem;
}
.p-products-single-contents .p-products-single-contents__detail {
  margin-top: 20px;
}
.p-products-single-contents .p-products-single-contents__detail-title {
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #DBDBDB;
  font-weight: 700;
  line-height: 1.6;
  font-size: 1rem;
}
.p-products-single-contents .p-products-single-contents__detail-text p {
  color: #505050;
}
.p-products-single-contents .p-products-single-contents__detail-text ul {
  list-style: none;
}
.p-products-single-contents .p-products-single-contents__detail-text ul li {
  position: relative;
  padding-left: 13px;
  color: #505050;
}
.p-products-single-contents .p-products-single-contents__detail-text ul li:nth-child(n+2) {
  margin-top: 3px;
}
.p-products-single-contents .p-products-single-contents__detail-text ul li::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  width: 6px;
  height: 2px;
  background: #4D1798;
}
.p-products-single-contents .p-products-single-contents__detail-text strong, .p-products-single-contents .p-products-single-contents__detail-text b {
  font-weight: 700;
}
.p-products-single-contents .p-products-single-contents__table td {
  background: #fff;
}
.p-products-single-contents .p-products-single-contents__table-body {
  width: max(100%, 1060px);
}
@media all and ( 768px <= width ) {
  .p-products-single-contents .p-products-single-contents__table {
    width: 100%;
  }
}
@media all and ( 1008px <= width ) {
  .p-products-single-contents {
    padding: 100px 0;
  }
  .p-products-single-contents .p-products-single-contents__body {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    gap: 60px 40px;
  }
  .p-products-single-contents .p-products-single-contents__imgWrap {
    grid-area: 1/1/2/2;
  }
  .p-products-single-contents .p-products-single-contents__subImg.-current::before {
    border-radius: 4px;
  }
  .p-products-single-contents .p-products-single-contents__textWrap {
    grid-area: 1/2/2/3;
  }
  .p-products-single-contents .p-products-single-contents__title {
    font-size: 2rem;
  }
  .p-products-single-contents .p-products-single-contents__tableWrap {
    grid-area: 2/1/3/3;
  }
  .p-products-single-contents .p-products-single-contents__detail-text ul li {
    padding-left: 18px;
  }
  .p-products-single-contents .p-products-single-contents__detail-text ul li::before {
    top: 13px;
    width: 8px;
  }
}
@media all and ( 1640px < width ) {
  .p-products-single-contents .p-products-single-contents__body {
    gap: 60px 80px;
  }
  .p-products-single-contents .p-products-single-contents__subImg-wrap {
    gap: 8px;
    margin-top: 8px;
  }
  .p-products-single-contents .p-products-single-contents__subImg img {
    border-radius: 4px;
  }
  .p-products-single-contents .p-products-single-contents__title {
    font-size: 2.5rem;
  }
  .p-products-single-contents .p-products-single-contents__detail {
    margin-top: 28px;
  }
  .p-products-single-contents .p-products-single-contents__detail-title {
    padding-bottom: 10px;
    font-size: 1.25rem;
  }
}

/*  p-products-single-other
---------------------------------------- */
.p-products-single-other {
  padding: 100px 0;
}
.p-products-single-other .p-products-single-other__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-products-single-other .p-products-single-other__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media all and ( 1008px <= width ) {
  .p-products-single-other {
    padding: 200px 0;
  }
  .p-products-single-other .p-products-single-other__title {
    margin-bottom: 70px;
  }
}
@media all and ( 1640px < width ) {
  .p-products-single-other .p-products-single-other__title {
    margin-bottom: 56px;
  }
}

/*  p-plan
---------------------------------------- */
.p-plan .p-plan__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-plan .p-plan__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-plan .p-plan__contents {
  padding-top: 60px;
}
@media all and ( 1008px <= width ) {
  .p-plan .p-plan__contents {
    padding-top: calc(200px - var(--headerH));
  }
}

/*  p-plan-sec
---------------------------------------- */
.p-plan-sec {
  padding: 50px 0;
}
.p-plan-sec:first-child {
  padding-top: 0;
}
.p-plan-sec:last-child {
  padding-bottom: 100px;
}
.p-plan-sec .p-plan-sec__list {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
}
.p-plan-sec .p-plan-sec__item {
  width: 100%;
}
@media all and ( 1008px <= width ) {
  .p-plan-sec {
    padding: 100px 0;
  }
  .p-plan-sec:last-child {
    padding-bottom: 200px;
  }
}
@media all and ( 1640px < width ) {
  .p-plan-sec .p-plan-sec__list {
    gap: 9px 60px;
  }
  .p-plan-sec .p-plan-sec__item {
    width: calc(50% - 30px);
  }
}

/*  p-gallery
---------------------------------------- */
.p-gallery .p-gallery__inner {
  width: min(100%, 1640px);
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (768px <= width < 1640px) {
  .p-gallery .p-gallery__inner {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }
}
.p-gallery .p-gallery__contents {
  padding-top: 60px;
}
@media all and ( 1008px <= width ) {
  .p-gallery .p-gallery__contents {
    padding-top: calc(200px - var(--headerH));
  }
}

/*  p-gallery-sec
---------------------------------------- */
.p-gallery-sec {
  padding: 50px 0;
}
.p-gallery-sec:first-child {
  padding-top: 0;
}
.p-gallery-sec:last-child {
  padding-bottom: 100px;
}
.p-gallery-sec .p-gallery-sec__list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.p-gallery-sec .p-gallery-sec__item {
  width: 100%;
}
.p-gallery-sec .p-gallery-sec__item-video-iframe {
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
}
.p-gallery-sec .p-gallery-sec__item-video-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.p-gallery-sec .p-gallery-sec__item-in {
  border-radius: 4px;
}
.p-gallery-sec .p-gallery-sec__item-title {
  margin-top: 16px;
  font-weight: 700;
  line-height: 1.6;
}
@media all and ( 1008px <= width ) {
  .p-gallery-sec {
    padding: 100px 0;
  }
  .p-gallery-sec:last-child {
    padding-bottom: 240px;
  }
  .p-gallery-sec .p-gallery-sec__list {
    gap: 40px 16px;
  }
  .p-gallery-sec .p-gallery-sec__item {
    width: calc(50% - 8px);
  }
}
@media all and ( 1640px < width ) {
  .p-gallery-sec .p-gallery-sec__list {
    gap: 60px 32px;
  }
  .p-gallery-sec .p-gallery-sec__item {
    width: calc(50% - 16px);
  }
  .p-gallery-sec .p-gallery-sec__item-title {
    margin-top: 18px;
    font-size: 1.125rem;
  }
}

.js-text-split {
  display: inline-block;
  overflow: hidden;
}
.js-text-split span:not([class]) {
  display: inline-block;
  opacity: 0;
  translate: 0 60%;
  transition: translate 0.6s ease-in-out, opacity 0.8s ease-out;
}
.js-text-split span:not([class]):nth-of-type(1) {
  transition-delay: 0.05s;
}
.js-text-split span:not([class]):nth-of-type(2) {
  transition-delay: 0.1s;
}
.js-text-split span:not([class]):nth-of-type(3) {
  transition-delay: 0.15s;
}
.js-text-split span:not([class]):nth-of-type(4) {
  transition-delay: 0.2s;
}
.js-text-split span:not([class]):nth-of-type(5) {
  transition-delay: 0.25s;
}
.js-text-split span:not([class]):nth-of-type(6) {
  transition-delay: 0.3s;
}
.js-text-split span:not([class]):nth-of-type(7) {
  transition-delay: 0.35s;
}
.js-text-split span:not([class]):nth-of-type(8) {
  transition-delay: 0.4s;
}
.js-text-split span:not([class]):nth-of-type(9) {
  transition-delay: 0.45s;
}
.js-text-split span:not([class]):nth-of-type(10) {
  transition-delay: 0.5s;
}
.js-text-split span:not([class]):nth-of-type(11) {
  transition-delay: 0.55s;
}
.js-text-split span:not([class]):nth-of-type(12) {
  transition-delay: 0.6s;
}
.js-text-split span:not([class]):nth-of-type(13) {
  transition-delay: 0.65s;
}
.js-text-split span:not([class]):nth-of-type(14) {
  transition-delay: 0.7s;
}
.js-text-split span:not([class]):nth-of-type(15) {
  transition-delay: 0.75s;
}
.js-text-split span:not([class]):nth-of-type(16) {
  transition-delay: 0.8s;
}
.js-text-split span:not([class]):nth-of-type(17) {
  transition-delay: 0.85s;
}
.js-text-split span:not([class]):nth-of-type(18) {
  transition-delay: 0.9s;
}
.js-text-split span:not([class]):nth-of-type(19) {
  transition-delay: 0.95s;
}
.js-text-split span:not([class]):nth-of-type(20) {
  transition-delay: 1s;
}
.js-text-split span:not([class]):nth-of-type(21) {
  transition-delay: 1.05s;
}
.js-text-split span:not([class]):nth-of-type(22) {
  transition-delay: 1.1s;
}
.js-text-split span:not([class]):nth-of-type(23) {
  transition-delay: 1.15s;
}
.js-text-split span:not([class]):nth-of-type(24) {
  transition-delay: 1.2s;
}
.js-text-split span:not([class]):nth-of-type(25) {
  transition-delay: 1.25s;
}
.js-text-split span:not([class]):nth-of-type(26) {
  transition-delay: 1.3s;
}
.js-text-split span:not([class]):nth-of-type(27) {
  transition-delay: 1.35s;
}
.js-text-split span:not([class]):nth-of-type(28) {
  transition-delay: 1.4s;
}
.js-text-split span:not([class]):nth-of-type(29) {
  transition-delay: 1.45s;
}
.js-text-split span:not([class]):nth-of-type(30) {
  transition-delay: 1.5s;
}
.js-text-split.is-active span:not([class]) {
  opacity: 1;
  translate: 0 0;
}