@use '../../fundation/index' as *;

/*  c-hero01
---------------------------------------- */
.c-hero01{
	position: relative;
	padding-top: 90px;
	.c-hero01__inner{
		position: relative;
		@include inner;
	}
	.c-hero01__title{
		margin-bottom: 45px;
		@include font_title;
		font-size: font-size(36);
		letter-spacing: 0.06em;
	}
	.c-hero01__logo{
		position: absolute;
		top: -56px;
		right: -79vw;
		width: 188vw;
		z-index: -1;
	}
	@include mq (medium){
		.c-hero01__logo{
			right: -40vw;
			width: 100vw;
		}
		.c-hero01__img{
			padding: 0 120px;
			img{
				border-radius: 4px;
			}
		}
	}
	@include mq (large){
		padding-top: 290px;
		.c-hero01__title{
			margin-bottom: 100px;
			font-size: font-size(56);
		}
		.c-hero01__logo{
			top: 0;
			right: -15vw;
			width: 1060px;
		}
		.c-hero01__img{
			padding: 0 80px;
		}
		.c-hero01__contents{
			margin-top: 24px;
		}
	}
	@include mq (full){
		.c-hero01__logo{
			right: 0;
		}
	}
}

/*  c-head02
---------------------------------------- */
.c-hero02{
	position: relative;
	padding-top: 90px;
	.c-hero02__inner{
		@include inner;
	}
	.c-hero02__title{
		margin-bottom: 45px;
		font-size: font-size(36);
		letter-spacing: 0.06em;
	}
	.c-hero02__logo{
		position: absolute;
		top: -56px;
		right: -79vw;
		width: 188vw;
		z-index: -1;
	}
	.c-hero02__body{
		margin-bottom: 30px;
	}
	.c-hero02__btnWrap{
		display: flex;
		flex-wrap: wrap;
	}
	.c-hero02__btn{
		width: 50%;
		&:nth-child(2n+1){
			.c-hero02__btn-link{
				border-radius: 4px 0 0 4px;
			}
		}
		&:nth-child(2n){
			.c-hero02__btn-link{
				border-radius: 0 4px 4px 0;
			}
		}
		&.-current{
			.c-hero02__btn-link{
				background: $clr_primary;
				color: #fff;
				border-color: $clr_primary;
			}
		}
	}
	.c-hero02__btn-link{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 12px;
		background: #fff;
		font-weight: 700;
		border: 1px solid $clr_border;
		@include trans-normal;
		&:hover,&:focus-visible{
			opacity: 0.5;
		}
	}
	@include mq (medium){
		.c-hero02__logo{
			right: -40vw;
			width: 100vw;
		}
		.c-hero02__img{
			padding: 0 120px;
			img{
				border-radius: 4px;
			}
		}
	}
	@include mq (large){
		padding-top: 290px;
		.c-hero02__title{
			margin-bottom: 100px;
			font-size: font-size(56);
		}
		.c-hero02__logo{
			top: 0;
			right: -15vw;
			width: 1060px;
		}
		.c-hero02__img{
			padding: 0 80px;
		}
		.c-hero02__body{
			display: flex;
			gap: 0 40px;
			margin-bottom: 0;
		}
		.c-hero02__contents{
			margin-top: 24px;
		}
		.c-hero02__btn{
			width: 214px;
			&:nth-child(2n+1){
				.c-hero02__btn-link{
					border-radius: 0;
				}
			}
			&:nth-child(2n){
				.c-hero02__btn-link{
					border-radius: 0;
				}
			}
			&:first-child{
				.c-hero02__btn-link{
					border-radius: 4px 0 0 4px;
				}
			}
			&:last-child{
				.c-hero02__btn-link{
					border-radius: 0 4px 4px 0;
				}
			}
		}
		.c-hero02__btn-link{
			font-size: font-size(18);
		}
	}
	@include mq (full){
		.c-hero02__logo{
			right: 0;
		}
		.c-hero02__body{
			gap: 0 76px;
		}
	}
}