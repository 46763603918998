@use '../../fundation/index' as *;

/*  p-plan
---------------------------------------- */
.p-plan{
	.p-plan__inner{
		@include inner;
	}
	.p-plan__contents{
		padding-top: 60px;
	}
	@include mq (large){
		.p-plan__contents{
			padding-top: calc(200px - var(--headerH));
		}
	}
}

/*  p-plan-sec
---------------------------------------- */
.p-plan-sec{
	padding: 50px 0;
	&:first-child{
		padding-top: 0;
	}
	&:last-child{
		padding-bottom: 100px;
	}
	.p-plan-sec__list{
		display: flex;
		flex-wrap: wrap;
		gap: 9px;
	}
	.p-plan-sec__item{
		width: 100%;
	}
	@include mq (large){
		padding: 100px 0;
		&:last-child{
			padding-bottom: 200px;
		}
	}
	@include mq (full){
		.p-plan-sec__list{
			gap: 9px 60px;
		}
		.p-plan-sec__item{
			width: calc(50% - (60px / 2));
		}
	}
}