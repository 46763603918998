@use '../../fundation/index' as *;

.c-head01{
	margin-bottom: 40px;
	padding-left: 28px;
	.c-head01__en{
		position: relative;
		font-size: font-size(50);
		@include font_en;
		&::before{
			content: '';
			position: absolute;
			top: 50%;
			translate: 0 -50%;
			left: -28px;
			width: 14px;
			aspect-ratio: 1;
			border-radius: 50%;
			border: 3px solid $clr_default;
		}
	}
	.c-head01__ja{
		margin-top: 5px;
		@include font_title;
		font-size: font-size(16);
		font-weight: 700;
	}
	@include mq (large){
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 32px;
		margin-bottom: 80px;
		padding-left: 64px;
		.c-head01__en{
			font-size: font-size(88);
			&::before{
				left: -64px;
				width: 24px;
				border-width: 8px;
			}
		}
		.c-head01__ja{
			margin-top: 0;
			font-size: font-size(32);
		}
	}
}

/*  c-head02
---------------------------------------- */
.c-head02{
	position: relative;
	margin-bottom: 24px;
	padding-left: 16px;
	@include font_title;
	font-size: font-size(20);
	&::before{
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		translate: 0 -50%;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		border: 2px solid $clr_default;
	}
	@include mq (large){
		margin-bottom: 32px;
		padding-left: 32px;
		font-size: font-size(32);
		&::before{
			width: 14px;
			height: 14px;
			border-width: 4px;
		}
	}
}

.c-head02.-large{
	margin-bottom: 30px;
	padding-left: 24px;
	font-size: font-size(26);
	&::before{
		width: 10px;
		height: 10px;
		border: 3px solid $clr_default;
	}
	@include mq (large){
		margin-bottom: 56px;
		padding-left: 44px;
		font-size: font-size(40);
		&::before{
			width: 16px;
			height: 16px;
			border: 5px solid $clr_default;
		}
	}
}

/*  c-head03
---------------------------------------- */
.c-head03{
	position: relative;
	margin-bottom: 20px;
	padding-left: 18px;
	@include font_title;
	font-size: font-size(20);
	&::before{
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		width: 4px;
		height: 100%;
		background: $clr_primary;
		border-radius: 1px;
	}
	@include mq (large){
		margin-bottom: 26px;
		padding-left: 25px;
		font-size: font-size(24);
		&::before{
			top: 50%;
			left: 0;
			translate: 0 -50%;
			width: 5px;
			height: calc(100% - 8px);
		}
	}
}