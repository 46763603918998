@use '../../fundation/index' as *;

/*  p-requirements
---------------------------------------- */
.p-requirements{
	padding-bottom: 100px;
	background: #fff;
	.p-requirements__inner{
		@include inner;
	}
	.p-requirements__contents{
		padding-top: 60px;
	}
	.p-requirements-gallery{
		position: relative;
    overflow: auto;
		width: calc(100vw - 20px);
		margin-top: 40px;
	}
	.p-requirements-gallery__imgWrap{
		display: grid;
		grid-template-columns: repeat(4,1fr);
		gap: 8px;
		width: max(100%,680px);
	}
	.p-requirements-gallery__img{
		overflow: hidden;
		border-radius: 4px;
	}
	@include mq (large){
		padding-bottom: 240px;
		.p-requirements__contents{
			padding-top: calc(200px - var(--headerH));
		}
		.p-requirements-gallery{
			width: 100%;
			margin-top: 60px;
		}
		.p-requirements-gallery__imgWrap{
			gap: 16px;
		}
	}
	@include mq (full){
		.p-requirements__contents{
			margin-right: 136px;
		}
	}
}

/*  p-requirements-sec
---------------------------------------- */
.p-requirements-sec{
	&:nth-of-type(n+2){
		padding-top: 40px;
	}
	.p-requirements-sec__tagWrap{
		margin-bottom: 16px;
	}
	.p-requirements-sec__contents{
	 > * + *{
		margin-top: 1.6em;
	}
	}
	@include mq (large){
		&:nth-of-type(n+2){
			padding-top: 60px;
		}
		.p-requirements-sec__table{
			th{
				width: 180px;
			}
		}
	}
	@include mq (full){
		.p-requirements-sec__table{
			th{
				width: 256px;
			}
		}
	}
}