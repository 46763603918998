@use '../../fundation/index' as *;

/*  c-table01
---------------------------------------- */
.c-table01{
	border-top: 1px solid $clr_default;
	th,td{
		display: block;
	}
	th{
		text-align: left;
		padding: 20px 10px 10px;
		color: $clr_text_secondary;
		font-weight: 400;
	}
	td{
		padding: 0 10px 20px;
		border-bottom: 1px solid $clr_border;
	}
	@include mq (large){
		th,td{
			display: table-cell;
			border-bottom: 1px solid $clr_border;
		}
		th{
			vertical-align: top;
			padding: 30px 40px 30px 30px;
			border-right: 1px solid $clr_border;
		}
		td{
			padding: 30px 40px;
		}
	}
	@include mq (full){
		th{
			padding: 30px 60px 30px 40px;
		}
		td{
			padding: 30px 60px;
		}
	}
}

/*  c-table02
---------------------------------------- */
.c-table02{
	overflow: auto;
	table{
		table-layout: auto;
		width: 100%;
	}
	th,td{
		text-align: center;
		padding: 14px 20px;
		border: 1px solid $clr_border;
		&:first-child{
			border-width: 0 1px 0 0;
		}
		&:nth-child(n+2){
			border-width: 0 1px 0 1px;
		}
		&:last-child{
			border-width: 0 0 0 1px;
		}
	}
	th{
		color:#fff;
		background: $clr_default;
	}
	tbody{
		tr{
			border-bottom: 1px solid $clr_border;
			&:nth-child(2n){
				background: $clr_bg;
			}
		}
	}
	@include mq (medium){
		margin-right: -120px;
	}
	@include mq (full){
		margin-right: 0;
		th,td{
			padding: 16px 30px;
		}
	}
}

/*  c-table03
---------------------------------------- */
.c-table03{
	border-top: 1px solid $clr_border;
	th,td{
		display: block;
		text-align: left;
		padding: 15px 20px;
		border-bottom: 1px solid $clr_border;
	}
	th{
		background: $clr_bg;
		font-weight: 400;
	}
	td{
		color: $clr_text_secondary;
	}
	@include mq (large){
		th,td{
			display: table-cell;
			vertical-align: top;
			padding: 17px 32px;
		}
	}
}