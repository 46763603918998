@use '../../fundation/index' as *;

/*  c-nav-products
---------------------------------------- */
.c-nav-products{
	padding: 60px 0;
	background: $clr_bg;
	.c-nav-products__inner{
		@include inner;
	}
	.c-nav-products__contents{
		&:nth-child(n+2){
			margin-top: 40px;
		}
	}
	.c-nav-products__info-list{
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}
	.c-nav-products__info-item{
		width: calc(50% - (8px /2));
		min-height: 164px;
		height: auto;
	}
	.c-nav-products__info-link{
		overflow: hidden;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		padding: 12px 12px 30px;
		aspect-ratio: 1/1;
		border-radius: 4px;
		@include trans-normal;
		&::before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 164px;
			opacity: 0.3;
			background-image: linear-gradient(to bottom, #000 0%, rgba(#000,0) 100%);
			z-index: 1;
		}
		&:hover,&:focus-visible{
			.c-nav-products__info-img{
				img{
					scale: 1.1;
				}
			}
			.c-nav-products__info-arw{
				&::before{
					background: $clr_primary;
				}
				&::after{
					opacity: 1;
				}
			}
		}
	}
	.c-nav-products__info-title{
		position: relative;
		@include font_title;
		font-size: font-size(16);
		color: #fff;
		z-index: 2;
	}
	.c-nav-products__info-img{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include trans-normal;
		z-index: 0;
		img{
			height: 100%;
			object-fit: cover;
			@include trans-normal;
		}
	}
	.c-nav-products__info-arw{
		position: absolute;
		bottom: 6px;
		right: 6px;
		width: 24px;
		height: 24px;
		&::before,
		&::after{
			content:'';
			position: absolute;
			@include trans-normal;
		}
		&::before{
			top: 50%;
			right: 50%;
			translate: 50% -50%;
			width: 4px;
			height: 8px;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			background: #fff;
			z-index: 2;
		}
		&::after{
			width: 100%;
			height: 100%;
			background: #fff;
			border-radius: 4px;
			opacity: 0.2;
			z-index: 1;
		}
	}
	.c-nav-products__subContents{
		margin-top: 24px;
		padding-top: 24px;
		border-top: 1px solid $clr_border;
	}
	@include mq (large){
		padding: 100px 0;
		.c-nav-products__contents{
			&:nth-child(n+2){
				margin-top: 60px;
			}
		}
		.c-nav-products__info-list{
			gap: 16px;
		}
		.c-nav-products__info-item{
			width: calc(25% - (48px / 4));
			min-height: 218px;
			height: auto;
		}
		.c-nav-products__info-link{
			padding: 20px 20px 60px;
			aspect-ratio: 388/218;
			&::before{
				height: 170px;
			}
		}
		.c-nav-products__info-title{
			font-size: font-size(18);
		}
		.c-nav-products__info-arw{
			bottom: 16px;
			right: 16px;
			width: 32px;
			height: 32px;
			&::before{
				width: 5px;
				height: 10px;
			}
		}
		.c-nav-products__subContents{
			margin-top: 60px;
			padding-top: 60px;
		}
	}
	@include mq (full){
		.c-nav-products__info-title{
			font-size: font-size(22);
		}
		.c-nav-products__info-link{
			padding: 30px 30px 60px;
		}
	}
}