@use '../../fundation/index' as *;
/*
---------------------------------------- */
.c-archive01{
	.c-archive01__item{
		border-bottom: 1px solid $clr_border;
	}
	.c-archive01__item-link{
		display: block;
		padding: 32px 0;
		@include trans-normal;
		@include trans-normal;
		&:hover,&:focus-visible{
			opacity: 0.5;
		}
	}
	.c-archive01__item-date{
		margin-bottom: 5px;
		color: $clr_text_secondary;
		font-size: font-size(13);
	}
	.c-archive01__item-title{
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	@include mq (large){
		.c-archive01__item-link{
			display: flex;
			align-items: center;
			padding: 48px 40px;
			gap: 44px;
		}
		.c-archive01__item-date{
			margin-bottom: 0;
			flex-shrink: 0;
			font-size: font-size(16);
		}
		.c-archive01__item-title{
			-webkit-line-clamp: 1;
		}
	}
}

/*  c-archive02
---------------------------------------- */
.c-archive02{
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	.c-archive02__item{
		width: 100%;
	}
	.c-archive02__link{
		display: block;
		@include trans-normal;
		&:hover,&:focus-visible{
			.c-archive02__img{
				&::before{
					background: $clr_primary;
				}
				&::after{
					background: #fff;
				}
			}
		}
	}
	.c-archive02__img{
		position: relative;
		border: 1px solid $clr_border;
		border-radius: 4px;
		aspect-ratio: 512 / 288;
		&::before,
		&::after{
			content:'';
			position: absolute;
			@include trans-normal;
		}
		&::before{
			bottom: 6px;
			right: 6px;
			width: 24px;
			height: 24px;
			background: $clr_bg;
			border-radius: 4px;
		}
		&::after{
			bottom: 14px;
			right: 16px;
			width: 4px;
			height: 8px;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			background: $clr_default;
		}
	}
	.c-archive02__textWrap{
		margin-top: 14px;
	}
	.c-archive02__title{
		margin-bottom: 8px;
		@include font_title;
	}
	.c-archive02__text{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		color: $clr_text_secondary;
	}
	@include mq (large){
		gap: 32px 16px;
		.c-archive02__item{
			width: calc(33.33% - (32px / 3));
		}
	}
	@include mq (full){
		gap: 60px 32px;
		.c-archive02__item{
			width: calc(33.33% - (64px / 3));
		}
		.c-archive02__img{
			&::before{
				bottom: 16px;
				right: 16px;
				width: 32px;
				height: 32px;
			}
			&::after{
				bottom: 26px;
				right: 29px;
				width: 5px;
				height: 10px;
			}
		}
		.c-archive02__textWrap{
			margin-top: 20px;
		}
		.c-archive02__title{
			margin-bottom: 12px;
			font-size: font-size(18);
		}
	}
}