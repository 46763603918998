@use '../../fundation/index' as *;

/*  c-pager01
----------------------------------- */
.c-pager01 {
  margin: 32px auto 0;
  display: flex;
  width: 275px;
  justify-content: center;
  align-items: center;
  > * {
    width: 40px;
    height: 40px;
    @include font_en;
  }
  .previous {
    border-width: 1px 0 1px 1px;
    border-radius: 4px 0 0 4px;
  }
  .current {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid $clr_primary;
    background: $clr_primary;
    aspect-ratio: 1/1;
  }
  .page,
  .extend,
  .larger {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
  }
  .extend {
    border: solid $clr_border;
    border-width: 1px 1px 1px 0;
  }
  .larger {
    border-width: 1px 1px 1px 0;
  }
  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: solid $clr_border;
    border-width: 1px 1px 1px 0;
    @include trans-normal;
    &:hover,
    &:focus-visible {
      color: #fff;
      border-color: $clr_primary;
      background: $clr_primary;
    }
  }
  .previouspostslink,
  .nextpostslink {
    position: relative;
    border: none;
    border: solid $clr_border;
    &:hover,
    &:focus-visible {
      &::before {
        background: #fff;
      }
    }
  }
  .previouspostslink {
    border-width: 1px 1px 1px 1px;
    border-radius: 4px 0 0 4px;
  }
  .nextpostslink{
    border-width: 1px 1px 1px 0;
    border-radius: 0 4px 4px 0;
  }
  @include mq(large) {
    margin: 80px auto 0;
    width: 400px;
    > * {
      width: 50px;
      height: 50px;
    }
    a {
      width: 50px;
      height: 50px;
      font-size: font-size(18);
    }
    .previouspostslink,
    .nextpostslink {
      &::before {
        right: 45%;
        top: 41%;
      }
    }
  }
}

/*  c-pager02
----------------------------------- */
.c-pager02 {
  position: relative;
  margin: 40px auto 0;
  padding-top: 60px;
  .c-pager02__prev,
  .c-pager02__next {
    position: absolute;
    display: block;
    width: min(50%, 164px);
    height: 24px;
    top: 0;
  }
  .c-pager02__prev {
    left: 0;
  }
  .c-pager02__next {
    right: 0;
  }
  .c-pager02__back {
    margin: auto;
    width: 220px;
    text-align: center;
  }
  .c-pager02__prev-link,
  .c-pager02__next-link {
    position: relative;
    display: block;
    @include trans-normal;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 3px;
      background: $clr_bg;
      border-radius: 4px;
      @include trans-normal;
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 4px;
      height: 8px;
      top: 11px;
      mask: url(../img/common/icon-arw01.svg) no-repeat center / contain;
      background: $clr_default;
      @include trans-normal;
    }
    &:hover,
    &:focus-visible {
      &::before {
        background: $clr_primary;
      }
      &::after {
        background: #fff;
      }
    }
  }
  .c-pager02__prev-link {
    padding-left: 36px;
    &::before {
      left: 0;
    }
    &::after {
      left: 10px;
      scale: -1;
    }
  }
  .c-pager02__next-link {
    padding-right: 36px;
    text-align: right;
    &::before {
      right: 0;
    }
    &::after {
      right: 10px;
    }
  }
  .c-pager02__back-link {
    display: block;
    padding: 14px;
    width: 100%;
    @include trans-normal;
    border: 1px solid $clr_default;
    border-radius: 4px;
    &:hover,
    &:focus-visible {
      color: #fff;
      background: $clr_primary;
    }
  }
  @include mq(large) {
    margin-top: 110px;
    padding-top: 0;
    .c-pager02__prev,
    .c-pager02__next {
      top: 50%;
      translate: 0 -50%;
    }
    .c-pager02__back {
      width: 360px;
    }
    .c-pager02__back-link {
      padding: 18px;
      font-size: font-size(18);
    }
    .c-pager02__prev-link,
    .c-pager02__next-link {
      &::before {
        width: 32px;
        height: 32px;
        top: -1px;
        background: $clr_bg;
        border-radius: 4px;
      }
      &::after {
        width: 5px;
        height: 10px;
        top: 11px;
        background: $clr_default;
      }
    }
    .c-pager02__prev-link {
      padding-left: 55px;
      &::after {
        left: 12px;
      }
    }
    .c-pager02__next-link {
      padding-right: 55px;
      &::after {
        top: 9px;
        right: 13px;
      }
    }
  }
}
