@use '../fundation/index' as *;

/* .l-header
---------------------------------------- */
.l-header{
	position: relative;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	.l-header__inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		height: var(--headerH);
		padding: 10px var(--headerH) 10px 20px;
	}
	.l-header__logo{
		position: relative;
		width: 145px;
		z-index: 1;
		&-link{
			display: block;
			@include trans-normal;
			&:hover,&:focus-visible{
				opacity: 0.6;
			}
			img{
				display: block;
			}
		}
	}
	.l-header__btn{
		position: absolute;
		top: 50%;
		translate: 0 -50%;
		right: 0;
		width: var(--headerH);
		aspect-ratio: 1/1;
		background: $clr_primary;
		z-index: 2;
	}
	.l-header__btn-line{
		position: absolute;
		left: 50%;
		top: 50%;
		translate: -50% -50%;
		width: 24px;
		height: 22px;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			width: 100%;
			height: 2px;
			background: #fff;
		}
		&-in{
			position: absolute;
			left: 50%;
			top: 50%;
			translate: -50% -50%;
			width: 100%;
			height: 100%;
			&::before,
			&::after{
				content: '';
				position: absolute;
				left: 50%;
				translate: -50% 0;
				height: 2px;
				width: 100%;
				background: #fff;
			}
			&::before{
				top: 0;
			}
			&::after{
				top: 20px;
			}
		}
	}
	.l-header__btn[aria-expanded="true"]{
		.l-header__btn-line{
			&::before{
				opacity: 0;
			}
		}
		.l-header__btn-line-in{
			&::before{
				rotate: 45deg;
				top: 9px;
			}
			&::after{
				rotate: -45deg;
				top: 9px;
			}
		}
	}
	@include mq (large){
		position: absolute;
		z-index: 999;
		.l-header__inner{
			height: var(--headerH);
			padding: 10px var(--headerH) 10px 40px;
		}
		&.is-active{
			.l-header__inner{
				background: #fff;
			}
		}
		.l-header__logo{
			width: 241px;
			path,rect{
				fill: $clr_default;
			}
		}
		.l-header__btn-line{
			width: 40px;
			height: 30px;
			&-in{
				&::after{
					top: 28px;
				}
			}
		}
		.l-header__btn[aria-expanded="true"]{
			.l-header__btn-line{
				&::before{
					opacity: 0;
				}
			}
			.l-header__btn-line-in{
				&::before{
					top: 11px;
				}
				&::after{
					top: 11px;
				}
			}
		}
	}
	.l-gnav__main{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 40px;
		padding-right: 40px;
	}
	.l-gnav__main-item-link{
		display: block;
		padding: 2px;
		font-size: font-size(16);
		color: $clr_default;
		@include trans-normal;
		&:hover,&:focus-visible{
			opacity: 0.5;
		}
	}
}

#home .l-header{
	@include mq (large){
		.l-header__logo{
			path,rect{
				fill: #fff;
			}
		}
	}
	.l-gnav__main-item-link{
		color: #fff;
	}
}


/* .l-gnav
---------------------------------------- */
.l-gnav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-height: 100vh;
	padding-top: var(--headerH);
	background: #fff;
	overflow: auto;
	//display: none;
	opacity: 0;
	pointer-events: none;
	user-select: none;
	transition: all .3s ease-out;
	z-index: 2;
	&::-webkit-scrollbar {
		display: none;
	}
	&[aria-hidden="false"] {
		//display: block;
		opacity: 1;
		pointer-events: auto;
		user-select: auto;
	}
	.l-gnav__inner{

	}
	.l-gnav__list{
		padding: 28px 40px;
	}
	.l-gnav__item{
		&:nth-child(n+2){
			border-top: 1px solid $clr_border;
		}
	}
	.l-gnav__item-link{
		display: block;
		padding: 24px 0;
		font-size: font-size(18);
		font-weight: 700;
		@include trans-normal;
		&:hover,&:focus-visible{
			opacity: 0.5;
		}
	}
	.l-gnav__item-in{
		position: relative;
		display: block;
		padding: 24px 50px 24px 0;
		font-size: font-size(18);
		font-weight: 700;
		cursor: pointer;
	}
	.l-gnav__sub{
		margin-bottom: 24px;
	}
	.l-gnav__sub-item,
	.l-gnav__sub-products-category,
	.l-gnav__sub-products-sub{
		&:nth-child(n+2){
			margin-top: 20px;
		}
		&-link{
			display: block;
			font-size: font-size(15);
			@include trans-normal;
			&:hover,&:focus-visible{
				opacity: 0.5;
			}
		}
	}
	.l-gnav__contact{
		position: relative;
		padding: 40px 20px;
		background: $clr_bg;
		z-index: 1;
		&::before{
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			width: 190px;
			height: auto;
			aspect-ratio: 190/86;
			background: #fff;
			z-index: -1;
			mask: url(../img/common/bg-nav-logo.svg) no-repeat center /contain;
		}
	}
	.l-gnav__contact-btn-item{
		&:nth-child(n+2){
			margin-top: 12px;
		}
		&-link{
			display: block;
			padding: 20px;
			border: 1px solid $clr_default;
			text-align: center;
			font-size: font-size(16);
			border-radius: 4px;
			@include trans-normal;
			&:hover,&:focus-visible{
				opacity: 0.5;
			}
		}
	}
	.l-gnav__contact-note{
		margin-top: 10px;
		&-text{
			font-size: font-size(12);
			padding-left: 1em;
			text-indent: -1em;
			color: $clr_text_gray;
			&:nth-child(n+2){
				margin-top: 2px;
			}
		}
	}
	@include mq(large) {
		padding-top: 0;
		height: 100vh;
		.l-gnav__inner{
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-areas:
			'contact list';
			height: 100%;
		}
		.l-gnav__list{
			position: relative;
			grid-area: list;
			display: flex;
			flex-wrap: wrap;
			gap: 80px 60px;
			align-content: center;
			padding: 100px vw(120);
			&::before{
				content: '';
				position: absolute;
				bottom: 0;
				right: vw(120);
				width: vw(612);
				aspect-ratio: 612/271;
				background: $clr_bg;
				z-index: -1;
				mask: url(../img/common/bg-nav-logo.svg) no-repeat center /contain;
			}
		}
		.l-gnav__item{
			align-self: start;
			&:nth-child(n+2){
				border-top: 0;
			}
			&:has(.l-gnav__item-in){
				width: 100%;
			}
		}
		.l-gnav__item-link{
			padding: 0;
			font-size: font-size(22);
		}
		.l-gnav__item-in{
			padding: 0;
			font-size: font-size(20);
			cursor: auto;
		}
		.l-gnav__sub{
			margin: 25px 0 0;
		}
		.l-gnav__sub-item,
		.l-gnav__sub-products-category,
		.l-gnav__sub-products-sub{
			&:nth-child(n+2){
				margin-top: 0;
			}
			&-link{
				display: block;
				font-size: font-size(20);
			}
		}
		.l-gnav__sub-list{
			display: flex;
			flex-wrap: wrap;
			gap: 32px;
		}
		.l-gnav__sub-item-link{
			font-size: font-size(18);
		}

		.l-gnav__sub-products{
			display: grid;
			grid-template-columns: 2fr 1fr 1fr 2fr;
			grid-template-areas:
			'prod1 prod2 prod2 prod3'
			'prod4 prod4 prod5 prod5';
			gap: 16px;
			.-prod01{ grid-area: prod1; }
			.-prod02{ grid-area: prod2; }
			.-prod03{ grid-area: prod3; }
			.-prod04{ grid-area: prod4; }
			.-prod05{ grid-area: prod5; }
		}
		.l-gnav__sub-products-category{
			overflow: hidden;
			border-radius: 4px;
			&.-prod02{
				.l-gnav__sub-products-category-link{
					.l-gnav__sub-products-category-arw{
						right: 6px;
					}
				}
			}
			&-link{
				position: relative;
				display: block;
				aspect-ratio: 278/417;
				height: 100%;
				&::before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					height: 208px;
					background-image: linear-gradient(to bottom, #000 0%, rgba(#000,0) 100%);
					opacity: 0.3;
					z-index: 1;
				}
				&:hover,&:focus-visible{
					opacity: 1;
					.l-gnav__sub-products-category-img{
						img{
							scale: 1.1;
						}
					}
					.l-gnav__sub-products-category-arw{
						&::before{
							background: $clr_primary;
						}
						&::after{
							opacity: 1;
						}
					}
				}
			}
			&-img{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				img{
					width: 100%;
					height: 100%;
					object-fit: contain;
					@include trans-normal;
				}
			}
			&-arw{
				position: absolute;
				bottom: 6px;
				right: 23px;
				width: 24px;
				height: 24px;
				&::before,
				&::after{
					content:'';
					position: absolute;
					@include trans-normal;
				}
				&::before{
					top: 50%;
					right: 50%;
					translate: 50% -50%;
					width: 4px;
					height: 8px;
					mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
					background: #fff;
					z-index: 2;
				}
				&::after{
					width: 100%;
					height: 100%;
					background: #fff;
					border-radius: 4px;
					opacity: 0.2;
					z-index: 1;
				}
			}
			&-text{
				position: absolute;
				top: vw(32);
				left: vw(32);
				color: #fff;
				@include font_title;
				font-size: font-size(20);
				z-index: 2;
			}
		}
		.l-gnav__sub-products-sub-link{
			position: relative;
			height: 100%;
			padding: 32px;
			border: 1px solid $clr_border;
			background: #fff;
			border-radius: 4px;
			@include trans-normal;
			&:hover,&:focus-visible{
				opacity: 1;
				color: $clr_primary;
				.l-gnav__sub-products-sub-arw{
					background: $clr_primary;
					&::before{
						background: #fff;
					}
				}
			}
		}
		.l-gnav__sub-products-sub-text{
			font-size: font-size(20);
			font-weight: 700;
		}
		.l-gnav__sub-products-sub-img{
			width: 50px;
			margin-top: 16px;
		}
		.l-gnav__sub-products-sub-arw{
			position: absolute;
			bottom: 6px;
			right: 6px;
			display: block;
			background: $clr_bg;
			border-radius: 4px;
			width: 24px;
			height: 24px;
			@include trans-normal;
			&::before{
				content:'';
				position: absolute;
				top: 50%;
				left: 50%;
				translate: -50% -50%;
				width: 4px;
				height: 8px;
				mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
				background: $clr_default;
			}
		}
		.l-gnav__contact{
			grid-area: contact;
			display: grid;
			place-content: center;
			width: vw(520);
			padding: 40px vw(80);
			&::before{
				content: none;
			}
		}
		.l-gnav__contact-logo{
			width: vw(289);
			margin: 0 auto 80px;
		}
		.l-gnav__contact-note{
			&-text{
				font-size: font-size(13);
				&:nth-child(n+2){
					margin-top: 1px;
				}
			}
		}
		.l-gnav__sub-products-sub-img.-plan{
			svg{
				path{
					fill: $clr_text_secondary;
				}

			}
		}
	}
	@include mq (full){
		.l-gnav__sub-products{
			grid-template-columns: repeat(4,1fr);
			grid-template-rows: repeat(2,1fr);
			grid-template-areas:
			'prod1 prod2 prod3 prod4'
			'prod1 prod2 prod3 prod5';
		}
		.l-gnav__sub-products-category{
			&-arw{
				right: 6px;
			}
		}
	}
}
.js-header{
	&.is-hidden{
		translate: 0 -100%;
		transition: all 0.3s linear;
	}
	&.is-active{
		position: fixed;
		translate: 0 0;
		.l-header__logo{
			path,rect{
				fill: $clr_default !important;
			}
		}
		.l-gnav__main-item-link{
			color: $clr_default !important;
		}
	}
}