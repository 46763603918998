@use '../../fundation/index' as *;

.c-slider{
	position: relative;
	.c-slide-pagination.swiper-pagination{
		position: relative;
		bottom: auto;
		left: auto;
		margin-top: 24px;
		.swiper-pagination-bullet{
			width: 4px;
			height: auto;
			aspect-ratio: 1;
			margin: 0 3px;
			padding: 0;
			border-radius: 0;
			&.swiper-pagination-bullet-active{
				background: $clr_default;
			}
		}
	}
	@include mq (large){
		padding-top: 64px !important;
		margin-top: -64px;
		.c-slide-pagination.swiper-pagination{
			margin-top: 40px;
			.swiper-pagination-bullet{
				width: 8px;
				margin: 0 5px;
			}
		}
		.c-slide-pager{
			position: absolute;
			top: 0;
			right: 0;
			width: 72px;
			height: 32px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.c-slide-pager__prev.swiper-button-prev,
		.c-slide-pager__next.swiper-button-next{
			position: relative;
			top: 0;
			right: auto;
			left: 0;
			margin: 0;
			width: 32px;
			height: auto;
			aspect-ratio: 1;
			background-color: transparent;
			border: 1px solid $clr_default;
			border-radius: 4px;
			&::before{
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				translate: -50% -50%;
				width: 5px;
				height: auto;
				aspect-ratio: 1/2;
				background: $clr_default;
				mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			}
			&::after{
				content: none;
			}
			@include trans-normal;
			&:hover,&:focus-visible{
				background: $clr_primary;
				&::before{
					background: #fff;
				}
			}
		}
		.c-slide-pager__prev.swiper-button-prev::before{
			scale: -1 1;
		}
	}
}