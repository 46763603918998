@use '../../fundation/index' as *;
/*  p-history
----------------------------------- */
.p-history {
  padding: 60px 0 100px;
  .p-history__inner {
    @include inner;
  }
  .p-history__contents-ttlArea {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .p-history__contents-ttl-logo {
    width: 17px;
  }
  .p-history__contents-ttl {
    display: flex;
    padding: 8px;
    width: calc(50% - 5px);
    justify-content: center;
    align-items: center;
    gap: 6px;
    background: $clr_bg;
  }
  .p-history__contents-ttl-item {
    font-size: font-size(16);
  }
  .p-history__contents-itemWrapper {
    position: relative;
  }
  .p-history__contents-bg {
    position: absolute;
    display: flex;
    width: 100%;
    column-gap: 40px;
    top: 110px;
  }
  .p-history__contents-bg-img {
    width: calc(50% - 20px);
    img {
      display: block;
      margin: 0 auto;
      width: 70%;
      aspect-ratio: 1/1;
    }
  }
  .p-history__contents-item {
    padding-top: 40px;
  }
  .p-history__contents-item-ttl {
    font-size: font-size(16);
    text-align: center;
  }
  .p-history__contents-child {
    position: relative;
    margin-top: 20px;
    padding-top: 20px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      width: 1px;
      height: 100%;
      background: $clr_border;
      z-index: -1;
    }
  }
  .p-history__heisei-list {
    display: flex;
    padding-top: 12px;
    flex-direction: column;
    gap: 12px;
  }
  .p-history__heisei-img-txt {
    padding-top: 8px;
    color: $clr_text_secondary;
    font-size: font-size(13);
  }
  .p-history__contents-child-txt,
  .p-history__contents-child-img-txt {
    padding-top: 8px;
    color: $clr_text_secondary;
  }
  .p-history__contents-child-img-txt {
    font-size: font-size(13);
  }
  .p-history__contents-child-img {
    margin-top: 12px;
    img {
      border-radius: 4px;
    }
  }
  .p-history__contents-item-row {
    display: flex;
  }
  .p-history__contents-child-right,
  .p-history__contents-child-left {
    position: relative;
    margin-left: auto;
    width: 50%;
    border-top: 1px solid $clr_border;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: -5px;
      width: 9px;
      height: 9px;
      background: $clr_primary;
      border-radius: 50%;
    }
  }
  .p-history__contents-child-right {
    padding: 17px 0 24px 20px;
    &::before {
      left: -4px;
    }
  }
  .p-history__contents-child-left {
    margin-right: auto;
    margin-left: 0;
    padding: 12px 20px 24px 0;
    &::before {
      right: -5px;
    }
  }
  @include mq(large) {
    padding: 200px 0 240px;
    .p-history__contents-ttlArea {
      gap: 32px;
    }
    .p-history__contents-ttl-logo {
      width: 36px;
    }
    .p-history__contents-ttl {
      padding: 16px;
      width: calc(50% - 16px);
      gap: 12px;
    }
    .p-history__contents-ttl-item {
      font-size: font-size(24);
    }
    .p-history__contents-bg {
      column-gap: 120px;
      top: 180px;
    }
    .p-history__contents-bg-img {
      width: calc(50% - 60px);
      img {
        width: min(100%, 400px);
      }
    }
    .p-history__contents-item {
      padding-top: 50px;
    }
    .p-history__contents-item-ttl {
      font-size: font-size(32);
    }
    .p-history__contents-child {
      margin-top: 40px;
      padding: 40px 0 0;
      &::before {
        width: 2px;
      }
    }
    .p-history__heisei-list {
      padding-top: 16px;
      flex-direction: row;
      gap: 16px;
    }
    .p-history__heisei-img-txt,
    .p-history__contents-child-img-txt {
      font-size: font-size(14);
    }
    .p-history__contents-child-txt,
    .p-history__contents-child-img-txt {
      padding-top: 0;
    }
    .p-history__contents-child-img {
      margin-top: 16px;
      width: min(100%, 250px);
    }
    .p-history__contents-child-img.-pcSmall {
      width: 160px;
    }
  }
  @include mq(full) {
    .p-history__contents-ttl-item {
      font-size: font-size(32);
    }
    .p-history__contents-child-img {
      padding-top: 16px;
      width: min(100%, 250px);
    }
    .p-history__contents-child-img.-pcSmall {
      width: 160px;
    }
  }
  @include mq(full) {
    .p-history__contents-ttl-item {
      font-size: font-size(32);
    }
    .p-history__contents-item {
      &:has(.p-history__contents-child) {
        .p-history__contents-child-right,
        .p-history__contents-child-left {
          display: grid;
          grid-template: 'ttl txt' auto '. img' 1fr / 70px auto;
          column-gap: 24px;
        }
      }
    }
    .p-history__contents-child-right,
    .p-history__contents-child-left {
      &::before {
        width: 12px;
        height: 12px;
      }
    }
    .p-history__contents-child-right {
      padding: 17px 0 32px 60px;
      &::before {
        left: -5px;
      }
    }
    .p-history__contents-child-left {
      padding: 17px 60px 32px 0;
      &::before {
        right: -7px;
      }
    }
    .p-history__contents-child-ttl {
      grid-area: ttl;
    }
    .p-history__contents-child-txt {
      grid-area: txt;
    }
    .p-history__contents-child-img,
    .p-history__heisei-list {
      grid-area: img;
    }
  }
}

.p-history {
  .p-history__contents-child-item.-row {
    display: flex;
  }
}

.p-history {
  .p-history__heisei-item-img.-hasBorder,
  .p-history__contents-child-img.-hasBorder {
    border-radius: 4px;
    overflow: hidden;
  }
}
