@use '../../fundation/index' as *;
/*  c-single01
----------------------------------- */
.c-single01 {
  .c-single01__header {
    margin-bottom: 32px;
    border-bottom: 1px solid $clr_border;
  }
  .c-single01__header-date {
    display: block;
    padding-bottom: 10px;
    @include font_en;
    font-size: font-size(13);
    color: $clr_text_secondary;
  }
  .c-single01__header-title {
    padding-bottom: 24px;
    font-size: font-size(26);
  }
  .c-single01__contents {
    overflow: hidden;

    > * {
      margin-top: 32px;
      clear: both;
    }
    p {
      color: $clr_text_secondary;
    }
    strong {
      color: $clr_primary;
    }
    a {
      display: inline-block;
      color: $clr_primary;
      &:hover,
      &:focus-visible {
        text-decoration: none;
      }
    }
    ul {
      padding-left: 16px;
      li {
        position: relative;
        list-style: none;
        &::before {
          content: '';
          position: absolute;
          display: block;
          width: 5px;
          height: 5px;
          top: 13px;
          left: -15px;
          background: $clr_primary;
          border-radius: 50%;
        }
        &:nth-child(n + 2) {
          margin-top: 10px;
        }
      }
    }
    img {
      display: block;
      max-width: 100%;
      width: auto;
    }
  }
  @include mq(large) {
    .c-single01__header {
      margin-bottom: 60px;
    }
    .c-single01__header-date {
      padding-bottom: 15px;
      font-size: font-size(16);
    }
    .c-single01__header-title {
      padding-bottom: 35px;
      font-size: font-size(32);
    }
    .c-single01__contents {
      > * {
        margin-top: 60px;
      }
      .aligncenter {
        display: block;
        margin: 40px auto 0;
        text-align: center;
        img {
          display: block;
          margin: 0 auto;
        }
      }
      .alighleft {
        float: left;
        padding: 0 40px 40px 0;
      }
      .alignright {
        float: right;
        padding: 0 0 40px 40px;
      }
    }
  }
}
