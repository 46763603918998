@use '../../fundation/index' as *;
/*
---------------------------------------- */
.c-btn01{
	width: min(100%,220px);
	margin: 32px auto 0;
	.c-btn01__link{
		display: block;
		padding: 15px;
		border: 1px solid $clr_default;
		text-align: center;
		border-radius: 4px;
		@include font_en;
		@include trans-normal;
		&:hover,&:focus-visible{
			border-color: $clr_primary;
			background: $clr_primary;
			color: #fff;
		}
	}
	@include mq (large){
		width: 360px;
		margin-top: 53px;
		.c-btn01__link{
			padding: 22px;
			font-size: font-size(18);
		}
	}
}

.c-btn01.-pcRight{
	margin-right: 0;
}

/*  c-btn02
---------------------------------------- */
.c-btn02{
	.c-btn02__link{
		position: relative;
		display: block;
		padding: 20px 20px 40px;
		border: 1px solid $clr_border;
		border-radius: 4px;
		background: #fff;
		font-weight: 700;
		@include trans-normal;
		&:hover,&:focus-visible{
			color: $clr_primary;
			.c-btn02__arw{
				background: $clr_primary;
				&::before{
					background: #fff;
				}
			}
		}
	}
	.c-btn02__arw{
		position: absolute;
		bottom: 6px;
		right: 6px;
		display: block;
		background: $clr_bg;
		border-radius: 4px;
		width: 24px;
		height: 24px;
		@include trans-normal;
		&::before{
			content:'';
			position: absolute;
			top: 50%;
			left: 50%;
			translate: -50% -50%;
			width: 4px;
			height: 8px;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			background: $clr_default;
		}
	}
	@include mq (large){
		width: min(100%,522px);
		.c-btn02__link{
			padding: 28px 32px 70px;
			font-size: font-size(20);
				}
		.c-btn02__arw{
			bottom: 10px;
			right: 10px;
			width: 32px;
			height: 32px;
			&::before{
				width: 5px;
				height: 10px;
			}
		}
	}
}

.c-btn02__wrap{
	display: flex;
	flex-wrap: wrap;
	gap: 7px;
	.c-btn02{
		width: calc(50% - (8px / 2));
	}
	@include mq (large){
		gap: 16px;
		.c-btn02{
			width: calc(50% - (16px / 2));
		}
	}
}

/*  c-btn03
---------------------------------------- */

.c-btn03{
	width: min(100%,220px);
	margin: 32px auto 0;
	.c-btn03__link{
		display: block;
		padding: 15px;
		border: 1px solid $clr_default;
		text-align: center;
		border-radius: 4px;
		@include font_en;
		@include trans-normal;
		&:hover,&:focus-visible{
			border-color: $clr_primary;
			background: $clr_primary;
			color: #fff;
			.c-btn03__text{
				&::before{
					background: #fff;
				}
			}
		}
	}
	.c-btn03__text{
		position: relative;
		display: inline-block;
		padding-right: 21px;
		&::before{
			content:'';
			position: absolute;
			top: 50%;
			right: 0;
			translate: 0 -50%;
			width: 15px;
			height: 15px;
			mask: url(../img/common/icon-blank.svg) no-repeat center /contain;
			background: $clr_default;
			@include trans-normal;
		}
	}
	@include mq (large){
		width: 360px;
		margin-top: 53px;
		.c-btn03__link{
			padding: 22px;
		}
		.c-btn03__text{
			font-size: font-size(18);
		}
	}
}

.c-btn03.-download{
	min-width: 220px;
	width: fit-content;
	.c-btn03__text{
		&::before{
			width: 17px;
			height: 17px;
			mask: url(../img/common/icon-download.svg) no-repeat center /contain;
		}
	}
	@include mq (large){
		width: 360px;
		.c-btn03__text{
			padding-right: 30px;
			&::before{
				width: 22px;
				height: 22px;
			}
		}
	}
}

/*  c-btn-plan01
---------------------------------------- */
.c-btn-plan01{
	display: grid;
	grid-template-columns: repeat(2,1fr);
	gap: 8px;
	.c-btn-plan01__btn{
		&:nth-child(1){
			grid-area: 1/1/2/3;
		}
	}
	.c-btn-plan01__link{
		position: relative;
		display: block;
		padding: 20px 20px 40px;
		border: 1px solid $clr_border;
		border-radius: 4px;
		background: #fff;
		font-weight: 700;
		@include trans-normal;
		&:hover,&:focus-visible{
			color: $clr_primary;
			.c-btn-plan01__arw{
				background: $clr_primary;
				&::before{
					background: #fff;
				}
			}
		}
	}
	.c-btn-plan01__icon{
		display: block;
		margin-top: 14px;
		width: 40px;
	}
	.c-btn-plan01__arw{
		position: absolute;
		bottom: 6px;
		right: 6px;
		display: block;
		background: $clr_bg;
		border-radius: 4px;
		width: 24px;
		height: 24px;
		@include trans-normal;
		&::before{
			content:'';
			position: absolute;
			top: 50%;
			left: 50%;
			translate: -50% -50%;
			width: 4px;
			height: 8px;
			mask: url(../img/common/icon-arw01.svg) no-repeat center /contain;
			background: $clr_default;
		}
	}
	@include mq (large){
		grid-template-columns: repeat(3,1fr);
		gap: 17px;
		.c-btn-plan01__btn{
			&:nth-child(1){
				grid-area: auto;
			}
		}
		.c-btn-plan01__link{
			height: 100%;
			padding: 28px 32px 60px;
			font-size: font-size(22);
		}
		.c-btn-plan01__icon{
			margin-top: 12px;
			width: 60px;
		}
		.c-btn-plan01__arw{
			bottom: 16px;
			right: 16px;
			width: 32px;
			height: 32px;
			&::before{
				width: 5px;
				height: 10px;
			}
		}
	}
}
.c-btn-plan01.-spLarge{
	@include mq (large){
		grid-template-columns: repeat(2,1fr);
		}
		.c-btn-plan01__btn{
			&:nth-child(1){
				grid-area: 1/1/2/3;
			}
		}
	@include mq (full){
		grid-template-columns: repeat(3,1fr);
		.c-btn-plan01__btn{
			&:nth-child(1){
				grid-area: auto;
			}
		}
	}
}

/*  c-btn-pdf01
---------------------------------------- */
.c-btn-pdf01{
	.c-btn-pdf01__link{
		position: relative;
		display: block;
		padding-left: 24px;
		text-decoration: underline;
		&::before{
			content:'';
			position: absolute;
			top: 5px;
			left: 0;
			width: 18px;
			height: 18px;
			background: url(../img/common/icon-pdf.svg) no-repeat center /contain;
		}
		&:hover,&:focus-visible{
			text-decoration: none;
		}
	}
	@include mq (large){
		.c-btn-pdf01__link{
			padding-left: 32px;
			&::before{
				top: 3px;
				width: 24px;
				height: 24px;
			}
		}
	}
}