@use '../../fundation/index' as *;

/*  p-products-other
---------------------------------------- */
.p-products-other{
	padding: 100px 0;
	.p-products-other__inner{
		@include inner;
	}
	.p-products-other__titleWrap{
		display: flex;
		flex-wrap: wrap;
		gap: 32px;
		margin-bottom: 40px;
	}
	.p-products-other__title{
		width: 100%;
		margin: 0;
	}
	.p-products-other__btn{
		margin: 0 auto;
	}
	@include mq (large){
		padding: 200px 0;
		.p-products-other__titleWrap{
			margin-bottom: 56px;
		}
		.p-products-other__title{
			width: fit-content;
			margin: 0;
		}
		.p-products-other__btn{
			margin: 0 0 0 auto;
		}
	}
}