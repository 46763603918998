@use '../../fundation/index' as *;

/*  p-about
---------------------------------------- */
.p-about{
	.p-about__inner{
		@include inner;
	}
	.p-about__contents{
		padding-top: 60px;
	}
	@include mq (large){
		.p-about__contents{
			padding-top: calc(200px - var(--headerH));
		}
	}
}

/*  p-about-date
---------------------------------------- */
.p-about-date{
	padding-bottom: 50px;
	.p-about-date__flex{
		&:nth-child(n+2){
			margin-top: 32px;
		}
	}
	.p-about-date__contents{
		padding: 16px 10px 0 10px;
		border-top: 1px solid $clr_default;
		&:nth-child(n+2){
			margin-top: 32px;
		}
	}
	.p-about-date__contents-head{
		@include font_title;
	}
	.p-about-date__contents-body{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-top: 16px;
	}
	.p-about-date__contents-list{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 4px;
	}
	.p-about-date__contents-text{
		display: flex;
		align-items: end;
		gap: 4px;
		font-size: font-size(45);
		@include font_en;
		letter-spacing: 0;
	}
	.p-about-date__contents-text-side{
		display: inline-block;
		@include font_base;
		font-size: font-size(17);
		font-weight: 700;
	}
	.p-about-date__contents-text-small{
		display: inline-block;
		@include font_base;
		font-weight: 700;
	}
	.p-about-date__contents-item-add{
		text-align: right;
		margin-top: 2px;
	}
	.p-about-date__contents-detail{
		margin-top: 6px;
		color: $clr_text_secondary;
		font-size: font-size(13);
	}
	@include mq (large){
		position: relative;
		padding-bottom: 100px;
		&::before{
			content:'';
			position: absolute;
			top: 43%;
			left: 50%;
			translate: -50% -50%;
			width: 360px;
			height: 360px;
			background: url(../img/common/icon-crest.svg) no-repeat center /contain;
			z-index: -1;
		}
		.p-about-date__flex{
			display: flex;
			flex-wrap: wrap;
			gap: 60px 16px;
			margin-bottom: 60px;
			&:nth-child(n+2){
				margin: 60px 0 0 0;
			}
			&.-col1{
				.p-about-date__contents{
					width: 100%;
				}
			}
			&.-col3{
				.p-about-date__contents{
					width: calc(50% - (16px / 2));
				}
			}
		}
		.p-about-date__contents{
			&:nth-child(n+2){
				margin-top: 0;
			}
		}
		.p-about-date__contents-body{
			margin-top: 22px;
		}
	}
	@include mq (full){
		.p-about-date__flex{
			gap: 32px;
			margin-bottom: 78px;
			&:nth-child(n+2){
				margin: 80px 0 0 0;
			}
			&.-col2{
				.p-about-date__contents{
					width: calc(50% - (32px / 2));
					padding: 24px 0 0 20px;
				}
			}
			&.-col3{
				.p-about-date__contents{
					width: calc(33.33% - (64px / 3));
				}
			}
		}
		.p-about-date__contents{
			padding: 24px 20px 0 20px;
		}
		.p-about-date__contents-head{
			font-size: font-size(20);
		}
		.p-about-date__contents-list{
			flex-direction: row;
			gap: 40px;
		}
		.p-about-date__contents-text{
			gap: 8px;
			font-size: font-size(64);
		}
		.p-about-date__contents-text-side{
			font-size: font-size(26);
		}
		.p-about-date__contents-text-small{
			font-size: font-size(20);
		}
		.p-about-date__contents-item-add{
			margin-top: 10px;
		}
		.p-about-date__contents-detail{
			font-size: font-size(14);
		}
	}
}

/*  p-about-vision
---------------------------------------- */
.p-about-vision{
	padding: 50px 0;
	@include mq (large){
		padding: 100px 0;
	}
}

/*  p-about-message
---------------------------------------- */
.p-about-message{
	padding: 50px 0;
	.p-about-message__img{
		img{
			border-radius: 4px;
		}
	}
	.p-about-message__textWrap{
		margin-top: 24px;
	}
	.p-about-message__text{
		color: $clr_text_secondary;
	}
	.p-about-message__nameWrap{
		margin-top: 20px;
		text-align: right;
	}
	.p-about-message__duty{
		font-size: font-size(13);
	}
	.p-about-message__name{
		width: 107px;
		margin: 14px 0 0 auto;
	}
	@include mq (large){
		padding: 100px 0;
		.p-about-message__textWrap{
			margin-top: 55px;
		}
		.p-about-message__text{
			+.p-about-message__text{
				margin-top: 16px;
			}
		}
		.p-about-message__nameWrap{
			margin-top: 45px;
		}
		.p-about-message__duty{
			font-size: font-size(14);
		}
		.p-about-message__name{
			width: 128px;
			margin: 10px 0 0 auto;
		}
	}
}

/*  p-about-company
---------------------------------------- */
.p-about-company{
	padding: 50px 0;
	.p-about-company__dl-contents{
		&:nth-child(n+2){
			margin-top: 14px;
		}
		dt{
			margin-bottom: 3px;
			font-weight: 700;
		}
	}
	@include mq (large){
		padding: 100px 0;
		.p-about-company__table{
			th{
				width: 28%;
			}
		}
		.p-about-company__dl-contents{
			&:nth-child(n+2){
				margin-top: 20px;
			}
		}
	}
	@include mq (full){
		.p-about-company__table{
			th{
				width: 15%;
			}
		}
	}
}

/*  p-about-environment
---------------------------------------- */
.p-about-environment{
	padding: 50px 0;
	.p-about-environment__contents{
		&:nth-of-type(n+2){
			margin-top: 40px;
		}
	}
	.p-about-environment__contents-text{
		color: $clr_text_secondary;
	}
	.p-about-environment__contents-box{
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		margin-top: 20px;
		padding: 10px 10px 24px 10px;
		background: $clr_bg;
		border-radius: 4px;
	}
	.p-about-environment__contents-box-img{
		width: 100%;
		padding: 45px;
		background: #fff;
		border-radius: 4px;
		img{
			display: block;
			width: 151px;
			margin: auto;
		}
	}
	.p-about-environment__contents-textWrap{
		padding: 0 10px;
	}
	.p-about-environment__contents-box-title{
		margin-bottom: 10px;
		@include font_title;
	}
	.p-about-environment__contents-img{
		margin-top: 20px;
	}
	.p-about-environment__contents-list{
		margin-top: 32px;
	}
	.p-about-environment__contents-item{
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		padding: 32px 10px;
		border-top: 1px solid $clr_border;
		&:last-child{
			border-bottom: 1px solid $clr_border;
		}
	}
	.p-about-environment__contents-item-img{
		width: 110px;
		margin: 0 auto;
	}
	.p-about-environment__contents-item-textWrap{
		width: 100%;
	}
	.p-about-environment__contents-item-title{
		text-align: center;
		margin-bottom: 14px;
		@include font_title;
		font-size: font-size(18);
	}
	.p-about-environment__contents-item-text{
		color: $clr_text_secondary;
	}
	.p-about-environment__contents-item-box{
		margin-top: 14px;
		padding: 14px 18px;
		background: $clr_bg;
		border-radius: 4px;
	}
	@include mq (large){
		padding: 100px 0;
		.p-about-environment__contents{
			&:nth-of-type(n+2){
				margin-top: 80px;
			}
		}
		.p-about-environment__contents-box{
			margin-top: 24px;
		}
		.p-about-environment__contents-box-img{
			padding: 56px;
			img{
				width: 188px;
			}
		}
		.p-about-environment__contents-img{
			margin-top: 45px;
		}
		.p-about-environment__contents-list{
			margin-top: 50px;
		}
		.p-about-environment__contents-item{
			gap: 30px;
			padding: 50px 0;
		}
		.p-about-environment__contents-item-img{
			width: 130px;
		}
		.p-about-environment__contents-item-textWrap{
			width: calc(100% - (130px + 30px));
		}
		.p-about-environment__contents-item-title{
			text-align: left;
			margin-bottom: 18px;
			font-size: font-size(20);
		}
		.p-about-environment__contents-item-box{
			margin-top: 14px;
			padding: 18px 26px;
		}
	}
	@include mq (full){
		.p-about-environment__contents-box{
			gap: 60px;
			align-items: center;
			padding: 16px 60px 16px 16px;
		}
		.p-about-environment__contents-box-img{
			width: 393px;
		}
		.p-about-environment__contents-textWrap{
			width: calc(100% - (393px + 60px));
			padding: 0;
		}
		.p-about-environment__contents-box-title{
			margin-bottom: 12px;
		}
		.p-about-environment__contents-img{
			padding: 0 120px;
		}
		.p-about-environment__contents-item{
			gap: 60px;
			padding: 50px 40px;
		}
		.p-about-environment__contents-item-img{
			width: 160px;
		}
		.p-about-environment__contents-item-textWrap{
			width: calc(100% - (160px + 60px));
		}
	}
}

/*  p-about-certification
---------------------------------------- */
.p-about-certification{
	padding: 50px 0 100px;
	.p-about-certification__table-body{
		width: max(100%,709px);
	}
	.p-about-certification__table-th{
		&.-width01{
			width: 90px;
		}
		&.-width02{
			width: 125px;
		}
	}
	.scroll-hint-icon-wrap{
		top: -25%;
	}
	@include mq (medium){
		.p-about-certification__table{
			width: 100%;
		}
	}
	@include mq (large){
		padding: 100px 0 200px;
		.p-about-certification__table-body{
			width: max(100%,940px);
		}
		.p-about-certification__table-th{
			&.-width01{
				width: 140px;
			}
			&.-width02{
				width: 180px;
			}
		}
	}
	@include mq (full){
		.p-about-certification__table-th{
			&.-width01{
				width: 170px;
			}
			&.-width02{
				width: 220px;
			}
		}
	}
}