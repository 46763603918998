@use '../../fundation/index' as *;

/*  c-tab01
---------------------------------------- */
.c-tab01{
	.c-tab01__list{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 16px;
	}
	.c-tab01__item{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50%;
		padding: 12px;
		cursor: pointer;
		background: #fff;
		border: 1px solid $clr_border;
		font-weight: 700;
		&:nth-child(2n+1){
			border-radius: 4px 0 0 4px;
		}
		&:nth-child(2n){
			border-radius: 0 4px 4px 0;
		}
		&.is-active{
			background: $clr_primary;
			color: #fff;
			border-color: $clr_primary;
		}
	}
	.c-tab01__contents{
		display: none;
		padding: 24px 16px;
		border: 1px solid $clr_border;
		&.is-active{
			display: block;
		}
	}
	@include mq (large){
		.c-tab01__item{
			width: 214px;
			font-size: font-size(18);
			&:nth-child(2n+1){
				border-radius: 0;
			}
			&:nth-child(2n){
				border-radius: 0;
			}
			&:first-child{
				border-radius: 4px 0 0 4px;
			}
			&:last-child{
				border-radius: 0 4px 4px 0;
			}
		}
		.c-tab01__contents{
			padding: 38px 40px;
		}
	}
}